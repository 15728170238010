import React from "react";
import { Avatar, Box } from "@mui/material";
import MenuDrawerCommon from "../../../../../../common/MenuDrawerCommon";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { USER_TYPES } from "../../../../../../utils/userTypes";
import AvatarWithFallBack from "../../../../../../common/AvatarWithFallBack";
import { AVATAR_ICON_V2, SETTING_ICON_V3 } from "../../../../../../constants";

export default function ProfileMenuDrawer({
	openDrawer,
	setOpenDrawer,
	userData,
	handleLogout,
	handleOpen,
	userType,
	handelProfileClick,
	dummyLogo,
	handleSubscriptionSettings,
}) {
	const handleDrawerClose = () => {
		setOpenDrawer(false);
	};
	const menuOptions1 = [
		{
			icon: <Box component={"img"} src={AVATAR_ICON_V2} alt="settings" />,
			text: "Account Profile",
			action: handelProfileClick,
		},

		{ icon: <Box component={"img"} src={SETTING_ICON_V3} alt="settings" />, text: "Account Settings", action: handleOpen },
		// { icon: <SettingsIcon />, text: "Manage Subscription", action: handleSubscriptionSettings },
		{
			icon: <LogoutIcon />,
			text: "Logout",
			action: handleLogout,
		},
	];
	const menuOptions2 = [
		{
			icon: (
				<Box
					component="img"
					src={userData?.picture}
					width={33}
					height={33}
					sx={{
						borderRadius: "50%",
					}}
				/>
			),
			text: "Account Profile",
			action: handelProfileClick,
		},
		{
			icon: <LogoutIcon />,
			text: "Logout",
			action: handleLogout,
		},
	];
	//const menuOptions = [
	//  {
	//    icon: (
	//      <Box
	//        component="img"
	//        src={userData?.picture}
	//        width={33}
	//        height={33}
	//        sx={{
	//          borderRadius: "50%",
	//        }}
	//      />
	//    ),
	//    text: "Name",
	//    action: "",
	//  },
	//  { icon: <SettingsIcon />, text: "Account Settings", action: handleOpen },
	//  {
	//    icon: <LogoutIcon />,
	//    text: "Logout",
	//    action: handleLogout,
	//  },
	//];

	return (
		<Box>
			<MenuDrawerCommon
				open={openDrawer}
				onClose={handleDrawerClose}
				menuOptions={userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STUDENT_USER ? menuOptions1 : menuOptions2}
			/>
		</Box>
	);
}
