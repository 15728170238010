import React, { Fragment, useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, Skeleton, Typography, Button, Avatar, FormControlLabel, Switch, Stack, Chip } from "@mui/material";
import { ProfileContext } from "../..";
import {
	SEAL_CHECK,
	NEW_LINKEDIN_ICON,
	MESSAGE_ICON_V2,
	CALEDNER_ICON_V2,
	HEART_ICON_V2,
	HEART_PINK_FILLED_ICON,
	PINK_CHECK_ICON,
} from "../../../../constants/image";
import SvgComponent from "../../../../utils/SVGColor";
import { StyledProfileCard } from "../../style";
import AccountBio from "./Components/AccountBio";
import ProfileAvatar from "./Components/ProfileAvatar";
import StartupBio from "./Components/StartupBio";
import StatusButton from "../StatusButton";
import ROUTES_PATH from "../../../../constants/routes";
import PaymentPlanDialog from "../../../PaymentPlanDialog";
import services from "../../../../services";
import { USER_TYPES } from "../../../../utils/userTypes";
import progressCompletionCalculator from "../../../../utils/progressCompletionCalculator";
import { setUserProfileData } from "../../../../modules/actions/userProfileActions";
import ScheduleMeetingDialog from "../../../Networking/ScheduleMeetingDialog";
import MessageDialog from "../../../Networking/MessageDialog";
import { setNetworkingDashboardData } from "../../../../modules/actions/networkingActions";
import { updateCurrentConversation } from "../../../../containers/Messaging/store/action-creators";
import { getConversationWithID } from "../../../../utils/messaging-helper";
import { saveOrHideMatchedCollections } from "../../../../containers/MentorProfile/Function/common";
import { ConfirmationModal } from "./Components/ConfirmationModal";
import { AddOutlined } from "@mui/icons-material";
import { Can } from "../../../../services/abilities/context";

function BioCard({ setBioPercentage, invited, setInvited }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const { viewContext, profileData, profileDataLoading, founderDetails } = useContext(ProfileContext);
	console.log("profileData==>", viewContext, profileData, profileDataLoading, founderDetails);
	const [loading, setloading] = useState(profileDataLoading);
	const isAlumni = profileData?.userBadges?.[0] || null;

	const [context, setcontext] = useState(viewContext);

	const [data, setdata] = useState(profileData);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const [openSubscriptionSettings, setOpenSubscriptionSettings] = useState(false);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const userData = useSelector((store) => ({ companyId: store?.auth?.companyId, userId: store?.auth?.userId }));
	const userType = useSelector((store) => store?.auth?.userType);
	const partnerCode = useSelector((store) => store?.auth?.partnerCode);
	const [showInvestor, setShowInvestor] = React.useState(true);
	const companyDetails = useSelector((state) => state?.userProfileData?.userData);
	console.log(`profileData`, profileData);
	const [openScheduleMeetingDialog, setOpenScheduleMeetingDialog] = useState({ open: false, userInfo: null, type: null });
	const [openMessagingDialog, setOpenMessagingDialog] = useState(false);
	const networkMatches = useSelector((state) => state.networking?.networkMatches ?? []);

	const currentNetworkTab = useSelector((state) => state.networking?.currentTab);
	const totalMatches = useSelector((state) => state?.networking?.totalMatches ?? 0);
	const isNotEmpty = (val) => val !== null && typeof val !== "undefined" && val !== "";
	const slidingProfileData = useSelector((store) => ({
		open: store?.slidingProfile?.open,
		profileType: store?.slidingProfile?.profileType,
		profileId: store?.slidingProfile?.profileId,
	}));

	const [openConfirmationModel, setOpenConfirmationModel] = useState(false);

	console.log(`network matches------------------`, networkMatches);
	useEffect(() => {
		setShowInvestor(companyDetails?.investorView);
	}, [companyDetails]);

	useEffect(() => {
		setloading(profileDataLoading);
	}, [profileDataLoading]);

	useEffect(() => {
		setcontext(viewContext);
	}, [viewContext]);

	useEffect(() => {
		setdata(profileData);
	}, [profileData]);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) {
			getSubscriptionDetails();
		}
	}, [userType]);

	const handleCloseScheduleMeetingDialog = () => {
		setOpenScheduleMeetingDialog({ open: false, userInfo: null, type: null });
	};
	const getUserType = () => {
		if (data?.userTypes?.includes(USER_TYPES.MENTOR_USER)) {
			return USER_TYPES.MENTOR_USER;
		}
		if (data?.userTypes?.includes(USER_TYPES.STARTUP_OWNER)) {
			return USER_TYPES.STARTUP_OWNER;
		}
		if (data?.userTypes?.includes(USER_TYPES.INVESTOR_USER)) {
			return USER_TYPES.INVESTOR_USER;
		}
		if (data?.isSaved) {
			return "SAVED";
		}
		if (data?.isHidden) {
			return "HIDDEN";
		}
	};
	const onCalendarClick = () => {
		const userTypes = getUserType();
		setOpenScheduleMeetingDialog({
			open: true,
			userInfo: context?.component === "startup" ? founderDetails : data,
			type: context?.component === "startup" ? USER_TYPES.STARTUP_OWNER : userTypes,
		});
	};
	const removeMatches = (id) => {
		const intUserId = isNaN(id) ? null : parseInt(id);
		const filteredNetworkMatches = networkMatches.filter((match) => match.id !== intUserId);
		dispatch(
			setNetworkingDashboardData({
				networkMatches: filteredNetworkMatches,
				hiddenConfirmOpen: false,
				slidingConfirmationObject: {},
				totalMatches: totalMatches - 1 >= 0 ? totalMatches - 1 : 0,
			})
		);
	};
	const onSaveConfirm = async () => {
		try {
			const isSaved = data?.isSaved ? data?.isSaved : false;
			await saveOrHideMatchedCollections({ entityIds: [data.id], collectionType: "SAVED", entityType: "USER", isRemove: isSaved });
			const tempUserDetails = { ...data, isSaved: !isSaved };

			setdata(tempUserDetails);
			if (slidingProfileData?.open) {
				if (currentNetworkTab === "SAVED") {
					removeMatches(data?.id);
					return;
				}
				const tempNetworkMatches = [...networkMatches];
				const intUserId = isNaN(data?.id) ? null : parseInt(data?.id);
				const matchedIndex = tempNetworkMatches.findIndex((match) => match.id === intUserId);
				if (matchedIndex !== -1) {
					tempNetworkMatches[matchedIndex] = { ...tempNetworkMatches[matchedIndex], isSaved: !isSaved };
				}
			}
		} catch (error) {
			console.log("Could not perform operation", error);
		}
	};
	const onMessageClick = async () => {
		try {
			let conversationID = await getConversationWithID({
				idArray: [viewContext?.component === "startup" ? founderDetails?.id : data?.id],
			});
			dispatch(updateCurrentConversation(conversationID));
			setOpenMessagingDialog(true);
			dispatch(
				setNetworkingDashboardData({
					openMessageDialog: true,
				})
			);
		} catch (e) {
			console.log("Could not retrieve conversation.", e);
		}
	};

	const handleRedirection = () => {
		if (viewContext?.component === "startup" || viewContext?.component === "account") {
			dispatch(
				setUserProfileData({
					userProfileEditOpen: true,
				})
			);
		}
	};

	const getSubscriptionDetails = async () => {
		try {
			const details = await services.getSubscriptionDetails();
			if (details?.data?.data?.customerId && details?.data?.data?.isCreatedByUser) {
				setOpenSubscriptionSettings(true);
			} else {
				setOpenSubscriptionSettings(false);
			}
		} catch (err) {
			setOpenSubscriptionSettings(false);
		}
	};

	const handlePremium = async () => {
		try {
			if (isPremiumPlanAccess || isAdmin) {
				const settings = await services.getSubscriptionSettings();
				window.open(settings?.data?.data?.url || process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
			} else {
				setShowPlanModal(true);
			}
		} catch (err) {
			setShowPlanModal(true);
		}
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const handlePlanBtn = useMemo(() => {
		if ((isPremiumPlanAccess && openSubscriptionSettings) || isAdmin) {
			return (
				<Button
					sx={{
						background: "#7B61FF",
						borderRadius: "100px",
					}}
					onClick={handlePremium}
				>
					Manage Subscription
				</Button>
			);
		} else if (!(isPremiumPlanAccess || isAdmin)) {
			return (
				<Button
					sx={{
						background: "#7B61FF",
						borderRadius: "100px",
					}}
					onClick={handlePremium}
				>
					Upgrade to Premium
				</Button>
			);
		}

		return null;
	}, [isPremiumPlanAccess, openSubscriptionSettings, isAdmin]);
	const handleshowInvestorStatus = (event) => {
		setShowInvestor(event.target.checked);
		const updatedTagList =
			profileData?.companyTags?.length > 0
				? profileData?.companyTags?.map((item) => {
						return Number(item?.scoreTag?.id);
				  })
				: [];
		services
			.updateCompanyProfile({
				...profileData,
				investorView: event.target.checked,
				companyTags: updatedTagList,
			})
			.then((response) => {
				if (response.data.message === "Success") {
					console.log("Success");
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const closeConfirmationModal = () => {
		setOpenConfirmationModel(false);
	};
	return (
		<StyledProfileCard sx={{ flex: 1 }}>
			{(isAdmin || userType === USER_TYPES.PARTNER_USER) && userData?.userId != profileData?.id && profileData?.partnerCode !== partnerCode && (
				<Box width={"30%"} ml={35}>
					<Can I="manage" a="AcceleratorUsers">
						<Button
							onClick={() => {
								!(profileData?.invitationStatus === "PENDING" || profileData?.invitationStatus === "ACCEPTED") &&
									setOpenConfirmationModel(true);
							}}
							variant="containedGrayStyle"
							sx={{
								backgroundColor: "rgba(242, 244, 247, 1)",
								cursor: profileData?.invitationStatus === "PENDING" || profileData?.invitationStatus === "ACCEPTED" ? "default" : "pointer",
							}}
							startIcon={
								profileData?.invitationStatus === "PENDING" || invited ? (
									<Box component={"img"} src={PINK_CHECK_ICON} sx={{ width: "24px", height: "24px" }} />
								) : (
									!profileData?.invitationStatus === "ACCEPTED" && (
										<AddOutlined sx={{ fontSize: "23px", color: (theme) => theme.palette.secondary.black }} className="add-icon-hover" />
									)
								)
							}
						>
							{profileData?.invitationStatus === "PENDING" || invited
								? "Invited"
								: profileData?.invitationStatus === "ACCEPTED"
								? "Accepted"
								: "Invite"}
						</Button>
					</Can>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box display={"flex"} alignItems={"center"}>
					{" "}
					{!viewContext?.id && (viewContext?.component === "startup" || viewContext?.component === "account") && (
						<Box>
							{/* <StatusButton percentage={progressCompletionCalculator(totalFields, completedFields)} handleRedirection={handleRedirection} /> */}
						</Box>
					)}
					{!viewContext?.id && viewContext?.component === "startup" && (
						<Box sx={{ ml: "auto" }}>
							<FormControlLabel
								onChange={(e) => handleshowInvestorStatus(e)}
								control={<Switch color="primary" checked={showInvestor} inputProps={{ "aria-label": "controlled" }} />}
								label={
									<Typography sx={{ textAlign: "end" }} variant="Text/sm/Semibold">
										Showcase to Investors
									</Typography>
								}
								labelPlacement="start"
							/>
						</Box>
					)}
				</Box>

				{/* Logo/Image */}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 3,
						my: 3,
					}}
				>
					<ProfileAvatar />

					{!viewContext?.id && context?.component === "startup" && (
						<Box display="flex" flexDirection="column" gap={1}>
							<Box display="flex" alignItems="center" gap={1}>
								<Avatar
									sx={{
										width: "30px",
										height: "30px",
										background: isPremiumPlanAccess || isAdmin ? "#12B76A" : "#EAECF0",
									}}
								>
									<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M13.3587 8.12909C13.3419 8.05722 13.3076 7.99061 13.259 7.93514C13.2103 7.87967 13.1487 7.83705 13.0796 7.81107L8.6475 6.14912L9.76993 0.536983C9.78908 0.441198 9.77647 0.34178 9.734 0.25381C9.69154 0.16584 9.62155 0.0941179 9.53465 0.049522C9.44774 0.00492616 9.34866 -0.0101102 9.25243 0.00669387C9.15621 0.0234979 9.06809 0.0712253 9.00144 0.142637L0.620643 9.12206C0.570311 9.17603 0.534056 9.24157 0.515087 9.31288C0.496118 9.38419 0.495019 9.45908 0.511888 9.53092C0.528756 9.60276 0.563072 9.66934 0.611799 9.72475C0.660526 9.78017 0.722162 9.82273 0.791252 9.84865L5.22335 11.5106L4.10092 17.1227C4.08165 17.2185 4.09416 17.318 4.13657 17.4061C4.17897 17.4941 4.24895 17.5659 4.33588 17.6106C4.3994 17.6429 4.46964 17.6599 4.54091 17.66C4.60254 17.66 4.66352 17.6473 4.72003 17.6227C4.77655 17.5981 4.8274 17.5622 4.86941 17.5171L13.2502 8.53765C13.3004 8.48369 13.3366 8.41819 13.3555 8.34694C13.3745 8.27568 13.3756 8.20086 13.3587 8.12909ZM5.2892 15.7489L6.17591 11.313C6.19695 11.2085 6.18012 11.1 6.12843 11.0067C6.07675 10.9135 5.99358 10.8417 5.89381 10.8042L1.73334 9.24403L8.57716 1.91084L7.69269 6.34742C7.67165 6.45192 7.68849 6.56049 7.74017 6.65371C7.79185 6.74694 7.87502 6.81873 7.97479 6.85626L12.1353 8.41643L5.2892 15.7489Z"
											fill={isPremiumPlanAccess || isAdmin ? "white" : "#000000"}
										/>
									</svg>
								</Avatar>
								<Typography variant="subhead5">{isPremiumPlanAccess || isAdmin ? `Premium User` : `Free User`}</Typography>
							</Box>
							{/* {handlePlanBtn} */}
						</Box>
					)}

					{/* {context &&
						context?.id &&
						(() => {
							if (context?.component === "account") {
								// Only render component if not personal account.
								if (context?.id !== userData?.userId) {
									return <ConnectButton />;
								}
							}
						})()} */}
				</Box>

				{/* Like, Meetings, Messaging */}
				{
					<Stack spacing={1}>
						{loading ? (
							<Skeleton animation="wave" variant="rectangular" width={100} height={24} />
						) : (
							<Stack direction="row" spacing={1}>
								{context?.component === "account" && viewContext?.id && (
									<Box
										component={"img"}
										src={data?.isSaved ? HEART_PINK_FILLED_ICON : HEART_ICON_V2}
										alt="heart"
										sx={{ height: "25px", width: "25px", cursor: "pointer" }}
										onClick={onSaveConfirm}
									/>
								)}
								{viewContext?.id && (
									<>
										{context?.component === "startup" ? (
											<Box
												component={"img"}
												src={CALEDNER_ICON_V2}
												alt="message"
												sx={{ height: "25px", width: "25px", cursor: "pointer" }}
												onClick={onCalendarClick}
											/>
										) : (
											data?.userTypes &&
											data?.userTypes[0] !== USER_TYPES.INVESTOR_USER && (
												<Box
													component={"img"}
													src={CALEDNER_ICON_V2}
													alt="message"
													sx={{ height: "25px", width: "25px", cursor: "pointer" }}
													onClick={onCalendarClick}
												/>
											)
										)}
									</>
								)}
								{viewContext?.id && data?.userSource !== "PROXY" && (
									<>
										<Box
											component={"img"}
											src={MESSAGE_ICON_V2}
											alt="message"
											sx={{ height: "25px", width: "25px", cursor: "pointer" }}
											onClick={onMessageClick}
										/>
									</>
								)}
							</Stack>
						)}
					</Stack>
				}

				{/* Name */}
				{loading ? (
					<Skeleton animation="wave" variant="text" sx={{ fontSize: "60px" }} />
				) : (
					<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsSemiBold",
								fontSize: "26px",
								lineHeight: "39px",
							}}
						>
							{(() => {
								if (context?.component === "startup") {
									return data?.companyName;
								} else if (context?.component === "account") {
									return data?.name;
								}
							})()}
						</Typography>
						{(() => {
							let url = "";
							if (context?.component === "startup") {
								url = data?.linkedInUrl;
							} else if (context?.component === "account") {
								url = data?.linkedInUrl;
							}
							if (url) {
								return (
									<img
										src={NEW_LINKEDIN_ICON}
										style={{
											height: "34px",
											width: "34px",
											stroke: "#12B76A",
											cursor: "pointer",
										}}
										onClick={() => window.open(url, "_blank")}
									/>
								);
							}
						})()}
					</Box>
				)}
				{/* Incorporated */}
				{context && context?.component === "startup" && (
					<Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 3 }}>
						{loading ? (
							<Skeleton animation="wave" variant="rounded" sx={{ height: "25px", width: "100%" }} />
						) : (
							<Fragment>
								<Typography
									component={"div"}
									variant="customValue"
									sx={{
										color: "black",
										fontFamily: "PoppinsSemiBold",
										fontSize: "20px",
										lineHeight: "30px",
									}}
								>
									Incorporated? {data?.incorporatedStatus}
								</Typography>
								{data?.incorporatedStatus === "Yes" && (
									<SvgComponent strokeColor={"#12B76A"} svgUrl={SEAL_CHECK} style={{ height: "34px", width: "34px" }} />
								)}
							</Fragment>
						)}
					</Box>
				)}
				{/* Title/Profession */}
				{context && context?.component === "account" && (
					<Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 3 }}>
						{loading ? (
							<Skeleton animation="wave" variant="rounded" sx={{ height: "25px", width: "100%" }} />
						) : (
							<Fragment>
								<Typography
									component={"div"}
									variant="customValue"
									sx={{
										color: "black",
										fontFamily: "PoppinsSemiBold",
										fontSize: "20px",
										lineHeight: "30px",
									}}
								>
									{data?.profession}
								</Typography>
							</Fragment>
						)}
					</Box>
				)}

				{/* is isAlumni */}
				{context && context?.component === "account" && isAlumni && (
					<Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 3 }}>
						{loading ? (
							<Skeleton animation="wave" variant="rectangular" sx={{ height: "10px", width: 1 }} />
						) : (
							<Fragment>
								<Chip
									label={
										<Typography variant="Text/xs/Regular" sx={{ color: (theme) => theme.palette.primary.primaryPurple }}>
											Alumni
										</Typography>
									}
									variant="outlined"
									sx={(theme) => ({
										border: `1px solid ${theme.palette.primary.primaryPurple}`,
										background: theme.palette.secondary.white,
									})}
								/>
							</Fragment>
						)}
					</Box>
				)}

				{/* Tag Line */}
				<Box sx={{ mb: 3 }}>
					{loading ? (
						<Skeleton animation="wave" variant="text" sx={{ fontSize: "16px" }} />
					) : (
						<Typography
							component={"div"}
							variant="customValue"
							sx={{
								color: "black",
								fontFamily: "PoppinsMedium",
								fontSize: "16px",
								lineHeight: "24px",
							}}
						>
							{(() => {
								if (context?.component === "startup") {
									return data?.tagLine;
								} else if (context?.component === "account") {
									return data?.headline;
								} else {
									return "";
								}
							})()}
						</Typography>
					)}
				</Box>
				{context && context?.component === "startup" && <StartupBio />}
				{context && context?.component === "account" && <AccountBio />}
				{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} isRedirect={false} />}
			</Box>
			{openMessagingDialog && <MessageDialog />}
			<ScheduleMeetingDialog
				open={openScheduleMeetingDialog?.open}
				handleClose={handleCloseScheduleMeetingDialog}
				userInfo={openScheduleMeetingDialog?.userInfo}
				withUserType={openScheduleMeetingDialog?.type}
			/>
			<ConfirmationModal
				open={openConfirmationModel}
				handleClose={closeConfirmationModal}
				setInvited={setInvited}
				invitedUserId={profileData?.id}
				userRole={profileData && profileData?.userTypes?.length > 0 ? profileData?.userTypes[0] : ""}
				partnerName={partnerCode}
			/>

			{/* {openScheduleMeetingDialog?.open && openScheduleMeetingDialog?.userInfo && (
				<ScheduleMeetingDialog
					open={openScheduleMeetingDialog?.open}
					handleClose={handleCloseScheduleMeetingDialog}
					userInfo={openScheduleMeetingDialog?.userInfo}
					withUserType={openScheduleMeetingDialog?.type}
				/>
			)} */}
		</StyledProfileCard>
	);
}

export default BioCard;
