import { Avatar, Typography, CircularProgress, Box, Stack } from "@mui/material";
import React, { Fragment, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export function AdminTableColumns(buttonLoader, setButtonLoader, reloadAdminsTable) {
	const columns = [
		{
			field: "name",
			headerName: "Name",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => (
				<Fragment>
					<Avatar src={params.value?.img} sx={{ mr: 1 }} />
					<Typography variant="Text/xs/Regular">{params.value?.name}</Typography>
				</Fragment>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
	];
	return columns;
}
