import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	useMediaQuery,
	Slide,
	Dialog,
	DialogContent,
	Fab,
	Typography,
	useTheme,
	Button,
	CircularProgress,
	FormControlLabel,
	Checkbox,
	TextField,
	Alert,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useWindowSize } from "../../../../utils/windowResize";
import { DRAWER_CLOSE_BLACK } from "../../../../constants/image";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toastContainer } from "../../../../utils/toast";

import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import AttributeSelectionList from "./AttributeSelectionList";
import { getAttributes } from "./functions";
import {
	assignRolesAndAttributesUrl,
	createPartnerGroupUrl,
	getCompanyAttributesUrl,
	getCompanyRolesUrl,
	getPartnerGroupByIdUrl,
	getPartnerRolesUrl,
	getRoleByIdUrl,
	getUserAttributesUrl,
	getUserRolesUrl,
} from "../../../../utils/urls";

//import { createPartnerRoleUrl, getPartnerRoleByIdUrl } from "../../../../utils/urls";

const InputPropsStyle = {
	borderRadius: "30px",
	height: "60px",
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	padding: "1.5px 6px",
	fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
	background: "#fff",
	boxShadow: "0px 10px 25px 0px #0000000D",
};

const TextAreaPropsStyle = {
	borderRadius: "12px",
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
	background: "#fff",
	boxShadow: "0px 10px 25px 0px #0000000D",
};

const InputLabelStyle = {
	style: {
		fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
		color: "#667085",
		zIndex: 9,
		padding: "2px 0 0 4px",
	},
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const defaultValues = {
	selectedRoles: [],
	selectedAttributes: [],
};

const roleSchemas = yup.object().shape({
	selectedRoles: yup.array(),
	selectedAttributes: yup.array(),
});

function ManageRoleAssignmentFlyout({ entity, onClose, partnerCode }) {
	const size = useWindowSize();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveDrawerWidth = isSmallScreen ? size.width * 1 : size.width * 0.85;

	const currentUserID = useSelector((state) => state?.auth?.userId);

	const [show, setShow] = useState(false);

	const [initEntityRoles, setInitEntityRoles] = useState([]);

	const [initEntityAttributes, setInitEntityAttributes] = useState([]);

	const [availableRoles, setAvailableRoles] = useState([]);

	const [availableAttributes, setAvailableAttributes] = useState([]);

	const [isLoading, setisLoading] = useState(false);

	const [isSaving, setisSaving] = useState(false);

	const [isDeleting, setisDeleting] = useState(false);

	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);

	const maxWidth = "1920px"; // Figma specifies max content width

	const [readOnly, setreadOnly] = useState(false);

	const {
		watch,
		handleSubmit,
		getValues,
		setValue,
		control,
		reset,
		formState: { isDirty, errors },
		getFieldState,
	} = useForm({
		mode: "all",
		defaultValues: defaultValues,
		resolver: yupResolver(roleSchemas),
		validateTrigger: "onSubmit",
	});

	useEffect(() => {
		if (entity?.open) {
			reset(defaultValues);
			if (entity?.entityType === "ADMIN" && currentUserID === entity?.entityId) setreadOnly(true);
			setShow(true);
			getData();
		} else {
			setTimeout(() => {
				setShow(false);
				setreadOnly(false);
			}, 700);
		}
	}, [entity]);

	const getData = async () => {
		setisLoading(true);

		let permissionData = {
			roleIds: [],
			attributeIds: [],
		};

		const URL = entity?.entityType === "STARTUP" ? getCompanyRolesUrl(entity?.entityId) : getUserRolesUrl(entity?.entityId);

		const ENTITY_ATTRIBUTE_URL =
			entity?.entityType === "STARTUP" ? getCompanyAttributesUrl(entity?.entityId, true) : getUserAttributesUrl(entity?.entityId, true);

		try {
			// Create all the axios calls
			const getRoles = axios.get(getPartnerRolesUrl(partnerCode)).then((resp) => {
				setAvailableRoles(
					resp?.data?.map(({ groupId, ...rest }) => ({
						id: groupId,
						...rest,
					}))
				);
			});

			const getAttributesCall = getAttributes().then((resp) => {
				setAvailableAttributes(resp?.data);
			});

			const getEntityAttributes = axios.get(ENTITY_ATTRIBUTE_URL).then((resp) => {
				const data = resp?.data;
				permissionData.attributeIds = data?.map((attribute) => attribute?.id);
				setInitEntityAttributes(data?.map((attribute) => attribute?.id));
			});

			const getEntityRoles = axios.get(URL).then((resp) => {
				const data = resp?.data;
				permissionData.roleIds = data?.map((role) => role?.groupId);
				setInitEntityRoles(data?.map((role) => role?.groupId));
			});

			// Run all the axios calls in parallel
			await Promise.all([getRoles, getAttributesCall, getEntityAttributes, getEntityRoles]);
		} catch (error) {
			console.log("An error occurred while fetching data.", error);
		} finally {
			console.log("Reset to:", {
				selectedRoles: permissionData.roleIds,
				selectedAttributes: permissionData.attributeIds,
			});
			reset({
				selectedRoles: permissionData.roleIds,
				selectedAttributes: permissionData.attributeIds,
			});
			// Set loading to false once all promises resolve
			setisLoading(false);
		}
	};

	const onSubmit = async (formData) => {
		setisSaving(true);
		// Compare current values to default (initial) values to detect changes
		const roleIdsChanged = JSON.stringify(formData?.selectedRoles) !== JSON.stringify(initEntityRoles);
		const attributeIdsChanged = JSON.stringify(formData?.selectedAttributes) !== JSON.stringify(initEntityAttributes);

		// Mark the fields as dirty only if they differ from their initial values
		if (roleIdsChanged) {
			setValue("selectedRoles", formData?.selectedRoles, { shouldDirty: true });
		}

		if (attributeIdsChanged) {
			setValue("selectedAttributes", formData?.selectedAttributes, { shouldDirty: true });
		}

		await axios
			.post(assignRolesAndAttributesUrl(), {
				entityType: entity?.entityType === "STARTUP" ? "COMPANY" : "USER",
				entityId: entity?.entityId,
				...(getFieldState("selectedAttributes")?.isDirty && { attributeIds: formData?.selectedAttributes }),
				...(getFieldState("selectedRoles")?.isDirty && { attributeCollectionIds: formData?.selectedRoles }),
			})
			.then(() => {
				toastContainer("Roles Updated Successfully");
				onClose();
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setisSaving(false);
			});
	};

	return (
		<Fragment>
			<Dialog
				fullWidth
				TransitionComponent={Transition}
				transitionDuration={700}
				open={show}
				onClose={() => onClose()} //close button is in dialog content
				keepMounted={false}
				PaperProps={{
					sx: {
						position: "fixed",
						right: 0,
						top: 0,
						bottom: 0,
						borderRadius: "30px 0px 0px 30px",
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						background: "#F5F9FA",
						width: `${responsiveDrawerWidth}px`,
						maxWidth: maxWidth, // Set your width here
						m: 0,
						maxHeight: "100%",
						minHeight: "100%",
					},
				}}
			>
				<DialogContent
					sx={{
						border: "none",
						p: 0,
						flex: 1,
						display: "flex",
						mb: "30px",
						pt: "60px",
					}}
				>
					<Box width={responsiveDrawerWidth} sx={{ maxWidth: maxWidth, display: "flex", flexDirection: "column", rowGap: "60px" }}>
						<Box
							sx={{
								px: {
									lg: 8,
									md: 4,
									xs: 2,
								},
								pb: "30px",
								display: "flex",
								flexDirection: "column",
								gap: "32px",
								maxWidth: "1920px", // Figma specifies max content width
							}}
						>
							<Box
								display="flex"
								alignItems={size.width > 1000 ? "center" : "baseline"}
								justifyContent="space-between"
								flexDirection={size.width > 1000 ? "row" : "column"}
							>
								<Box
									sx={{
										ml: "auto",
										position: "fixed",
										right: 20,
										display: "flex",
										gap: "8px",
									}}
								>
									<Fab
										sx={{
											bgcolor: (theme) => theme.palette.secondary.black,
											color: (theme) => theme.palette.secondary.white,
											"&:hover": {
												bgcolor: (theme) => theme.palette.secondary.black,
											},
										}}
										aria-label="close"
										color="secondary.white"
										onClick={() => {
											setShow(false);
											onClose();
										}}
									>
										<Box component={"img"} src={DRAWER_CLOSE_BLACK} alt="close-drawer" />
									</Fab>
								</Box>
							</Box>

							{isLoading ? (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<CircularProgress />
								</Box>
							) : (
								<form onSubmit={handleSubmit(onSubmit)}>
									<Box
										sx={{
											display: "flex",
											flex: 1,
											flexDirection: "column",
											gap: 2,
											pb: "30px",
										}}
									>
										<Box sx={{ flex: 1 }}>
											<Typography variant="Text/md/Semibold" component={"p"}>
												Assign Roles and Permissions
											</Typography>
										</Box>
										{readOnly && (
											<Box sx={{ flex: 1 }}>
												<Alert severity="info" variant="outlined">
													<strong>Read Only:</strong> You are unable to modify your own permissions.
												</Alert>
											</Box>
										)}
										<Box sx={{ flex: 1 }}>
											<Typography variant="Text/sm/Semibold" component={"p"}>
												Select Roles
											</Typography>
										</Box>
										<Box sx={{ flex: 1 }}>
											<AttributeSelectionList
												attributes={availableRoles}
												selectedAttributes={getValues("selectedRoles")}
												updateSelection={(selection) => setValue("selectedRoles", selection)}
												readOnly={readOnly}
											/>
										</Box>

										<Box sx={{ flex: 1 }}>
											<Typography variant="Text/sm/Semibold" component={"p"}>
												Select Permissions
											</Typography>
										</Box>
										<Box sx={{ flex: 1 }}>
											<AttributeSelectionList
												attributes={availableAttributes}
												selectedAttributes={getValues("selectedAttributes")}
												updateSelection={(selection) => setValue("selectedAttributes", selection)}
												readOnly={readOnly}
											/>
										</Box>
									</Box>

									<Box sx={{ mt: "auto", display: "flex", justifyContent: "flex-end", gap: 1 }} onClick={() => {}}>
										<Button variant="DS1" color="gray" onClick={() => onClose()}>
											Cancel
										</Button>
										<Button
											variant="DS1"
											type="submit"
											disabled={isSaving || isDeleting || readOnly}
											startIcon={isSaving && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
										>
											Save
										</Button>
									</Box>
								</form>
							)}
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
}

export default ManageRoleAssignmentFlyout;
