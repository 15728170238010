import React, { useEffect, useState } from "react";
import ManageGroupFlyout from "./ManageGroup";
import { Box, Button } from "@mui/material";
import axios from "axios";
import GroupsTable from "./GroupsTable";
import ManageRoleFlyout from "./ManageRole";
import RolesTable from "./RolesTable";
import { AbilityContext, Can } from "../../../../services/abilities/context";
import { useAbility } from "@casl/react";

function Roles({ partnerCode, tableKey }) {
	const [manageRole, setmanageRole] = useState({ editMode: false, role: null, open: false });

	const [rolesTableKey, setKeyForRolesTable] = useState(0);

	const ability = useAbility(AbilityContext);

	useEffect(() => {
		setKeyForRolesTable(tableKey);
	}, [tableKey]);

	return (
		<Box>
			<RolesTable
				tableKey={tableKey}
				partnerCode={partnerCode}
				setKeyForRolesTable={setKeyForRolesTable}
				rolesTableKey={rolesTableKey}
				editRole={(id) => {
					if (ability.can("manage", "AcceleratorRoles"))
						setmanageRole({
							open: true,
							editMode: true,
							role: id,
							partnerCode: partnerCode,
						});
				}}
			/>
			<Can I="manage" a="AcceleratorRoles">
				<Button
					variant="DS1"
					onClick={() => {
						setmanageRole({
							open: true,
							editMode: false,
							role: null,
							partnerCode: partnerCode,
						});
					}}
				>
					Create Role
				</Button>

				<ManageRoleFlyout
					open={manageRole?.open}
					editMode={manageRole?.editMode}
					partnerCode={partnerCode}
					role={manageRole?.role}
					onClose={() => {
						setmanageRole({
							open: false,
							editMode: false,
							role: null,
							partnerCode: partnerCode,
						});
						setKeyForRolesTable((prevKey) => prevKey + 1);
					}}
				/>
			</Can>
		</Box>
	);
}

export default Roles;
