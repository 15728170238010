import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	useMediaQuery,
	Slide,
	Dialog,
	DialogContent,
	Fab,
	Typography,
	useTheme,
	Button,
	CircularProgress,
	FormControlLabel,
	Checkbox,
	TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useWindowSize } from "../../../../utils/windowResize";
import { DRAWER_CLOSE_BLACK } from "../../../../constants/image";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toastContainer } from "../../../../utils/toast";

import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import AttributeSelectionList from "./AttributeSelectionList";
import { getAttributes } from "./functions";
import { createPartnerGroupUrl, getPartnerGroupByIdUrl, getRoleByIdUrl } from "../../../../utils/urls";

//import { createPartnerRoleUrl, getPartnerRoleByIdUrl } from "../../../../utils/urls";

const InputPropsStyle = {
	borderRadius: "30px",
	height: "60px",
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	padding: "1.5px 6px",
	fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
	background: "#fff",
	boxShadow: "0px 10px 25px 0px #0000000D",
};

const TextAreaPropsStyle = {
	borderRadius: "12px",
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
	background: "#fff",
	boxShadow: "0px 10px 25px 0px #0000000D",
};

const InputLabelStyle = {
	style: {
		fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
		color: "#667085",
		zIndex: 9,
		padding: "2px 0 0 4px",
	},
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

const defaultValues = {
	roleName: "",
	roleDescription: "",
	selectedAttributes: [],
};

const roleSchemas = yup.object().shape({
	roleName: yup.string().required("Role name is required"),
	roleDescription: yup.string(),
	selectedAttributes: yup.array(),
});

function ManageRoleFlyout({ open, onClose, editMode, partnerCode, role }) {
	const size = useWindowSize();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveDrawerWidth = isSmallScreen ? size.width * 1 : size.width * 0.85;
	const [show, setShow] = useState(false);

	const [allAttributes, setAllAttributes] = useState([]);

	const [isLoading, setisLoading] = useState(false);

	const [isSaving, setisSaving] = useState(false);

	const [isDeleting, setisDeleting] = useState(false);

	const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);

	const maxWidth = "1920px"; // Figma specifies max content width

	const {
		watch,
		handleSubmit,
		getValues,
		setValue,
		control,
		reset,
		formState: { errors },
	} = useForm({
		mode: "all",
		defaultValues: defaultValues,
		resolver: yupResolver(roleSchemas),
		validateTrigger: "onSubmit",
	});

	useEffect(() => {
		if (open) {
			reset(defaultValues);
			setShow(true);
			getAllAttributes();
			if (editMode) {
				getRole();
			}
		} else {
			setTimeout(() => {
				setShow(false);
			}, 700);
		}
	}, [open]);

	const getAllAttributes = async () => {
		setisLoading(true);
		await getAttributes()
			.then((resp) => {
				setAllAttributes(resp?.data);
			})
			.catch(() => {
				console.log("Issue grabbing attributes.");
			})
			.finally(() => {
				if (!editMode) setisLoading(false);
			});
	};

	const getRole = async () => {
		setisLoading(true);
		await axios
			.get(getRoleByIdUrl(role))
			.then((res) => {
				const data = res?.data;
				reset({
					roleName: data?.name,
					roleDescription: data?.description,
					selectedAttributes: data?.attributes?.map((attribute) => attribute?.id),
				});
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setisLoading(false);
			});
	};

	const onSubmit = async (formData) => {
		console.log(`formData`, formData);
		setisSaving(true);

		if (editMode) {
			axios
				.put(getRoleByIdUrl(role), {
					groupName: formData?.roleName,
					groupDescription: formData?.roleDescription,
					attributeIds: formData?.selectedAttributes,
				})
				.then((res) => {
					toastContainer("Role Updated Successfully");
					onClose();
					console.log(res);
				})
				.catch((e) => {
					console.log(e);
					toastContainer("Unable to update role", "error");
				})
				.finally(() => {
					setisSaving(false);
				});
		} else {
			axios
				.post(createPartnerGroupUrl(), {
					groupName: formData?.roleName,
					groupDescription: formData?.roleDescription,
					partnerCode: partnerCode,
					collectionType: "ATTRIBUTES",
					attributeIds: formData?.selectedAttributes,
				})
				.then((res) => {
					toastContainer("Role Created Successfully");
					onClose();
					console.log(res);
				})
				.catch((e) => {
					console.log(e);
					toastContainer("Unable to create role", "error");
				})
				.finally(() => {
					setisSaving(false);
				});
		}
	};

	const deleteRoleHandler = async () => {
		axios
			.delete(getPartnerGroupByIdUrl(role))
			.then((res) => {
				toastContainer("Role Deleted Successfully");
				onClose();
				console.log(res);
			})
			.catch((e) => {
				console.log(e);
				toastContainer("Unable to delete role", "error");
			})
			.finally(() => {
				setDeleteConfirmPopup(false);
				setisDeleting(false);
			});
	};

	return (
		<Fragment>
			<Dialog
				fullWidth
				TransitionComponent={Transition}
				transitionDuration={700}
				open={show}
				onClose={() => onClose()} //close button is in dialog content
				keepMounted={false}
				PaperProps={{
					sx: {
						position: "fixed",
						right: 0,
						top: 0,
						bottom: 0,
						borderRadius: "30px 0px 0px 30px",
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						background: "#F5F9FA",
						width: `${responsiveDrawerWidth}px`,
						maxWidth: maxWidth, // Set your width here
						m: 0,
						maxHeight: "100%",
						minHeight: "100%",
					},
				}}
			>
				<DialogContent
					sx={{
						border: "none",
						p: 0,
						flex: 1,
						display: "flex",
						mb: "30px",
						pt: "60px",
					}}
				>
					<Box width={responsiveDrawerWidth} sx={{ maxWidth: maxWidth, display: "flex", flexDirection: "column", rowGap: "60px" }}>
						<Box
							sx={{
								px: {
									lg: 8,
									md: 4,
									xs: 2,
								},
								pb: "30px",
								display: "flex",
								flexDirection: "column",
								gap: "32px",
								maxWidth: "1920px", // Figma specifies max content width
							}}
						>
							<Box
								display="flex"
								alignItems={size.width > 1000 ? "center" : "baseline"}
								justifyContent="space-between"
								flexDirection={size.width > 1000 ? "row" : "column"}
							>
								<Box
									sx={{
										ml: "auto",
										position: "fixed",
										right: 20,
										display: "flex",
										gap: "8px",
									}}
								>
									<Fab
										sx={{
											bgcolor: (theme) => theme.palette.secondary.black,
											color: (theme) => theme.palette.secondary.white,
											"&:hover": {
												bgcolor: (theme) => theme.palette.secondary.black,
											},
										}}
										aria-label="close"
										color="secondary.white"
										onClick={() => {
											setShow(false);
											onClose();
										}}
									>
										<Box component={"img"} src={DRAWER_CLOSE_BLACK} alt="close-drawer" />
									</Fab>
								</Box>
							</Box>

							{isLoading ? (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<CircularProgress />
								</Box>
							) : (
								<form onSubmit={handleSubmit(onSubmit)}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											gap: 2,
											pb: "30px",
										}}
									>
										<Box sx={{ flex: 1 }}>
											<Typography variant="Text/md/Semibold" component={"p"}>
												{editMode ? "Edit" : "Create"} Role
											</Typography>
										</Box>
										<Box sx={{ flex: 1 }}>
											<Controller
												name="roleName"
												control={control}
												render={({ field: { onChange, value, name } }) => (
													<TextField
														fullWidth
														id="roleName"
														label="Role name*"
														value={value}
														name={name}
														sx={{
															"& .MuiOutlinedInput-notchedOutline": {
																border: errors?.roleName ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
															},
														}}
														InputProps={{ style: InputPropsStyle }}
														InputLabelProps={InputLabelStyle}
														onChange={onChange}
													/>
												)}
											/>
											{errors?.roleName && (
												<Box mt={1}>
													<Typography variant="subhead2" sx={{ color: "#F04438" }}>
														{errors?.roleName?.message}
													</Typography>
												</Box>
											)}
										</Box>
										<Box sx={{ flex: 1 }}>
											<Controller
												name="roleDescription"
												control={control}
												render={({ field: { onChange, value, name } }) => (
													<TextField
														fullWidth
														multiline
														minRows={10}
														id="roleDescription"
														label="Role description"
														value={value}
														name={name}
														sx={{
															"& .MuiOutlinedInput-notchedOutline": {
																border: errors?.roleDescription ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
															},
														}}
														InputProps={{ style: TextAreaPropsStyle }}
														InputLabelProps={InputLabelStyle}
														onChange={onChange}
													/>
												)}
											/>
											{errors?.roleDescription && (
												<Box mt={1}>
													<Typography variant="subhead2" sx={{ color: "#F04438" }}>
														{errors?.roleDescription?.message}
													</Typography>
												</Box>
											)}
										</Box>
									</Box>

									<Box sx={{ flex: 1, mb: 2 }}>
										<Typography variant="Text/sm/Semibold" component={"p"}>
											Role Permissions
										</Typography>
									</Box>
									<Box sx={{ flex: 1 }}>
										<AttributeSelectionList
											attributes={allAttributes}
											selectedAttributes={getValues("selectedAttributes")}
											updateSelection={(selection) => setValue("selectedAttributes", selection)}
										/>
									</Box>

									<Box sx={{ mt: "auto", display: "flex", justifyContent: "flex-end", gap: 1 }} onClick={() => {}}>
										{editMode && (
											<Button
												variant="DS1"
												color="destructive"
												onClick={() => setDeleteConfirmPopup(true)}
												disabled={isSaving || isDeleting}
												startIcon={isDeleting && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
											>
												Delete Role
											</Button>
										)}

										<Button
											variant="DS1"
											type="submit"
											disabled={isSaving || isDeleting}
											startIcon={isSaving && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
										>
											{editMode ? "Save" : "Create"} Role
										</Button>
									</Box>
								</form>
							)}
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
			<MessageHandlerModal
				isOpen={deleteConfirmPopup}
				onCancel={() => setDeleteConfirmPopup(false)}
				onOk={() => {
					deleteRoleHandler();
				}}
				okText="Delete"
				cancelText={"No thanks"}
				heading={`Delete Role`}
				text={`Are you sure you want to delete the role?`}
				messageType="primary"
			/>
		</Fragment>
	);
}

export default ManageRoleFlyout;
