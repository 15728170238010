import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import CommonSlideDialog from "../../common/CommonSlideDialog";
import theme from "../../constants/Theme";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import { startupProfileProgress } from "../../utils/profilePercentageCalculation";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import PaymentPlanDialog from "../PaymentPlanDialog";
import { Accordion, AccordionSummary, AccordionDetails } from "../Founder/Journey";
import { setCommonDialogConfirmationData } from "../../modules/actions";
import { getFormattedDate } from "../../utils/date";
import messages from "../../constants/messages";
import { isEduEmail } from "../../utils/commonFunctions";
import { formSubmissionsBaseUrl, getAssignedFormByEntityIdUrl } from "../../utils/urls";
import axios from "axios";
import ViewFormFlyout from "../Forms/Utilities/ViewFormFlyout";
import InfoHelper from "../Founder/InfoHelper";

export const INVESTOR_OPTIONS = [
	{ id: 0, title: "Complete required fields in your Startup Profile", disabled: true },
	{ id: 1, title: "Ensure your startup is incorporated", disabled: true },
	{ id: 2, title: "Upload your pitch deck", disabled: true },
];

export default function InvestorApplyToPitch() {
	const dispatch = useDispatch();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const InvestorApplyToPitchData = useSelector((state) => state?.commonDialogConfirmation);
	const userData = useSelector((store) => ({ companyId: store?.auth?.companyId, userId: store?.auth?.userId, userEmail: store?.auth?.email }));
	const [requireOptions, setRequireOptions] = useState(INVESTOR_OPTIONS);
	const authData = useSelector((data) => ({
		isPremiumPlanAccess: data?.auth?.isPremiumPlanAccess,
		isAdmin: data?.auth?.isAdmin,
	}));
	const [showPlanModal, setShowPlanModal] = useState(false);
	const [isPremium, setIsPremium] = useState(false);
	const [expanded, setExpanded] = useState(0);
	const { isPremiumPlanAccess, userType, email } = useSelector((store) => store?.auth);
	const { eventList } = useSelector((state) => state.event);

	const [pitchEvents, setPitchEvents] = useState([]);

	const [eventActionLoading, seteventActionLoading] = useState(false);

	const [showFormFlyout, setshowFormFlyout] = useState({
		open: false,
		formStructure: "",
		item: null,
		uuid: null,
		formId: null,
	});

	useEffect(() => {
		if (InvestorApplyToPitchData.isSVGExpand) {
			setExpanded(0);
		}

		if (InvestorApplyToPitchData.isPitchExpand) {
			setExpanded(1);
		}
	}, [InvestorApplyToPitchData]);

	useEffect(() => {
		if (InvestorApplyToPitchData?.applyToPitchOpen) {
			setPitchEvents(eventList?.filter((item) => item?.isPitchEvent));
			ApiToGetApplyToPitchData(eventList?.filter((item) => item?.isPitchEvent));
			getSubscriptionDetails();
		}
	}, [InvestorApplyToPitchData?.applyToPitchOpen]);
	const isPastEvent = (eventDetails) => {
		const currentDate = new Date().getTime();
		let check = new Date(eventDetails?.deadlineDate);
		if (check < currentDate) {
			return true;
		} else {
			return false;
		}
	};
	const ApiToGetApplyToPitchData = async (tempEvents) => {
		const response = await services.getApplyToPitchApi(userData?.companyId);
		if (tempEvents?.length > 0) {
			tempEvents.map((pitchItem) => {
				const appliedIndex = response?.data?.data?.findIndex((item) => item?.uuid === pitchItem?.uuid);
				pitchItem.isApplied = appliedIndex !== -1;
			});
			setPitchEvents([...tempEvents]);
		}
	};

	const getSubscriptionDetails = async () => {
		try {
			const details = await services.getSubscriptionDetails();

			if (details?.data?.data?.subscriptionStatus === "active" && details?.data?.data?.isCreatedByUser === true) {
				setIsPremium(true);
			}
		} catch (err) {
			if (isEduEmail(email)) {
				setIsPremium(true);
			}
		}
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};
	const handleApplyToPitch = async (uuid, item) => {
		try {
			const res = await services.applyPitch({
				companyId: userData?.companyId,
				applyEmail: null,
				uuid,
				title: item?.title,
			});
			let tempEvents = [...pitchEvents];
			const appliedIndex = tempEvents.findIndex((item) => item.uuid === uuid);
			if (appliedIndex !== -1) {
				tempEvents[appliedIndex].isApplied = true;
			}
			setPitchEvents([...tempEvents]);
			toastContainer(messages?.PITCH_EVENT_SUCESS);

			handleMixPanel("Applied to Pitch Event", {
				companyId: userData?.companyId,
				uuid,
				subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
				user_type: userType,
			});
		} catch (e) {
			toastContainer(e.response?.data?.message || "Something is wrong!", "error");
		}
		seteventActionLoading(false);
	};

	const handleClose = () => {
		seteventActionLoading(false);
		dispatch(
			setCommonDialogConfirmationData({
				applyToPitchOpen: false,
			})
		);
	};

	const getEventForm = (eid) => {
		return axios
			.get(getAssignedFormByEntityIdUrl({ entityType: "EVENT", entityId: eid }))
			.then((resp) => {
				return resp;
			})
			.catch((e) => {
				return false;
			});
	};

	const handlePitch = async (e, uuid, item) => {
		seteventActionLoading(true);
		e.stopPropagation();

		const eventForm = await getEventForm(item?.id);
		if (eventForm) {
			setshowFormFlyout({
				open: true,
				formStructure: eventForm?.data?.formStructure,
				item: item,
				uuid: uuid,
				formId: eventForm?.data?.id,
			});
		} else {
			handleApplyToPitch(uuid, item);
		}
		// Allow all users to pitch
		//handleApplyToPitch(uuid, item);

		// if (isPremium || authData?.isAdmin) {
		// 	handleApplyToPitch(uuid, item);
		// } else {
		// 	dispatch(
		// 		setCommonDialogConfirmationData({
		// 			applyToPitchOpen: false,
		// 		})
		// 	);

		// 	setShowPlanModal(true);
		// }
	};

	const getStartupProfileData = async () => {
		try {
			if (userData?.companyId) {
				let response = await services.getStartupDetails(userData?.companyId);
				const { totalPercentage } = startupProfileProgress(response?.data?.data);
				requireOptions[0].title = `Complete required fields in your Startup Profile [${Math.round(totalPercentage || 0)}%]`;
				if (Math.round(totalPercentage || 0) === 100) {
					requireOptions[0].disabled = false;
				}
				requireOptions[1].disabled = response?.data?.data?.incorporatedStatus === "Yes" ? false : true;
				requireOptions[2].disabled = response?.data?.data?.pitchDeckLink ? false : true;
				setRequireOptions([...requireOptions]);
			}
		} catch (error) {
			console.log("error: Unable to successfully retrieve startup profile by ID:" + error);
		}
	};

	useEffect(() => {
		getStartupProfileData();
	}, []);

	const getButtonLabel = (isApplied) => {
		// Allow all users to pitch
		return isApplied ? "Applied" : "Apply to Pitch";
		// if (isPremium || authData?.isAdmin) {
		// 	return isApplied ? "Applied" : "Apply to Pitch";
		// } else {
		// 	return "Subscribe!";
		// }
	};

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const eventFormSubmit = async (formData) => {
		try {
			const transformedArray = [];
			// Iterate over the mapped schema object
			Object.keys(formData).forEach((fieldId) => {
				const { value, schema } = formData[fieldId];

				// Push the transformed object into the array
				transformedArray.push({
					id: fieldId,
					name: schema?.label || schema?.name, // Use schema label or name
					value: value,
					fieldType: schema?.component,
				});
			});

			await axios.post(formSubmissionsBaseUrl(), {
				formId: showFormFlyout?.formId,
				submissionData: JSON.stringify(transformedArray),
			});

			seteventActionLoading(true);
			handleApplyToPitch(showFormFlyout?.uuid, showFormFlyout?.item);
			setshowFormFlyout({
				open: false,
				formStructure: "",
				item: null,
				uuid: null,
				formId: null,
			});
		} catch (e) {
			console.error("Failed to submit form", e);
		}
	};

	return (
		<>
			<CommonSlideDialog
				open={InvestorApplyToPitchData?.applyToPitchOpen}
				onClose={handleClose}
				title="Important Announcement!"
				titleVariant="Text/md/Semibold"
				maxWidth="sm"
				disableBackDropClick={false}
				PaperProps={{
					sx: {
						px: 2,
						py: 3,
						borderRadius: "30px",
						width: isSmallScreen ? "100%" : theme.breakpoints.values.sm,
					},
				}}
				contentStyle={{ mt: 0 }}
				dialogAction={
					<Box display="flex" alignItems="center" gap={1.5} sx={{ pr: 3, my: 1 }}>
						<Box>
							<Button
								variant="contained"
								sx={{
									height: "48px",
									borderRadius: "100px",
									color: "#344054 !important",
									fontWeight: "600",
									fontSize: "16px",
									background: "#F2F4F7",
									"&:hover": {
										background: "#F2F4F7",
									},
								}}
								onClick={() => {
									handleClose();
								}}
							>
								Close
							</Button>
						</Box>
					</Box>
				}
			>
				<Box>
					<Box mb="20px">
						<Typography component={"p"} variant="Text/sm/Semibold">
							Upcoming StartupOS Pitch events!
						</Typography>
						<Typography component={"p"} variant="Text/sm/Regular">
							Apply to pitch at one or more of our StartupOS sponsored pitch events. We will be in touch to confirm your application!
						</Typography>
						{/* {(isPremium || authData?.isAdmin) === false && (
							<Typography component={"p"} variant="Text/sm/Regular" color="#7B61FF" mt="24px" sx={{ cursor: "pointer", fontWeight: 600 }}>
								You must be a premium member to apply to pitch!
							</Typography>
						)} */}
					</Box>

					{pitchEvents?.map(
						(item, idx) =>
							!isPastEvent(item) && (
								<Accordion key={item.uuid} expanded={expanded === idx + 1} onChange={handleChange(idx + 1)}>
									<AccordionSummary expanded={expanded === idx + 1 ? "true" : "false"}>
										<Box
											ml={1.5}
											width="100%"
											display="flex"
											alignItems="flex-start"
											justifyContent="space-between"
											onClick={() => handleChange(idx + 1)}
										>
											<Box width="100%" display="flex" alignItems="flex-start" justifyContent="space-between" gap={1.5}>
												<Box>
													<Typography variant="Text/sm/Semibold" sx={{ display: "block" }}>
														{item?.title}
													</Typography>
													<Typography variant="Text/sm/Regular">{getFormattedDate(item?.deadlineDate, "MMMM DD, YYYY")}</Typography>
												</Box>

												<Box>
													{isPastEvent(item) ? (
														<Button variant="DS1" color={"clear"}>
															Registration Closed
														</Button>
													) : item?.isApplied ? (
														<Button
															variant={"nooutlined"}
															sx={{
																fontSize: "16px",
																color: "rgba(102, 112, 133, 1)",
																cursor: "auto",
																height: "48px",
																fontWeight: "600",
																borderRadius: "100px",
															}}
														>
															Applied
														</Button>
													) : (
														<Box sx={{ position: "relative" }}>
															<Button
																variant={"PurpleRounded"}
																sx={{ whiteSpace: "nowrap" }}
																onClick={(e) => handlePitch(e, item?.uuid, item)}
																disabled={eventActionLoading || !userData?.companyId}
																endIcon={eventActionLoading && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
															>
																{getButtonLabel(item?.isApplied)}
															</Button>
															{!userData?.companyId && (
																<Box sx={{ position: "absolute", top: "-5px", right: "-5px" }}>
																	<InfoHelper
																		title={"Ineligible to apply to pitch"}
																		body={
																			"You are not part of a company, so you are unable to apply to participate in this pitch event."
																		}
																	/>
																</Box>
															)}
														</Box>
													)}
												</Box>
											</Box>
										</Box>
									</AccordionSummary>
									<AccordionDetails>
										<Typography variant="subhead1">
											{" "}
											<span
												dangerouslySetInnerHTML={{
													__html: item?.pitchEventDescription,
												}}
											/>{" "}
										</Typography>
									</AccordionDetails>
								</Accordion>
							)
					)}
				</Box>
			</CommonSlideDialog>
			{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} isRedirect={false} />}
			{showFormFlyout && showFormFlyout?.open && (
				<ViewFormFlyout
					open={showFormFlyout?.open}
					formString={showFormFlyout?.formStructure}
					viewMode={"INPUT"}
					onSubmit={(resp) => {
						eventFormSubmit(resp);
					}}
					onClose={() => {
						setshowFormFlyout({
							open: false,
							formStructure: "",
							item: null,
							uuid: null,
							formId: null,
						});
						seteventActionLoading(false);
					}}
				/>
			)}
		</>
	);
}
