import React, { useState, useEffect, Fragment, useRef } from "react";
import {
	Box,
	useMediaQuery,
	Slide,
	Dialog,
	DialogContent,
	Fab,
	Typography,
	Button,
	CircularProgress,
	TextField,
	Divider,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	FormControlLabel,
	Radio,
	RadioGroup,
	FormLabel,
	FormGroup,
	Checkbox,
	Alert,
} from "@mui/material";

import { useWindowSize } from "../../../utils/windowResize";
import { DRAWER_CLOSE_BLACK } from "../../../constants/image";

import FormRenderer from "@data-driven-forms/react-form-renderer/form-renderer";

import { componentMapper } from "@data-driven-forms/mui-component-mapper";

import { useFieldApi, useFormApi } from "@data-driven-forms/react-form-renderer";

const initialSchema = {
	fields: [],
};

const InputPropsStyle = {
	borderRadius: "30px",
	fontWeight: "500",
	fontSize: "16px",
	lineHeight: "24px",
	fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
	background: "#fff",
	boxShadow: "0px 10px 25px 0px #0000000D",
};

const InputLabelStyle = {
	style: {
		fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
		color: "#667085",
		zIndex: 9,
		padding: "2px 0 0 4px",
	},
};

const PlainTextHeader = (props) => {
	return <Typography variant="Text/lg/Semibold" component={"h1"} sx={{ my: 1 }} dangerouslySetInnerHTML={{ __html: props?.content }}></Typography>;
};
const PlainTextParagraph = (props) => {
	return <Typography variant="Text/sm/Regular" component={"p"} sx={{ my: 1 }} dangerouslySetInnerHTML={{ __html: props?.content }}></Typography>;
};
const PlainTextDivider = (props) => {
	const {
		customProp,
		label,
		input,
		isRequired,
		meta: { error, touched },
		FieldArrayProvider,
		dataType,
		...rest
	} = useFieldApi(props);
	return (
		<Divider
			sx={{
				my: 2,
				"&": (theme) => ({
					...theme.typography["Text/xs/Semibold"],
					textTransform: "uppercase",
				}),
			}}
			textAlign="left"
		>
			{props?.content}
		</Divider>
	);
};
const FormTextField = (props) => {
	const {
		label,
		input,
		isRequired,
		meta: { error, touched },
		FieldArrayProvider,
		dataType,
		isReadOnly,
		...rest
	} = useFieldApi(props);

	return (
		<TextField
			fullWidth
			helperText={<Typography variant="Text/xs/Regular">{props?.description}</Typography>}
			required={isRequired}
			label={label}
			disabled={isReadOnly || false}
			InputProps={{
				style: {
					...InputPropsStyle,
					...(!props?.multiline && {
						height: "60px",
						padding: "1.5px 6px",
					}),
				},
				...input,
				...rest,
			}}
			InputLabelProps={InputLabelStyle}
			sx={{
				my: 1,
				"& .Mui-disabled": {
					color: "initial",
					"-webkit-text-fill-color": "unset",
				},
			}}
			{...(props?.multiline && {
				multiline: true,
				minRows: 10,
			})}
		/>
	);
};
const FormSelectField = (props) => {
	const {
		label,
		input,
		isRequired,
		meta: { error, touched },
		options,
		description,
		isReadOnly,
		...rest
	} = useFieldApi(props);

	return (
		<FormControl fullWidth required={isRequired} error={touched && Boolean(error)} sx={{ my: 1 }}>
			<Typography variant="Text/xs/Medium" sx={{ color: "#344054" }}>
				{label}
			</Typography>
			<Select
				{...input}
				{...rest}
				displayEmpty
				disabled={isReadOnly || false}
				sx={{
					my: 1,
					borderRadius: "100px",
					backgroundColor: "#FFFFFF",
					border: "1px solid #D0D5DD",
					boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)", // Initial shadow
					"&.Mui-focused": {
						boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF", // Shadow when focused
						borderColor: "#F4EBFF", // Highlighted border color on focus
					},
					"& .MuiOutlinedInput-notchedOutline": {
						border: "none", // Remove the default border
					},
					"& .Mui-disabled": {
						color: "initial",
						"-webkit-text-fill-color": "unset",
					},
				}}
				renderValue={(selected) => {
					if (!selected || selected?.length === 0) {
						return (
							<Typography variant="Text/sm/Medium" sx={{ color: "#667085" }}>
								Select Option
							</Typography>
						);
					}

					// Assuming options is passed or available in this context
					const selectedOption = options?.find((option) => option?.value === selected);

					// If a matching option is found, display its label
					if (selectedOption) {
						return <Typography variant="Text/sm/Regular">{selectedOption?.label}</Typography>;
					}

					// Default fallback in case the option is not found
					return (
						<Typography variant="Text/sm/Medium" sx={{ color: "#667085" }}>
							Select Option
						</Typography>
					);
				}}
			>
				{/*<MenuItem value="">
					
				</MenuItem>*/}
				{options?.map((option, index) => (
					<MenuItem
						key={index}
						value={option?.value}
						sx={{
							"&:hover": {
								backgroundColor: "#F9FAFB", // Hover background color for options
							},
							"&.Mui-selected": {
								backgroundColor: "#F9FAFB", // Background color for the selected option
								color: "#6941C6", // Text color for the selected option
							},
							"&.Mui-selected:hover": {
								backgroundColor: "#F9FAFB", // Ensure hover and selected states match
							},
						}}
					>
						<Typography variant="Text/sm/Regular">{option?.label}</Typography>
					</MenuItem>
				))}
			</Select>
			{description && <Typography variant="Text/xs/Regular">{description}</Typography>}
			{touched && error && <FormHelperText error>{error}</FormHelperText>}
		</FormControl>
	);
};
const FormRadioField = (props) => {
	const {
		label,
		input,
		isRequired,
		meta: { error, touched },
		options,
		description,
		isReadOnly,
		...rest
	} = useFieldApi(props);

	return (
		<FormControl fullWidth required={isRequired} error={touched && Boolean(error)} sx={{ my: 1 }}>
			<Typography variant="Text/xs/Medium" sx={{ color: "#344054" }}>
				{label}
			</Typography>
			<RadioGroup {...input} {...rest}>
				{options?.map((option, index) => (
					<FormControlLabel
						key={index}
						value={option?.value}
						control={<Radio disabled={isReadOnly || false} />}
						label={
							<Typography variant={"Text/sm/Medium"} sx={{ color: "#344054" }}>
								{option?.label}
							</Typography>
						}
					/>
				))}
			</RadioGroup>
			{description && <Typography variant="Text/xs/Regular">{description}</Typography>}
			{touched && error && <FormHelperText error>{error}</FormHelperText>}
		</FormControl>
	);
};

const CustomCheckboxOption = (props) => {
	const { input } = useFieldApi(props);

	return (
		<Checkbox
			{...input}
			disabled={props?.isReadOnly || false}
			onChange={(e) => {
				props?.onChange(e);
				input?.onChange(e);
			}}
		/>
	);
};

const CustomCheckboxGroup = (props) => {
	const { input, label, isRequired, meta, options, description, isReadOnly } = useFieldApi(props);

	return (
		<FormControl component="fieldset" required={isRequired}>
			<FormLabel component="legend">
				<Typography variant="Text/sm/Medium">{label}</Typography>
			</FormLabel>
			{description && (
				<Typography variant="Text/xs/Regular" component={"p"}>
					{description}
				</Typography>
			)}
			<FormGroup>
				{options.map((option, index) => (
					<FormControlLabel
						key={option.value}
						control={
							<CustomCheckboxOption
								component="checkbox"
								name={option.name}
								isReadOnly={isReadOnly || false}
								onChange={(event) => {
									const newValue = event.target.checked ? [...input.value, option.value] : input.value.filter((val) => val !== option.value);
									input.onChange(newValue);
								}}
							/>
						}
						label={
							<Typography variant={"Text/sm/Medium"} sx={{ color: "#344054" }}>
								{option?.label}
							</Typography>
						}
					/>
				))}
				{/* Shadow input to hold group state; used for form submission, requirement checking, errors, etc... */}
				<input {...input} hidden={true} />
			</FormGroup>
		</FormControl>
	);
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

function ViewFormFlyout({ open, onClose, formString, submissionData, viewMode = "PREVIEW", onSubmit }) {
	const size = useWindowSize();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const responsiveDrawerWidth = isSmallScreen ? size.width * 1 : size.width * 0.85;
	const [show, setShow] = useState(false);
	const [schema, setSchema] = useState(initialSchema);
	const [initialValues, setInitialValues] = useState({});

	const maxWidth = "1920px"; // Figma specifies max content width

	const FormTemplate = ({ schema, formFields }) => {
		const { handleSubmit } = useFormApi();

		return (
			<form onSubmit={handleSubmit}>
				{schema.title}
				{formFields}
				{viewMode === "INPUT" && (
					<Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "flex-end", mt: 2 }}>
						<Button variant="DS1" type="submit">
							Submit
						</Button>
					</Box>
				)}
			</form>
		);
	};

	useEffect(() => {
		if (open) {
			setShow(true);
		} else {
			setTimeout(() => {
				setShow(false);
			}, 700);
		}
	}, [open]);

	useEffect(() => {
		convertFormeoToDataDrivenForms();
	}, [formString]);

	const convertFormeoToDataDrivenForms = () => {
		try {
			let formData = formString;
			if (typeof formData === "string") {
				formData = JSON.parse(formData); // Parse formData if it's a JSON string
			}

			if (!formData || !formData.rows || !formData.columns || !formData.fields) {
				throw new Error("Invalid Formeo schema structure");
			}

			const fields = [];

			// Pass 1: Build fields without conditions
			Object.values(formData.stages).forEach((stage) => {
				// Iterate through rows in the current stage
				stage.children.forEach((rowId) => {
					const row = formData.rows[rowId];
					row.children.forEach((columnId) => {
						const column = formData.columns[columnId];
						column.children.forEach((fieldId) => {
							const field = formData.fields[fieldId];
							const fieldConfig = field.config || {};
							const fieldMeta = field.meta || {};
							const fieldAttrs = field.attrs || {};

							let componentType = "text-field"; // Default component type
							let content = ""; // For custom components like headers or paragraphs

							// Field type to Data-Driven Forms component mapping
							switch (fieldMeta.id) {
								case "checkbox":
									componentType = "checkbox-field";
									break;
								case "textarea":
									componentType = "text-field";
									break;
								case "select":
									componentType = "select-field";
									break;
								case "radio":
									componentType = "radio-field";
									break;
								case "html.header":
									componentType = "plain-text-header";
									content = field.content || "This is a header";
									break;
								case "paragraph":
									componentType = "plain-text-paragraph";
									content = field.content || "This is a paragraph";
									break;
								case "divider":
									componentType = "plain-text-divider";
									content = field.content || "";
									break;
								default:
									componentType = "text-field";
							}

							// Build the field object
							const convertedField = {
								component: componentType,
								name: fieldId,
								label: fieldConfig.label || "",
								content: content || "",
								description: fieldAttrs?.description || "",
								isRequired: fieldAttrs.required || false,
								clearOnUnmount: true,
								...(viewMode === "SUBMISSION" && { isReadOnly: true }),
							};

							if (fieldMeta.id === "textarea") {
								convertedField.multiline = true;
							}

							if (componentType === "select-field" || componentType === "radio-field") {
								convertedField.options = field.options || [];
							}

							// Build options for checkbox fields
							if (fieldMeta.id === "checkbox") {
								const options = [];
								field.options.forEach((opt) => {
									const optionName = `${fieldId}-${opt.value}`;
									options.push({
										label: opt.label,
										value: opt.value,
										name: optionName,
									});
								});
								convertedField.options = options;
							}
							fields.push(convertedField);
						});
					});
				});
			});

			// Pass 2: Apply conditions to the appropriate fields
			Object.keys(formData.fields).forEach((fieldId) => {
				const field = formData.fields[fieldId];

				if (field.conditions && field.conditions.length > 0) {
					field.conditions.forEach((formCondition) => {
						formCondition.if.forEach((cond) => {
							const sourceFieldId = cond.source.split(".")[1];
							const conditionIs = cond.target || "";

							// Find the target field where the condition should be applied
							const targetField = fields.find((f) => f.name === formCondition.then[0]?.target.split(".")[1]);

							if (targetField) {
								// For checkboxes, append the option value to the field name
								if (formData.fields[sourceFieldId]?.meta?.id === "checkbox") {
									const checkboxOption = formData.fields[sourceFieldId].options.find((opt) => opt.value === conditionIs);
									if (checkboxOption) {
										const conditionWhen = `${sourceFieldId}-${conditionIs}`;
										const conditionObj = {
											when: conditionWhen,
											is: true,
										};
										targetField.condition = targetField.condition || { or: [] };
										targetField.condition.or.push(conditionObj);
									}
								} else {
									// For other field types, just use the base condition
									const conditionWhen = sourceFieldId;
									const conditionObj = {
										when: conditionWhen,
										is: conditionIs,
									};
									targetField.condition = targetField.condition || { or: [] };
									targetField.condition.or.push(conditionObj);
								}
							}
						});
					});
				}
			});

			setSchema({ fields });
		} catch (error) {
			console.error("Error converting Formeo schema:", error.message);
			setSchema({ fields: [] });
		}
	};

	//useEffect(() => {
	//	//console.log("The FORMEO STRING:", formString);
	//	console.log("The SCHEMA:", schema);
	//}, [schema]);

	useEffect(() => {
		if (submissionData?.submissionData) {
			// Parse submissionData and convert it into initialValues
			const parsedSubmissionData = JSON.parse(submissionData?.submissionData);
			const values = parsedSubmissionData.reduce((acc, field) => {
				acc[field.id] = field.value; // Use field id as key, value as field response
				return acc;
			}, {});
			setInitialValues(values);
		}
	}, [submissionData]);

	const submitMapping = (e) => {
		const response = {};

		try {
			if (typeof onSubmit === "function") {
				// Iterate over formValues (field name and value)
				Object.keys(e).forEach((fieldName) => {
					const fieldValue = e[fieldName];

					// Find the matching field schema in the provided schema
					const fieldSchema = schema?.fields?.find((field) => field?.name === fieldName);

					if (fieldSchema) {
						// Build the response object, including both schema and value
						response[fieldName] = {
							value: fieldValue,
							schema: fieldSchema,
						};
					}
				});

				onSubmit(response);
			}
		} catch (e) {
			console.error("Form submit mapping error: ", e);
			return null;
		}
	};

	const formatCreatedAt = (createdAt) => {
		const date = new Date(createdAt);
		const options = { month: "2-digit", day: "2-digit", year: "numeric" };
		const dateString = date.toLocaleDateString("en-US", options);
		const timeString = date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
		return `${dateString} at ${timeString}`;
	};

	return (
		<Dialog
			fullWidth
			TransitionComponent={Transition}
			transitionDuration={700}
			open={show}
			onClose={() => onClose()} //close button is in dialog content
			keepMounted={false}
			PaperProps={{
				sx: {
					position: "fixed",
					right: 0,
					top: 0,
					bottom: 0,
					borderRadius: "30px 0px 0px 30px",
					boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
					background: "#F5F9FA",
					width: `${responsiveDrawerWidth}px`,
					maxWidth: maxWidth,
					m: 0,
					maxHeight: "100%",
					minHeight: "100%",
				},
			}}
		>
			<DialogContent
				sx={{
					border: "none",
					p: 0,
					flex: 1,
					display: "flex",
					mb: "30px",
					pt: "60px",
				}}
			>
				<Box width={responsiveDrawerWidth} sx={{ maxWidth: maxWidth, display: "flex", flexDirection: "column", rowGap: "60px" }}>
					<Box
						sx={{
							px: {
								lg: 8,
								md: 4,
								xs: 2,
							},
							pb: "30px",
							display: "flex",
							flexDirection: "column",
							gap: "32px",
							maxWidth: "1920px", // Figma specifies max content width
						}}
					>
						<Box
							display="flex"
							alignItems={size.width > 1000 ? "center" : "baseline"}
							justifyContent="space-between"
							flexDirection={size.width > 1000 ? "row" : "column"}
						>
							<Box
								sx={{
									ml: "auto",
									position: "fixed",
									right: 20,
									display: "flex",
									gap: "8px",
								}}
							>
								<Fab
									sx={{
										bgcolor: (theme) => theme.palette.secondary.black,
										color: (theme) => theme.palette.secondary.white,
										"&:hover": {
											bgcolor: (theme) => theme.palette.secondary.black,
										},
									}}
									aria-label="close"
									color="secondary.white"
									onClick={() => {
										setShow(false);
										onClose();
									}}
								>
									<Box component={"img"} src={DRAWER_CLOSE_BLACK} alt="close-drawer" />
								</Fab>
							</Box>
						</Box>
						<Box sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "flex-start" }}>
							{viewMode === "PREVIEW" && <Typography variant="Text/lg/Semibold">Form Preview</Typography>}
							{viewMode === "SUBMISSION" && (
								<Alert severity="info" variant="outlined" sx={{ flex: 1 }}>
									<Typography variant="Text/lg/Semibold" component={"h1"}>
										{submissionData?.name}
									</Typography>
									<Typography variant="Text/md/Regular" component={"p"}>
										Submitted on {formatCreatedAt(submissionData?.createdAt)}
									</Typography>
								</Alert>
							)}
						</Box>
						{schema && (
							<FormRenderer
								FormTemplate={FormTemplate}
								componentMapper={{
									...componentMapper,
									"plain-text-header": PlainTextHeader,
									"plain-text-paragraph": PlainTextParagraph,
									"plain-text-divider": PlainTextDivider,
									"text-field": FormTextField,
									"select-field": FormSelectField,
									"checkbox-field": CustomCheckboxGroup,
									"radio-field": FormRadioField,
								}}
								schema={schema}
								onSubmit={(e) => submitMapping(e)}
								initialValues={initialValues}
							/>
						)}
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default ViewFormFlyout;
