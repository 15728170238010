import { Box, Typography, useTheme, Button, Fab, Badge, Autocomplete, Select, OutlinedInput, MenuItem, InputLabel, FormControl } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TableController from "../../Tables/TableController";
import { getPartnerFormsUrl } from "../../../utils/urls";
import axios from "axios";

import ViewFormFlyout from "./ViewFormFlyout";
import { EyeVisionIcon } from "../../../common/icons";

function FormsTable({ partnerCode, formsTableKey, setKeyForFormsTable, editForm, updatedSelection, selectedIds }) {
	const theme = useTheme();

	const dispatch = useDispatch();
	const [partnerCodeSelect, setPartnerCodeSelect] = useState(null);
	const [loadedPartnerCode, setloadedPartnerCode] = useState(null);

	const [rowSelectionModel, setRowSelectionModel] = useState(selectedIds);

	const [previewForm, setpreviewForm] = useState({ formString: null, open: false });

	useEffect(() => {
		if (typeof updatedSelection === "function") updatedSelection(rowSelectionModel);
	}, [rowSelectionModel]);

	const formatCreatedAt = (createdAt) => {
		const date = new Date(createdAt);
		const options = { month: "2-digit", day: "2-digit", year: "numeric" };
		const dateString = date.toLocaleDateString("en-US", options);
		const timeString = date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
		return `${dateString} at ${timeString}`;
	};

	const formColumns = [
		{
			field: "name",
			headerName: "Form Name",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "createdAt",
			headerName: "Created",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params?.value ? formatCreatedAt(params?.value) : ""}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "previewForm",
			headerName: "Preview",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 250,
			renderCell: (params) => (
				<Button
					iconButton={true}
					small={true}
					variant="DS1"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if (params?.row?.formStructure)
							setpreviewForm({
								open: true,
								formString: params?.row?.formStructure,
							});
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
						<path
							d="M8 22.6667C8 23.1351 8 23.3693 8.02089 23.5729C8.19433 25.2632 9.41681 26.6595 11.0694 27.0548C11.2685 27.1025 11.5007 27.1334 11.9649 27.1953L20.7542 28.3672C23.2561 28.7008 24.507 28.8676 25.4779 28.4815C26.3304 28.1426 27.0404 27.521 27.489 26.7208C28 25.8094 28 24.5474 28 22.0234V9.97663C28 7.45257 28 6.19054 27.489 5.27917C27.0404 4.47902 26.3304 3.85738 25.4779 3.51844C24.507 3.13239 23.2561 3.29918 20.7542 3.63277L11.9649 4.80467C11.5006 4.86657 11.2685 4.89753 11.0694 4.94514C9.41681 5.34046 8.19433 6.73676 8.02089 8.42711C8 8.63072 8 8.86492 8 9.33332M16 10.6667L21.3333 16M21.3333 16L16 21.3333M21.3333 16H4"
							stroke="white"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</Button>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
	];

	useEffect(() => {
		if (partnerCode) {
			setloadedPartnerCode(partnerCode); //Prevent table flash on field clear.
			setPartnerCodeSelect(partnerCode);
		}
	}, [partnerCode]);

	const getForms = async (currentPage, pageSize) => {
		const result = await getPartnerForms();
		return result;
	};

	const getPartnerForms = async () => {
		const result = await axios.get(getPartnerFormsUrl(partnerCode));

		if (!result && !result?.data) return null;

		try {
			const models = result?.data;

			return {
				data: {
					totalElements: models?.length,
					models: models,
				},
			};
		} catch (e) {
			console.log("Issue with processing Partner Forms. Failed with error:", e);
		}
	};

	return (
		<Box
			sx={{
				//px: { lg: 8, md: 4, xs: 2 },
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				margin: "0 auto",
				maxWidth: "1920px", // Figma specifies max content width
			}}
		>
			{(partnerCodeSelect || loadedPartnerCode) && (
				<TableController
					checkboxSelection={typeof updatedSelection === "function" ? true : false}
					rowSelectionModel={typeof updatedSelection === "function" ? rowSelectionModel : []}
					setRowSelectionModel={typeof updatedSelection === "function" ? setRowSelectionModel : null}
					key={`forms_${formsTableKey}`}
					requestData={(currentPage, pageSize) => getForms(currentPage, pageSize)}
					columnDefs={formColumns}
					title="Forms"
					showCount
					countLabel={{ singular: "Form", plural: "Forms" }}
					icon={
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<path
								d="M28 6.66651L13.3333 6.6665M28 25.3332L13.3333 25.3332M28 15.9998L13.3333 15.9998M8 6.6665C8 7.77107 7.10457 8.6665 6 8.6665C4.89543 8.6665 4 7.77107 4 6.6665C4 5.56193 4.89543 4.6665 6 4.6665C7.10457 4.6665 8 5.56193 8 6.6665ZM8 25.3332C8 26.4377 7.10457 27.3332 6 27.3332C4.89543 27.3332 4 26.4377 4 25.3332C4 24.2286 4.89543 23.3332 6 23.3332C7.10457 23.3332 8 24.2286 8 25.3332ZM8 15.9998C8 17.1044 7.10457 17.9998 6 17.9998C4.89543 17.9998 4 17.1044 4 15.9998C4 14.8953 4.89543 13.9998 6 13.9998C7.10457 13.9998 8 14.8953 8 15.9998Z"
								stroke={theme?.palette?.primary?.main}
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					}
					noResultsMessage={
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "16px",
								alignSelf: "stretch",
								flex: 1,
								justifyContent: "center",
							}}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Typography variant="Text/sm/Semibold">No Forms found</Typography>
						</Box>
					}
					onRowClick={(res) => {
						if (typeof editForm === "function") editForm(res?.id);
					}}
					dataGridProps={{ disableColumnMenu: true }}
				/>
			)}
			<ViewFormFlyout
				open={previewForm?.open}
				formString={previewForm?.formString}
				onClose={() => {
					setpreviewForm({
						open: false,
						formString: null,
					});
				}}
			/>
		</Box>
	);
}

export default FormsTable;
