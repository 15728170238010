import { Box, Typography, useTheme, Button, Fab, Badge, Autocomplete, Select, OutlinedInput, MenuItem, InputLabel, FormControl } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import TableController from "../../../../components/Tables/TableController";
import { StartupSortOptions, StartupTableColumns } from "./StartupTableColumns";
import { FounderSortOptions, FounderTableColumns } from "./FounderTableColumns";
import EditIcon from "@mui/icons-material/Edit";
import { getPartnerAdmins, getPartnerFounders, getPartnerStartups, removePartnerFromFounder } from "./functions";
import { useDispatch, useSelector } from "react-redux";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import ThemeEditFlyout from "./ThemeEditFlyout";
import services from "../../../../services";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import SortComponent from "../../../../components/Tables/SortComponent";
import FilterDrawer from "./FilterDrawer";
import SelectDrop from "./SelectDrop";
import ToggleButtons from "./ToggleButtons";
import FormHelperText from "@mui/material/FormHelperText";
import { SelectIconComponent } from "../../../NewOnboard/Components";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";
import UpdatedDialogConfirmation from "../../../../common/UpdatedDialogConfirmation";
import { ALERT_ICON_V1 } from "../../../../constants";
import Groups from "./Groups";
import Roles from "./Roles";
import ManageRoleAssignmentFlyout from "./ManageRoleAssignment";
import { AbilityContext, Can } from "../../../../services/abilities/context";
import { useAbility } from "@casl/react";
import { AdminTableColumns } from "./AdminTableColumns";

import FormBuilder from "../../../../components/Forms/FormBuilder";

function PartnerAdmin() {
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();
	const ability = useAbility(AbilityContext);

	const [partnerCodeSelect, setPartnerCodeSelect] = useState(null);
	const [loadedPartnerCode, setloadedPartnerCode] = useState(null);
	const partnerCode = useSelector((state) => state?.auth?.partnerCode);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const userType = useSelector((store) => store?.auth?.userType);
	const slackData = useSelector((store) => store?.slackWorkspace);
	const [startupsTableKey, setKeyForStartupsTable] = useState(0);
	const [foundersTableKey, setKeyForFoundersTable] = useState(0);
	const [groupsTableKey, setKeyForGroupsTable] = useState(0);
	const [rolesTableKey, setKeyForRolesTable] = useState(0);
	const [adminsTableKey, setKeyForAdminsTable] = useState(0);

	const [sortCriteriaStartups, setSortCriteriaStartups] = useState({ colName: "", direction: "" });
	const [sortCriteriaFounders, setSortCriteriaFounders] = useState({ colName: "", direction: "" });
	const [filterCriteriaStartups, setFilterCriteriaStartups] = useState({});
	const [filterCriteriaFounders, setFilterCriteriaFounders] = useState({});
	const [filterDrawerOpen, setFilterDrawerOpen] = useState({ startups: false, founders: false });
	const [showEdit, setshowEdit] = useState(false);
	const [partnerThemeAvailable, setpartnerThemeAvailable] = useState(false);
	const [options, setOptions] = useState(false);
	const [buttonLoader, setButtonLoader] = useState(false);

	const [roleAssignment, setRoleAssignment] = useState({ open: false, entityType: null, entityId: null });

	const handleSortChange = (newSortCriteria, type) => {
		if (type === "startups") setSortCriteriaStartups(newSortCriteria);
		else if (type === "founders") setSortCriteriaFounders(newSortCriteria);
	};

	const getPartnerTheme = async (pc) => {
		const resp = await services.getPartnerTheme(pc).catch((e) => {
			console.log("Unable to grab partner theme. Failed with error:", e);
		});
		if (resp?.data?.data?.partnerCode) setpartnerThemeAvailable(true);
		else setpartnerThemeAvailable(false);
	};

	const reloadStartupsTable = () => {
		setKeyForStartupsTable((prevKey) => prevKey + 1);
	};

	const reloadFoundersTable = () => {
		setKeyForFoundersTable((prevKey) => prevKey + 1);
	};

	const reloadGroupsTable = () => {
		setKeyForGroupsTable((prevKey) => prevKey + 1);
	};

	const reloadRolesTable = () => {
		setKeyForRolesTable((prevKey) => prevKey + 1);
	};

	const reloadAdminsTable = () => {
		setKeyForAdminsTable((prevKey) => prevKey + 1);
	};

	const reloadTables = (pc) => {
		setloadedPartnerCode(pc); //Prevent table flash on field clear.
		reloadStartupsTable();
		reloadFoundersTable();
		reloadGroupsTable();
		reloadRolesTable();
		reloadAdminsTable();
	};

	useEffect(() => {
		if (typeof isAdmin !== "undefined" && typeof userType !== "undefined") {
			if (!isAdmin && !ability.can("view", "AcceleratorDashboard")) {
				if (userType !== "PARTNER_USER") history.push(ROUTES_PATH.WORKSPACE);
			}
		}
	}, [isAdmin, userType]);

	useEffect(() => {
		getPartnerCodes();
		// getPartnerTheme(partnerCode);

		if (partnerCode) {
			setloadedPartnerCode(partnerCode); //Prevent table flash on field clear.
			setPartnerCodeSelect(partnerCode);
			getPartnerTheme(partnerCode);
		}
	}, [partnerCode]);

	useEffect(() => {
		getPartnerTheme(loadedPartnerCode); // Check to see if a valid theme obj exists.
	}, [loadedPartnerCode]);

	useEffect(() => {
		sortfilterStartups();
	}, [sortCriteriaStartups]);

	useEffect(() => {
		sortfilterFounders();
	}, [sortCriteriaFounders]);

	useEffect(() => {
		sortfilterStartups();
	}, [filterCriteriaStartups]);

	useEffect(() => {
		sortfilterFounders();
	}, [filterCriteriaFounders]);

	const sortfilterStartups = async () => {
		reloadStartupsTable();
	};

	const sortfilterFounders = async () => {
		reloadFoundersTable();
	};

	const getAdmins = async (currentPage, pageSize) => {
		const result = await getPartnerAdmins(
			currentPage,
			pageSize,
			partnerCodeSelect,
			sortCriteriaFounders?.direction,
			sortCriteriaFounders?.colName,
			filterCriteriaFounders?.string
		);
		return result;
	};

	const getFounders = async (currentPage, pageSize) => {
		const result = await getPartnerFounders(
			currentPage,
			pageSize,
			partnerCodeSelect,
			sortCriteriaFounders?.direction,
			sortCriteriaFounders?.colName,
			filterCriteriaFounders?.string
		);
		return result;
	};

	const getStartups = async (currentPage, pageSize) => {
		const result = await getPartnerStartups(
			currentPage,
			pageSize,
			partnerCodeSelect,
			sortCriteriaStartups?.direction,
			sortCriteriaStartups?.colName,
			formatFiltersForQuery(filterCriteriaStartups)
		);
		return result;
	};

	const getPartnerCodes = async () => {
		try {
			const res = await services.getPartnerCodesApi();
			const partnerCodes = res?.data?.data?.map((item) => item?.partnerCode);
			// partnerCodes?.unshift(partnerCode);
			setOptions(partnerCodes);
		} catch (error) {
			console.log(`Error fetching partner codes`, error);
		}
	};
	const openStartupProfile = (id) => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "STARTUP_PROFILE",
				profileId: id,
				adminActions: true,
			})
		);
	};

	const openUserProfile = (id) => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "ACCOUNT_PROFILE",
				profileId: id,
				adminActions: true,
			})
		);
	};

	const handleFounderCellClick = (params, event) => {
		// Prevent row click event when clicking on a specific cell
		event.stopPropagation();
		if (params.field === "company") {
			if (params?.value?.id) openStartupProfile(params?.value?.id);
			else openUserProfile(params?.row?.id);
		} else {
			openUserProfile(params?.row?.id);
		}
	};

	const formatFiltersForQuery = (filters) => {
		try {
			return Object.entries(filters)
				.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
				.join("&");
		} catch (e) {
			console.log("Unable to process filter for query string, failed with ", e);
			return "";
		}
	};

	const handleStartupFilter = (column, value) => {
		setFilterCriteriaStartups((prevFilters) => {
			const newFilters = { ...prevFilters };
			// If the value is not empty, blank, or null, set the filter
			if (value !== "" && value !== null && value !== undefined) {
				newFilters[column] = value;
			} else {
				// If the value is empty, blank, or null, delete the filter
				delete newFilters[column];
			}
			return newFilters;
		});
	};

	const clearStartupFilters = () => {
		setFilterCriteriaStartups({});
	};

	const clearFounderFilters = () => {
		setFilterCriteriaFounders({});
	};
	const handleInputChange = (event, newInputValue) => {
		setPartnerCodeSelect(newInputValue);
	};

	const handleStartupRoleDialog = (startupId) => {
		setRoleAssignment({ open: true, entityType: "STARTUP", entityId: startupId });
	};

	const handleUserRoleDialog = (userId) => {
		setRoleAssignment({ open: true, entityType: "USER", entityId: userId });
	};

	const handleAdminRoleDialog = (userId) => {
		setRoleAssignment({ open: true, entityType: "ADMIN", entityId: userId });
	};

	return (
		<Box
			sx={{
				px: { lg: 8, md: 4, xs: 2 },
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				margin: "0 auto",
				maxWidth: "1920px", // Figma specifies max content width
			}}
		>
			{isAdmin && (
				<Box
					sx={{
						borderRadius: "12px",
						border: "1px solid var(--gray-200, #EAECF0)",
						background: "var(--base-white, #FFF)",
						boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
						p: 3,
						display: "flex",
						alignItems: "center",
					}}
				>
					<Box sx={{ flex: 1 }}>
						<Typography variant="Text/md/Semibold" component={"p"}>
							StartupOS Admin Control
						</Typography>
						<Typography variant="Text/sm/Regular" component={"p"}>
							Select an accelerator to load startups and founders.
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "35%" }}>
						<FormControl>
							<Typography variant="count_title" sx={{ ml: 1, p: 1 }}>
								{"Accelerator Code"}
							</Typography>
							<Select
								value={partnerCodeSelect}
								onChange={(event) => {
									setPartnerCodeSelect(event?.target?.value);
									// setloadedPartnerCode(event?.target?.value);
									//getPartnerTheme(event?.target?.value);
									reloadTables(event?.target?.value);
								}}
								sx={{
									borderRadius: "30px",
								}}
							>
								{options &&
									options?.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Box>
				</Box>
			)}
			{(partnerCodeSelect || loadedPartnerCode) && (
				<Fragment>
					<Can I="view" a="AcceleratorAdmins">
						<TableController
							key={`admins_${adminsTableKey}`}
							requestData={(currentPage, pageSize) => getAdmins(currentPage, pageSize)}
							columnDefs={(() => {
								return [
									{
										field: "roles",
										headerName: "Roles",
										sortable: false,
										filterable: false,
										wrapText: true,
										width: 100,
										renderCell: (params) => (
											<Button
												variant="DS1"
												color="secondary"
												iconButton={true}
												small={true}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													handleAdminRoleDialog(params?.id);
												}}
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
													<path
														d="M8.66667 26.6665H6.66667C5.19391 26.6665 4 25.4726 4 23.9998V5.33317C4 3.86041 5.19391 2.6665 6.66667 2.6665H25.3333C26.8061 2.6665 28 3.86041 28 5.33317V23.9998C28 25.4726 26.8061 26.6665 25.3333 26.6665H23.3333M16 25.3332C18.2091 25.3332 20 23.5423 20 21.3332C20 19.124 18.2091 17.3332 16 17.3332C13.7909 17.3332 12 19.124 12 21.3332C12 23.5423 13.7909 25.3332 16 25.3332ZM16 25.3332L16.0286 25.3329L11.7716 29.5899L8.00033 25.8187L12.0262 21.7928M16 25.3332L20.2571 29.5899L24.0283 25.8187L20.0024 21.7928M12 7.99984H20M9.33333 12.6665H22.6667"
														stroke="white"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</Button>
										),
										renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
									},
									...AdminTableColumns(buttonLoader, setButtonLoader, reloadAdminsTable),
								];
							})()}
							title="Admins"
							showCount
							countLabel={{ singular: "Admin", plural: "Admins" }}
							icon={
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 28" fill="none">
									<path
										d="M11.0693 26.8198C11.3645 26.992 11.512 27.0781 11.7203 27.1228C11.882 27.1575 12.1178 27.1575 12.2795 27.1228C12.4878 27.0781 12.6354 26.992 12.9306 26.8198C15.528 25.3045 22.6666 20.5446 22.6666 14V7.62346C22.6666 6.55744 22.6666 6.02444 22.4922 5.56626C22.3382 5.16151 22.0879 4.80036 21.763 4.51403C21.3953 4.18991 20.8962 4.00276 19.898 3.62845L12.749 0.947556C12.4718 0.843608 12.3332 0.791634 12.1906 0.77103C12.0641 0.752755 11.9357 0.752755 11.8092 0.77103C11.6666 0.791634 11.528 0.843608 11.2509 0.947556L4.10179 3.62845C3.10365 4.00276 2.60458 4.18991 2.2368 4.51403C1.9119 4.80036 1.66162 5.16151 1.5076 5.56626C1.33325 6.02444 1.33325 6.55744 1.33325 7.62346V14C1.33325 20.5446 8.47187 25.3045 11.0693 26.8198Z"
										stroke={theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							}
							noResultsMessage={
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										gap: "16px",
										alignSelf: "stretch",
										flex: 1,
										justifyContent: "center",
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
										<path
											d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
											stroke={theme?.palette?.primary?.main}
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<Typography variant="Text/sm/Semibold">No admins found</Typography>
								</Box>
							}
							onRowClick={() => {}}
							onCellClick={() => {}}
							dataGridProps={{ disableColumnMenu: true }}
						/>
					</Can>
					<Can I="view" a="AcceleratorStartups">
						<TableController
							key={`startups_${startupsTableKey}`}
							requestData={(currentPage, pageSize) => getStartups(currentPage, pageSize)}
							columnDefs={(() => {
								return [
									// Comment out attribute selector for v1.0 as only PARTNER_USER is supported.
									//{
									//	field: "roles",
									//	headerName: "Roles",
									//	sortable: false,
									//	filterable: false,
									//	wrapText: true,
									//	width: 100,
									//	renderCell: (params) => (
									//		<Button
									//			variant="DS1"
									//			color="secondary"
									//			iconButton={true}
									//			small={true}
									//			onClick={(e) => {
									//				e.preventDefault();
									//				e.stopPropagation();
									//				handleStartupRoleDialog(params?.id);
									//			}}
									//		>
									//			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
									//				<path
									//					d="M8.66667 26.6665H6.66667C5.19391 26.6665 4 25.4726 4 23.9998V5.33317C4 3.86041 5.19391 2.6665 6.66667 2.6665H25.3333C26.8061 2.6665 28 3.86041 28 5.33317V23.9998C28 25.4726 26.8061 26.6665 25.3333 26.6665H23.3333M16 25.3332C18.2091 25.3332 20 23.5423 20 21.3332C20 19.124 18.2091 17.3332 16 17.3332C13.7909 17.3332 12 19.124 12 21.3332C12 23.5423 13.7909 25.3332 16 25.3332ZM16 25.3332L16.0286 25.3329L11.7716 29.5899L8.00033 25.8187L12.0262 21.7928M16 25.3332L20.2571 29.5899L24.0283 25.8187L20.0024 21.7928M12 7.99984H20M9.33333 12.6665H22.6667"
									//					stroke="white"
									//					stroke-width="1.5"
									//					stroke-linecap="round"
									//					stroke-linejoin="round"
									//				/>
									//			</svg>
									//		</Button>
									//	),
									//	renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
									//},
									...StartupTableColumns(buttonLoader, setButtonLoader, reloadStartupsTable),
								];
							})()}
							title="Startups"
							showCount
							countLabel={{ singular: "Startup", plural: "Startups" }}
							icon={
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
										stroke={theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							}
							noResultsMessage={
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										gap: "16px",
										alignSelf: "stretch",
										flex: 1,
										justifyContent: "center",
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
										<path
											d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
											stroke={theme?.palette?.primary?.main}
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<Typography variant="Text/sm/Semibold">No startups found</Typography>
								</Box>
							}
							sortComponent={
								<SortComponent
									sortOptions={StartupSortOptions}
									onSortChange={(obj) => handleSortChange(obj, "startups")}
									currentSort={sortCriteriaStartups}
								/>
							}
							filterComponent={
								<Fragment>
									<Badge
										badgeContent={Object.keys(filterCriteriaStartups).length ?? 0}
										color={"tertiary"}
										overlap="circular"
										sx={{
											"& .MuiBadge-badge": {
												fontFamily: (theme) => theme.typography["Text/xs/Semibold"],
												fontSize: ".8rem",
											},
										}}
									>
										<Button
											variant="DS1"
											iconButton={true}
											onClick={() =>
												setFilterDrawerOpen((prevState) => ({
													...prevState,
													startups: true,
												}))
											}
										>
											<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 26 26" fill="none">
												<path
													d="M17.0673 9H4.33333C2.49238 9 1 7.50762 1 5.66667C1 3.82572 2.49238 2.33333 4.33333 2.33333H17.0673M8.93266 23.6667H21.6667C23.5076 23.6667 25 22.1743 25 20.3333C25 18.4924 23.5076 17 21.6667 17H8.93266M1 20.3333C1 22.9107 3.08934 25 5.66667 25C8.244 25 10.3333 22.9107 10.3333 20.3333C10.3333 17.756 8.244 15.6667 5.66667 15.6667C3.08934 15.6667 1 17.756 1 20.3333ZM25 5.66667C25 8.244 22.9107 10.3333 20.3333 10.3333C17.756 10.3333 15.6667 8.244 15.6667 5.66667C15.6667 3.08934 17.756 1 20.3333 1C22.9107 1 25 3.08934 25 5.66667Z"
													stroke="white"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</Button>
									</Badge>
									<FilterDrawer
										isFilterOpen={filterDrawerOpen?.startups}
										closeDrawer={() =>
											setFilterDrawerOpen((prevState) => ({
												...prevState,
												startups: false,
											}))
										}
										clearAll={() => clearStartupFilters()}
										title={"Startup Filters"}
										filters={
											<Fragment>
												<ToggleButtons
													label={"Revenue"}
													selectOptions={["Yes", "No"]}
													onOptionChange={(value) => handleStartupFilter("revenue", value)}
													currentOption={filterCriteriaStartups?.revenue}
												/>
												<SelectDrop
													label={"Financing"}
													sortOptions={["None", "Priced Round", "Safe", "Conv. Note"]}
													onSortChange={(value) => handleStartupFilter("financing", value)}
													currentSort={filterCriteriaStartups?.financing}
												/>
												<SelectDrop
													label={"Current Round"}
													sortOptions={["Pre-Seed", "Seed", "Series A", "Growth"]}
													onSortChange={(value) => handleStartupFilter("currentStage", value)}
													currentSort={filterCriteriaStartups?.currentStage}
												/>
												input
												<SelectDrop
													label={"Seeking"}
													sortOptions={["Seed", "Series A"]}
													onSortChange={(value) => handleStartupFilter("seeking", value)}
													currentSort={filterCriteriaStartups?.seeking}
												/>
											</Fragment>
										}
									/>
								</Fragment>
							}
							onRowClick={(res) => openStartupProfile(res?.id)}
							dataGridProps={{ disableColumnMenu: true }}
						/>
					</Can>
					<Can I="view" a="AcceleratorFounders">
						<TableController
							key={`founders_${foundersTableKey}`}
							requestData={(currentPage, pageSize) => getFounders(currentPage, pageSize)}
							columnDefs={(() => {
								return [
									// Comment out attribute selector for v1.0 as only PARTNER_USER is supported.
									//{
									//	field: "roles",
									//	headerName: "Roles",
									//	sortable: false,
									//	filterable: false,
									//	wrapText: true,
									//	width: 100,
									//	renderCell: (params) => (
									//		<Button
									//			variant="DS1"
									//			color="secondary"
									//			iconButton={true}
									//			small={true}
									//			onClick={(e) => {
									//				e.preventDefault();
									//				e.stopPropagation();
									//				handleUserRoleDialog(params?.id);
									//			}}
									//		>
									//			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
									//				<path
									//					d="M8.66667 26.6665H6.66667C5.19391 26.6665 4 25.4726 4 23.9998V5.33317C4 3.86041 5.19391 2.6665 6.66667 2.6665H25.3333C26.8061 2.6665 28 3.86041 28 5.33317V23.9998C28 25.4726 26.8061 26.6665 25.3333 26.6665H23.3333M16 25.3332C18.2091 25.3332 20 23.5423 20 21.3332C20 19.124 18.2091 17.3332 16 17.3332C13.7909 17.3332 12 19.124 12 21.3332C12 23.5423 13.7909 25.3332 16 25.3332ZM16 25.3332L16.0286 25.3329L11.7716 29.5899L8.00033 25.8187L12.0262 21.7928M16 25.3332L20.2571 29.5899L24.0283 25.8187L20.0024 21.7928M12 7.99984H20M9.33333 12.6665H22.6667"
									//					stroke="white"
									//					stroke-width="1.5"
									//					stroke-linecap="round"
									//					stroke-linejoin="round"
									//				/>
									//			</svg>
									//		</Button>
									//	),
									//	renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
									//},
									...FounderTableColumns(buttonLoader, setButtonLoader, reloadFoundersTable),
								];
							})()}
							title="Founders"
							showCount
							countLabel={{ singular: "Founder", plural: "Founders" }}
							icon={
								<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none">
									<path
										d="M28.3333 25V22.3333C28.3333 19.8482 26.6336 17.7601 24.3333 17.168M19.6666 1.38768C21.6212 2.17886 23 4.09508 23 6.33333C23 8.57159 21.6212 10.4878 19.6666 11.279M21.6666 25C21.6666 22.515 21.6666 21.2725 21.2606 20.2924C20.7193 18.9855 19.6811 17.9473 18.3743 17.406C17.3942 17 16.1516 17 13.6666 17H9.66663C7.1816 17 5.93909 17 4.95898 17.406C3.65216 17.9473 2.6139 18.9855 2.0726 20.2924C1.66663 21.2725 1.66663 22.515 1.66663 25M17 6.33333C17 9.27885 14.6121 11.6667 11.6666 11.6667C8.72111 11.6667 6.33329 9.27885 6.33329 6.33333C6.33329 3.38781 8.72111 1 11.6666 1C14.6121 1 17 3.38781 17 6.33333Z"
										stroke={theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							}
							noResultsMessage={
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										gap: "16px",
										alignSelf: "stretch",
										flex: 1,
										justifyContent: "center",
									}}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
										<path
											d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
											stroke={theme?.palette?.primary?.main}
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<Typography variant="Text/sm/Semibold">No founders found</Typography>
								</Box>
							}
							onRowClick={(params) => openUserProfile(params?.row?.id)}
							onCellClick={handleFounderCellClick}
							dataGridProps={{ disableColumnMenu: true }}
						/>
					</Can>

					<Can I="view" a="AcceleratorGroups">
						<Groups tableKey={`groupsTable_${groupsTableKey}`} partnerCode={partnerCodeSelect || loadedPartnerCode} />
					</Can>

					<Can I="view" a="AcceleratorRoles">
						<Roles tableKey={`rolesTable_${rolesTableKey}`} partnerCode={partnerCodeSelect || loadedPartnerCode} />
					</Can>

					<Can I="manage" a="AcceleratorRoles">
						<ManageRoleAssignmentFlyout
							entity={roleAssignment}
							onClose={() => setRoleAssignment({ open: false, entityType: null, entityId: null })}
							partnerCode={partnerCodeSelect || loadedPartnerCode}
						/>
					</Can>

					{ability.can("manage", "AcceleratorConfiguration") && partnerThemeAvailable && (
						<Fragment>
							<Box
								sx={{
									ml: "auto",
									position: "fixed",
									right: 20,
									display: "flex",
									gap: "8px",
								}}
							>
								<Fab
									sx={{
										bgcolor: (theme) => theme.palette.primary.main,
										color: (theme) => theme.palette.secondary.white,
										"&:hover": {
											bgcolor: (theme) => theme.palette.primary.dark,
										},
									}}
									aria-label="Edit"
									color="secondary.white"
									onClick={() => {
										setshowEdit(true);
									}}
								>
									<EditIcon />
								</Fab>
							</Box>
							<ThemeEditFlyout open={showEdit} onClose={() => setshowEdit(false)} partnerCode={loadedPartnerCode} />
						</Fragment>
					)}
				</Fragment>
			)}
			<UpdatedDialogConfirmation />
		</Box>
	);
}

export default PartnerAdmin;
