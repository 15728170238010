import React, { useEffect, useState } from "react";
import ManageGroupFlyout from "./ManageGroup";
import { Box, Button } from "@mui/material";
import axios from "axios";
import GroupsTable from "./GroupsTable";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "../../../../services/abilities/context";

function Groups({ partnerCode, tableKey }) {
	const [manageGroup, setmanageGroup] = useState({ editMode: false, group: null, open: false });

	const [groupsTableKey, setKeyForGroupsTable] = useState(0);

	const ability = useAbility(AbilityContext);

	useEffect(() => {
		setKeyForGroupsTable(tableKey);
	}, [tableKey]);

	return (
		<Box>
			<GroupsTable
				tableKey={tableKey}
				partnerCode={partnerCode}
				setKeyForGroupsTable={setKeyForGroupsTable}
				groupsTableKey={groupsTableKey}
				editGroup={(id) => {
					if (ability.can("manage", "AcceleratorGroups"))
						setmanageGroup({
							open: true,
							editMode: true,
							group: id,
							partnerCode: partnerCode,
						});
				}}
			/>
			<Can I="manage" a="AcceleratorGroups">
				<Button
					variant="DS1"
					onClick={() => {
						setmanageGroup({
							open: true,
							editMode: false,
							group: null,
							partnerCode: partnerCode,
						});
					}}
				>
					Create Group
				</Button>

				<ManageGroupFlyout
					open={manageGroup?.open}
					editMode={manageGroup?.editMode}
					partnerCode={partnerCode}
					group={manageGroup?.group}
					onClose={() => {
						setmanageGroup({
							open: false,
							editMode: false,
							group: null,
							partnerCode: partnerCode,
						});
						setKeyForGroupsTable((prevKey) => prevKey + 1);
					}}
				/>
			</Can>
		</Box>
	);
}

export default Groups;
