import { faker } from "@faker-js/faker";
import services from "../../../../services";
import axios from "axios";
import { addOrRemoveCompanyToTrackSection } from "../../../Investor/Dashboard/Functions/common";
import { getPartnerGroupsUrl, getAttributesUrl, getRolesUrl, getPartnerRolesUrl } from "../../../../utils/urls";

export const getPartnerGroups = async (page, perPage, partnerCode, sort, sortCol, filterString) => {
	const result = await axios.get(getPartnerGroupsUrl(partnerCode));

	if (!result && !result?.data) return null;

	try {
		const models = result?.data;

		const newModels = models.map((model) => ({
			id: model?.groupId,
			groupName: model?.groupName,
			groupDescription: model?.groupDescription,
			totalCompanies: model?.companyIds?.length,
		}));

		return {
			data: {
				totalElements: newModels?.length,
				models: newModels,
			},
		};
	} catch (e) {
		console.log("Issue with processing Partner Groups. Failed with error:", e);
	}
};

export const getPartnerRoles = async (page, perPage, partnerCode, sort, sortCol, filterString) => {
	const URL = partnerCode?.length > 0 ? getPartnerRolesUrl(partnerCode) : getRolesUrl();
	const result = await axios.get(URL);

	if (!result && !result?.data) return null;

	try {
		const models = result?.data;

		const newModels = models.map((model) => ({
			id: model?.groupId,
			roleName: model?.name,
			roleDescription: model?.description,
		}));

		return {
			data: {
				totalElements: newModels?.length,
				models: newModels,
			},
		};
	} catch (e) {
		console.log("Issue with processing Roles. Failed with error:", e);
	}
};

export const getAttributes = async (page, perPage, partnerCode, sort, sortCol, filterString) => {
	const result = await axios.get(getAttributesUrl());
	return result;
};

export const getPartnerStartups = async (page, perPage, partnerCode, sort, sortCol, filterString) => {
	const result = await services.getPartnerStartups(page + 1, perPage, partnerCode, sort, sortCol, filterString);

	if (!result && !result?.data?.data?.totalElements && !result?.data?.data?.models) return null;

	try {
		const { totalElements, models } = result?.data?.data;

		const newModels = models.map((model) => ({
			id: model.id,
			name: {
				name: model?.companyName,
				img: model?.logoUrl,
			},
			location: model?.city && model?.state ? `${model?.city}, ${model?.state}` : "",
			score: model?.currentScore,
			founded: model?.foundedYear,
			size: model?.companySize,
			revenue: model?.revenue,
			capitalRaised: model?.capitalRaised,
			financing: model?.financing,
			currentRound: model?.currentStage,
			seeking: model?.seeking,
			ask: model?.ask,
			partner: {
				partnerName: model?.partnerName,
				inTracked: model?.inTracked,
			},
		}));

		return {
			data: {
				totalElements: totalElements,
				models: newModels,
			},
		};
	} catch (e) {
		console.log("Issue with processing Partner Startups. Failed with error:", e);
	}
};

export const getPartnerFounders = async (page, perPage, partnerCode, sort, sortCol, filterString) => {
	const result = await services.getPartnerUsers(page + 1, perPage, partnerCode, sort, sortCol, filterString);
	if (!result && !result?.data?.data?.totalElements && !result?.data?.data?.models) return null;

	try {
		const { totalElements, models } = result?.data?.data;

		const newModels = models.map((model) => ({
			id: model.id,
			name: {
				name: model.name,
				img: model.picture,
			},
			location: model?.city && model?.state ? `${model?.city}, ${model?.state}` : "",
			profileCompletePercent: model?.userProfilePercentage,
			company: {
				name: model?.companyModel?.companyName,
				id: model?.companyModel?.id,
			},
			partner: {
				partnerName: model?.partnerName,
				invitationStatus: model?.invitationStatus,
			},
		}));

		return {
			data: {
				totalElements: totalElements,
				models: newModels,
			},
		};
	} catch (e) {
		console.log("Issue with processing Partner Founders. Failed with error:", e);
	}
};

export const getPartnerAdmins = async (page, perPage, partnerCode, sort, sortCol, filterString) => {
	const result = await services.getPartnerAdmins(page + 1, perPage, partnerCode, sort, sortCol, filterString);
	if (!result && !result?.data?.data?.totalElements && !result?.data?.data?.models) return null;

	try {
		const { totalElements, models } = result?.data?.data;

		const newModels = models.map((model) => ({
			id: model.id,
			name: {
				name: model.name,
				img: model.picture,
			},
		}));

		return {
			data: {
				totalElements: totalElements,
				models: newModels,
			},
		};
	} catch (e) {
		console.log("Issue with processing Partner Admins. Failed with error:", e);
	}
};

export const updatePartnerTheme = async (payload, isAdmin, partnerCode) => {
	const url = isAdmin ? `api/m1/v1/partners?partnerCode=${partnerCode}` : "api/m1/v1/partners/users";

	const result = await axios.put(`${process.env.REACT_APP_API_URL}/${url}`, payload).catch((e) => {
		console.log("Failed to update partner theme. Failed with error:", e);
	});

	return result;
};

// Dev Functions:

export const mockFetchData = async (currentPage, pageSize) => {
	// Simulate a delay like an API call
	await new Promise((resolve) => setTimeout(resolve, 500));

	// Generate fake data
	const models = generateFakeData(pageSize);

	// Return the mock data
	return {
		data: {
			models: models,
			totalElements: 50, // You can set a fixed number or make it dynamic
		},
	};
};

const generateFakeData = (pageSize) => {
	const data = [];
	for (let i = 0; i < pageSize; i++) {
		data.push({
			id: faker.datatype.uuid(),
			score: faker.datatype.number({ min: 0, max: 100 }),
			name: {
				name: faker.company.name(),
				img: faker.image.business(),
			},
			location: `${faker.address.city()}, ${faker.address.stateAbbr()}`,
			founded: faker.date.past(20).getFullYear().toString(),
			industry: faker.commerce.department(),
			size: `${faker.datatype.number({ min: 10, max: 1000 })} employees`,
			revenue: `$${faker.finance.amount()}M`,
			financeTD: faker.helpers.arrayElement(["Seed", "Series A", "Series B", "Series C"]),
			financing: faker.helpers.arrayElement(["Yes", "No"]),
			currentRound: faker.helpers.arrayElement(["Series A", "Series B", "Series C", "IPO"]),
			seeking: faker.helpers.arrayElement(["Yes", "No"]),
			ask: `$${faker.finance.amount()}M`,
		});
	}
	return data;
};

export const removePartnerFromFounder = async (removePartnerFromFounderApi, setButtonLoader, handleCloseConfirmation, reloadFoundersTable) => {
	setButtonLoader(true);
	const result = await services.removePartnerFromFounderApi(removePartnerFromFounderApi);
	if (result) {
		setButtonLoader(false);
		handleCloseConfirmation();
		reloadFoundersTable();
	}
};

export const untrackPartnerFromFounder = async (trackedCompanyId, setButtonLoader, reloadStartupsTable, handleCloseConfirmation) => {
	setButtonLoader(true);
	const result = await addOrRemoveCompanyToTrackSection({ trackedCompanyIds: [trackedCompanyId], isRemove: true });
	if (result) {
		setButtonLoader(false);
		reloadStartupsTable();
		handleCloseConfirmation();
	}
};
