import React, { Fragment } from "react";
import { WorkspacePopoverBtn, UserSection, UserPopoverBtn } from "./styles";
import { Popover, Divider, Typography, Box, useMediaQuery, Button, Avatar, ListItemAvatar, Chip, Link } from "@mui/material";
import theme from "../../../constants/Theme";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountSettingsPopup from "../../../containers/Dashboard/AccountSettings/AccountSettingsPopup";
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import ProfileMenuDrawer from "../../../containers/Mentor/Network/StartupProfile/Components/ProfileMenuDrawer";
import { USER_TYPES } from "../../../utils/userTypes";
import ROUTES_PATH from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { setAuthData } from "../../../modules/actions";
import { AVATAR_ICON_V2, LOGOUT_ICON_V2, NEW_PROFILE_LOGO, SETTING_ICON_V3, SUBSCRIPTION_ICON_V2, SWITCH_ICON_V2 } from "../../../constants";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import services from "../../../services";
import AvatarWithFallBack from "../../../common/AvatarWithFallBack";
import PaymentPlanDialog from "../../PaymentPlanDialog";
import { handleMixPanel, handleMixPanelSetUser } from "../../../utils/mixPanelEventHandling";

export default function UpdatedWorkspaceUser({
	onLogout,
	userData,
	showAccountSettingPopup,
	closeAccountSettingsPopup,
	handleOpen,
	userName,
	isPowerupPages = false,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [openSubscriptionSettings, setOpenSubscriptionSettings] = useState(false);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const userType = useSelector((store) => store?.auth?.userType);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const [showPlanModal, setShowPlanModal] = useState(false);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) {
			getSubscriptionDetails();
		}
	}, [userType]);

	useEffect(() => {
		if (showAccountSettingPopup) {
			handleClose();
		}
	}, [showAccountSettingPopup]);

	const updateMixPanelUserData = (subscriptionStatus) => {
		let currentProperties = {
			subscription_status: subscriptionStatus,
		};
		handleMixPanelSetUser(currentProperties);
	};

	const getSubscriptionDetails = async () => {
		try {
			const details = await services.getSubscriptionDetails();
			if (details?.data?.data) {
				updateMixPanelUserData("Subscribed");
				dispatch(setAuthData({ isPremiumPlanAccess: true }));
			}

			if (details?.data?.data?.customerId && details?.data?.data?.isCreatedByUser) {
				setOpenSubscriptionSettings(true);
			} else {
				setOpenSubscriptionSettings(false);
			}
		} catch (err) {
			updateMixPanelUserData("Not Subsribed");

			setOpenSubscriptionSettings(false);
		}
	};

	const handleSubscriptionSettings = async () => {
		if (isPremiumPlanAccess || isAdmin) {
			try {
				const settings = await services.getSubscriptionSettings();
				window.open(settings?.data?.data?.url || process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
			} catch (err) {
				window.open(process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
			}
		} else {
			setShowPlanModal(true);
		}
		handleMixPanel("Manage Subscription Viewed");
		handleClose();
		setOpenDrawer(false);
	};

	const StartupUserPopOver = ({ userType }) => {
		return (
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				PaperProps={{
					sx: {
						borderRadius: "24px",
						boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
						minWidth: "280px",
						p: 3,
					},
				}}
			>
				<WorkspacePopoverBtn>
					<List disablePadding>
						<ListItem disablePadding>
							<ListItemIcon>
								<Box component={"img"} src={SETTING_ICON_V3} alt="settings" />
							</ListItemIcon>
						</ListItem>
						<ListItem disablePadding sx={{ mt: 1 }}>
							<ListItemText primary={<Typography variant="Text/md/Semibold">Account</Typography>} />
						</ListItem>
						{userType !== "PARTNER_USER" && (
							<ListItem disablePadding sx={{ mt: 1 }}>
								<ListItemButton
									onClick={() => {
										handelProfileClick();
									}}
									sx={{
										"&:hover": {
											backgroundColor: "white",
										},
										px: 0,
									}}
									disableRipple
								>
									<ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
										<Box component={"img"} src={AVATAR_ICON_V2} alt="avatar" />
									</ListItemIcon>
									<ListItemText>
										<Typography variant="Text/sm/Regular">Account Profile</Typography>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						)}

						<>
							<ListItem disablePadding>
								<ListItemButton
									onClick={handleOpen}
									sx={{
										"&:hover": {
											backgroundColor: "white",
										},
										px: 0,
									}}
									disableRipple
								>
									<ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
										<Box component={"img"} src={SWITCH_ICON_V2} alt="switch" />
									</ListItemIcon>
									<ListItemText>
										<Typography variant="Text/sm/Regular">Account Settings</Typography>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						</>

						{openSubscriptionSettings && (
							<>
								{/* <ListItem disablePadding>
									<ListItemButton
										onClick={handleSubscriptionSettings}
										sx={{
											"&:hover": {
												backgroundColor: "white",
											},
											px: 0,
										}}
										disableRipple
									>
										<ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
											<Box component={"img"} src={SUBSCRIPTION_ICON_V2} alt="subscription" />
										</ListItemIcon>
										<ListItemText>
											<Typography variant="Text/sm/Regular">Manage Subscription</Typography>
										</ListItemText>
									</ListItemButton>
								</ListItem> */}
							</>
						)}

						{!isPremiumPlanAccess &&
							!isAdmin &&
							userType !== "PARTNER_USER" && ( //free user sos-2838
								<>
									{/* <ListItem disablePadding>
										<ListItemButton
											onClick={handleSubscriptionSettings}
											sx={{
												"&:hover": {
													backgroundColor: "white",
												},
												px: 0,
											}}
											disableRipple
										>
											<ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
												<Box component={"img"} src={SUBSCRIPTION_ICON_V2} alt="logout" />
											</ListItemIcon>
											<ListItemText>
												<Typography variant="Text/sm/Regular">Manage Subscription</Typography>
											</ListItemText>
										</ListItemButton>
									</ListItem> */}
								</>
							)}

						<ListItem disablePadding>
							<ListItemButton
								onClick={handleLogout}
								sx={{
									"&:hover": {
										backgroundColor: "white",
									},
									px: 0,
								}}
								disableRipple
							>
								<ListItemIcon sx={{ minWidth: 0, pr: 2 }}>
									<Box component={"img"} src={LOGOUT_ICON_V2} alt="logout" />
								</ListItemIcon>
								<ListItemText>
									<Typography variant="Text/sm/Regular">Logout</Typography>
								</ListItemText>
							</ListItemButton>
						</ListItem>
					</List>
				</WorkspacePopoverBtn>
				<Box
					sx={{
						display: "flex",
						justifyContent: "left",
						flexDirection: "column",
						alignItems: "start",
						mt: 2,
					}}
					gap={1}
				>
					<Link
						variant="Text/xs/Regular"
						rel="noopener"
						href="https://startupos.com/privacy-policy/"
						target="_blank"
						underline="hover"
						sx={{ color: "rgba(102, 112, 133, 1)" }}
						onClick={() => {
							handleMixPanel("Privacy Policy Viewed");
						}}
					>
						Privacy Policy
					</Link>
					<Link
						variant="Text/xs/Regular"
						rel="noopener"
						href="https://startupos.com/terms-of-service/"
						target="_blank"
						onClick={() => {
							handleMixPanel("Terms of Service Viewed");
						}}
						underline="hover"
						sx={{ color: "rgba(102, 112, 133, 1)" }}
					>
						Terms of Service
					</Link>
				</Box>
			</Popover>
		);
	};

	//remove later
	const OtherUserPopOver = () => {
		return (
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<UserPopoverBtn onClick={onLogout}>Logout</UserPopoverBtn>
			</Popover>
		);
	};

	const handleLogout = () => {
		setConfirmPopup(true);
		handleClose();
	};

	const handleClick = (event) => {
		// setAnchorEl(event.currentTarget);
		if (isSmallScreen) {
			setOpenDrawer(true);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const popUpClose = () => {
		setConfirmPopup(false);
		handleClose();
	};
	const handelProfileClick = () => {
		if (userType === USER_TYPES.MENTOR_USER) {
			history.push(ROUTES_PATH.MY_MENTOR_PROFILE);
		} else {
			history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
		}
		dispatch(setAuthData({ currentSelectedNavItem: "accountProfile" }));
		handleClose();
	};

	const handlePremium = async (e) => {
		// e.stopPropagation();
		// if (isPremiumPlanAccess || isAdmin) {
		// 	try {
		// 		const settings = await services.getSubscriptionSettings();
		// 		window.open(settings?.data?.data?.url || process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
		// 	} catch (err) {
		// 		window.open(process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
		// 	}
		// } else {
		// 	setShowPlanModal(true);
		// }
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<Fragment>
			<UserSection aria-describedby={id} variant="contained">
				<Box display="flex" justifyContent="center" alignItems="center" gap={1.25}>
					<AvatarWithFallBack
						src={userData?.picture}
						style={{
							width: "50px",
							height: "50px",
						}}
						onClick={handleClick}
					/>
					<Box display="flex" flexDirection="column">
						<Box display="flex" onClick={handleClick}>
							<Typography
								variant="flyout_title"
								sx={{ textTransform: "capitalize" }}
								color={isPowerupPages ? "secondary.white" : "secondary.black"}
							>
								{`Hello ${userName || ""}!`}
							</Typography>
							{open ? <KeyboardArrowUp sx={{ alignSelf: "center", ml: "4px" }} /> : <KeyboardArrowDown sx={{ alignSelf: "center", ml: "4px" }} />}
						</Box>
						{(userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) && (
							<Box display="flex" alignItems="center" gap={1} onClick={handlePremium} sx={{ cursor: "default" }}>
								<Avatar
									sx={{
										width: "30px",
										height: "30px",
										background: isPremiumPlanAccess || isAdmin ? "#12B76A" : "#EAECF0",
									}}
								>
									<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M13.3587 8.12909C13.3419 8.05722 13.3076 7.99061 13.259 7.93514C13.2103 7.87967 13.1487 7.83705 13.0796 7.81107L8.6475 6.14912L9.76993 0.536983C9.78908 0.441198 9.77647 0.34178 9.734 0.25381C9.69154 0.16584 9.62155 0.0941179 9.53465 0.049522C9.44774 0.00492616 9.34866 -0.0101102 9.25243 0.00669387C9.15621 0.0234979 9.06809 0.0712253 9.00144 0.142637L0.620643 9.12206C0.570311 9.17603 0.534056 9.24157 0.515087 9.31288C0.496118 9.38419 0.495019 9.45908 0.511888 9.53092C0.528756 9.60276 0.563072 9.66934 0.611799 9.72475C0.660526 9.78017 0.722162 9.82273 0.791252 9.84865L5.22335 11.5106L4.10092 17.1227C4.08165 17.2185 4.09416 17.318 4.13657 17.4061C4.17897 17.4941 4.24895 17.5659 4.33588 17.6106C4.3994 17.6429 4.46964 17.6599 4.54091 17.66C4.60254 17.66 4.66352 17.6473 4.72003 17.6227C4.77655 17.5981 4.8274 17.5622 4.86941 17.5171L13.2502 8.53765C13.3004 8.48369 13.3366 8.41819 13.3555 8.34694C13.3745 8.27568 13.3756 8.20086 13.3587 8.12909ZM5.2892 15.7489L6.17591 11.313C6.19695 11.2085 6.18012 11.1 6.12843 11.0067C6.07675 10.9135 5.99358 10.8417 5.89381 10.8042L1.73334 9.24403L8.57716 1.91084L7.69269 6.34742C7.67165 6.45192 7.68849 6.56049 7.74017 6.65371C7.79185 6.74694 7.87502 6.81873 7.97479 6.85626L12.1353 8.41643L5.2892 15.7489Z"
											fill={isPremiumPlanAccess || isAdmin ? "white" : "#000000"}
										/>
									</svg>
								</Avatar>
								<Typography variant="Text/sm/Regular">
									{/* {isPremiumPlanAccess || isAdmin ? (
										`Premium User`
									) : (
										<>
											Free User |
											<Chip
												sx={{
													ml: 1,
													color: (theme) => theme.palette.primary.primaryPurple,
													borderColor: (theme) => theme.palette.primary.primaryPurple,
													cursor: "pointer",
												}}
												size="small"
												label="Upgrade Now!"
												variant="outlined"
											/>
										</>
									)} */}
									Premium User
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</UserSection>
			<StartupUserPopOver userType={userType} />

			<ProfileMenuDrawer
				openDrawer={openDrawer}
				setOpenDrawer={setOpenDrawer}
				userData={userData}
				userType={userType}
				handleLogout={handleLogout}
				handleOpen={handleOpen}
				handelProfileClick={handelProfileClick}
				dummyLogo={NEW_PROFILE_LOGO}
				handleSubscriptionSettings={handleSubscriptionSettings}
			/>

			<MessageHandlerModal
				isOpen={confirmPopup}
				onCancel={popUpClose}
				onOk={() => onLogout()}
				okText="Ok"
				cancelText={"Cancel"}
				heading="Logout"
				text={"Are you sure you want to logout?"}
				messageType="primary"
			/>

			<AccountSettingsPopup open={showAccountSettingPopup} onClose={closeAccountSettingsPopup} />

			{showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} />}
		</Fragment>
	);
}
