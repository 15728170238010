import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ProfileContext } from "..";
import services from "../../../services";
import { useLazyQuery } from "@apollo/client";
import { GET_FOUNDER_DATA_QUERY } from "./Function/common";
import { setAuthData } from "../../../modules/actions";
import { setUserProfileData } from "../../../modules/actions/userProfileActions";
import { getRoleValue, handleExpAndEduSort } from "../../../utils/commonFunctions";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import ROUTES_PATH from "../../../constants/routes";

export const AccountProfile = ({ children }) => {
	const [errors, setErrors] = useState({});
	const [viewContext, setViewContext] = useState(null);
	const [profileDataLoading, setProfileDataLoading] = useState(true);
	const [profileData, setProfileData] = useState({});
	const userData = useSelector((store) => ({
		userType: store?.auth?.userType,
		companyId: store?.auth?.companyId,
		userId: store?.auth?.userId,
		userPicture: store?.auth?.userData?.picture,
		userName: store?.auth?.userName,
	}));
	const dispatch = useDispatch();
	const editorOpen = useSelector((state) => state?.userProfileData?.userProfileEditOpen);

	const params = useParams();
	const slidingProfileData = useSelector((store) => ({
		open: store?.slidingProfile?.open,
		profileType: store?.slidingProfile?.profileType,
		profileId: store?.slidingProfile?.profileId,
	}));
	const userId = slidingProfileData?.open ? slidingProfileData?.profileId : params?.id ? params?.id : null;

	const [loadUserData, { loading, error, data }] = useLazyQuery(GET_FOUNDER_DATA_QUERY, {
		onCompleted: function (data) {
			if (data?.userById) {
				let sortedData = handleExpAndEduSort(data?.userById);
				setProfileData(sortedData);
			}
		},
		onError: (err) => {
			setErrors({
				error: viewContext?.id
					? "Unable to successfully retrieve account profile by ID:" + viewContext?.id
					: "Unable to successfully retrieve your account profile.",
			});
		},
		variables: { id: userId },
		fetchPolicy: "no-cache",
	});
	useEffect(() => {
		if (!editorOpen) {
			loadUserData({
				variables: { id: userId },
			});
		}
	}, [editorOpen]);

	useEffect(() => {
		if (!slidingProfileData?.open) {
			loadUserData({
				variables: { id: userId },
			});
		}
	}, [slidingProfileData?.open]);

	const initilizer = () => {
		const { id } = params;
		if (slidingProfileData?.open && slidingProfileData?.profileId) {
			if (userData?.userId === parseInt(slidingProfileData?.profileId)) {
				setViewContext({ component: "account", id: null });
			} else {
				setViewContext({ component: "account", id: isNaN(slidingProfileData?.profileId) ? null : parseInt(slidingProfileData?.profileId) });
			}
			return;
		}
		// Prevent loading of public profile when viewing your own via ID
		if (userData?.userId === parseInt(id)) {
			setViewContext({ component: "account", id: null });
		} else {
			setViewContext({ component: "account", id: isNaN(id) ? null : parseInt(id) });
		}
	};

	useEffect(() => {
		initilizer();
	}, []);

	useEffect(() => {
		initilizer();
	}, [params]);

	useEffect(() => {
		if (viewContext && viewContext?.component === "account") {
			if (viewContext?.id) {
				getById(viewContext?.id);
			} else {
				getMyProfile();
			}
		}
	}, [viewContext]);

	useEffect(() => {
		setProfileDataLoading(loading);
		if (loading) {
			dispatch(
				setUserProfileData({
					loading: loading,
				})
			);
		} else {
			if (!error && data) {
				if (!userId) {
					if (
						(data?.userById?.picture && data?.userById?.picture !== userData?.userPicture) ||
						(data?.userById?.name && data?.userById?.name !== userData?.userName)
					)
						dispatch(
							setAuthData({
								userData: { picture: data?.userById?.picture },
								userName: data?.userById?.name,
							})
						);
				}
				let sortedData = handleExpAndEduSort(data?.userById);
				dispatch(
					setUserProfileData({
						loading: loading,
						userData: sortedData,
					})
				);
			} else {
				console.log(error);
			}
		}
	}, [loading]);

	const getById = async (id) => {
		loadUserData({ variables: { id: userId } });
		// setProfileDataLoading(true);
		// try {
		// 	let response = await services.getCandidateDetails(id);
		// 	setProfileData(response?.data?.data);
		// } catch (error) {
		// 	setErrors(error?.response?.data ?? { error: "Unable to successfully retrieve startup profile by ID:" + id });
		// } finally {
		// 	setProfileDataLoading(false);
		// }
	};

	const getMyProfile = async () => {
		loadUserData({ variables: { id: userId } });
		// setProfileDataLoading(true);
		// try {
		// 	let response = await services.getUserProfile();
		// 	setProfileData(response?.data?.data);
		// } catch (error) {
		// 	setErrors(error?.response?.data ?? { error: "Unable to successfully retrieve your startup profile." });
		// } finally {
		// 	setProfileDataLoading(false);
		// }
	};

	const contextValue = {
		errors,
		viewContext,
		profileDataLoading,
		profileData,
		setViewContext,
		setProfileDataLoading,
		setProfileData,
		setErrors,
		getById,
		getMyProfile,
		loading,
	};

	return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};
