import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Box, Button, Switch, styled, useMediaQuery, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import theme from "../../constants/Theme";
import services from "../../services";
import ROUTES_PATH from "../../constants/routes";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import CommonSlideDialog from "../../common/CommonSlideDialog";
import { USER_TYPES } from "../../utils/userTypes";
import { PAYMENT_PLANS, PAYMENT_DESC } from "./Content";
import { setNetworkingDashboardData } from "../../modules/actions/networkingActions";

const PremiumSwitch = styled(Switch)(({ theme }) => ({
	width: 86,
	height: 24,
	padding: 0,
	// zIndex: 2,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 20,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(20px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(62px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: "#F4F3FF",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 20,
		height: 20,
		borderRadius: 20,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 20,
		opacity: 1,
		backgroundColor: "#FDF2FA",
		boxSizing: "border-box",
	},
}));

export default function PaymentPlanDialog({ open, descIndex = 0, isRedirect = true, onClose }) {
	const history = useHistory();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [isMonthlyPlan, setIsMonthlyPlan] = useState(false);
	const [isManagePlan, setIsManagePlan] = useState(false);
	const [loading, setLoading] = useState(false);

	const userType = useSelector((store) => store?.auth?.userType);
	const dispatch = useDispatch();

	useEffect(() => {
		if (open) {
			handleMixPanel("Upgrade Plan Viewed");
		}
	}, [open]);

	useEffect(() => {
		if (userType === USER_TYPES.STARTUP_OWNER || userType === USER_TYPES.STARTUP_USER) {
			getSubscriptionDetails();
		}
	}, [userType]);

	const getSubscriptionDetails = async () => {
		try {
			const details = await services.getSubscriptionDetails();
			if (details?.data?.data?.subscriptionStatus !== "active" && details?.data?.data?.isCreatedByUser === true) {
				setIsManagePlan(true);
			} else {
				setIsManagePlan(false);
			}
		} catch (err) {
			setIsManagePlan(false);
		}
	};

	const handleClose = () => {
		dispatch(
			setNetworkingDashboardData({
				openMessageDialog: false,
				messageWithMatch: {},
			})
		);

		if (isRedirect) {
			history.push(ROUTES_PATH.MY_ACCOUNT_PROFILE);
		}

		onClose();
	};

	const handlePlanChoose = async () => {
		setLoading(true);
		const checkoutData = await services.checkOut(
			isMonthlyPlan ? process.env.REACT_APP_PREMIUM_MONTHLY_PRICE_ID : process.env.REACT_APP_PREMIUM_ANNUAL_PRICE_ID
		);

		if (checkoutData?.data?.data) {
			setLoading(false);

			handleMixPanel("Go to Stripe", {
				"Plan name": isMonthlyPlan ? "Monthly" : "Annual",
			});
			handleMixPanel("Upgrade Viewed");

			window.open(checkoutData?.data?.data?.url);
		}
	};

	const handlePlanChange = () => {
		setIsMonthlyPlan(!isMonthlyPlan);
	};

	const handleSubscriptionSettings = async () => {
		try {
			const settings = await services.getSubscriptionSettings();
			window.open(settings?.data?.data?.url || process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
		} catch (err) {
			window.open(process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
		}
	};

	return (
		<CommonSlideDialog
			open={open}
			slidingEffect={false}
			onClose={handleClose}
			title={
				<Box display="flex" flexDirection="column" p="0 0 8px">
					<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7.66675 16.3333L8.71269 18.4252C9.06668 19.1332 9.24367 19.4871 9.48012 19.7939C9.68994 20.0661 9.93396 20.3101 10.2062 20.5199C10.5129 20.7564 10.8669 20.9334 11.5749 21.2873L13.6667 22.3333L11.5749 23.3792C10.8669 23.7332 10.5129 23.9102 10.2062 24.1467C9.93396 24.3565 9.68994 24.6005 9.48012 24.8727C9.24367 25.1794 9.06668 25.5334 8.71269 26.2414L7.66675 28.3333L6.6208 26.2414C6.26682 25.5334 6.08983 25.1794 5.85337 24.8727C5.64356 24.6005 5.39954 24.3565 5.12734 24.1467C4.82059 23.9102 4.46661 23.7332 3.75864 23.3792L1.66675 22.3333L3.75864 21.2873C4.46661 20.9334 4.82059 20.7564 5.12734 20.5199C5.39954 20.3101 5.64356 20.0661 5.85337 19.7939C6.08983 19.4871 6.26682 19.1332 6.6208 18.4252L7.66675 16.3333Z"
							stroke="#7B61FF"
							stroke-width="2.66667"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M19.0001 1.66663L20.5716 5.75252C20.9476 6.73016 21.1356 7.21898 21.428 7.63015C21.6871 7.99456 22.0055 8.31295 22.3699 8.57207C22.7811 8.86444 23.2699 9.05245 24.2475 9.42846L28.3334 11L24.2475 12.5715C23.2699 12.9475 22.7811 13.1355 22.3699 13.4278C22.0055 13.687 21.6871 14.0054 21.428 14.3698C21.1356 14.7809 20.9476 15.2698 20.5716 16.2474L19.0001 20.3333L17.4286 16.2474C17.0526 15.2698 16.8646 14.7809 16.5722 14.3698C16.3131 14.0054 15.9947 13.687 15.6303 13.4278C15.2191 13.1355 14.7303 12.9475 13.7526 12.5715L9.66675 11L13.7526 9.42846C14.7303 9.05245 15.2191 8.86444 15.6303 8.57207C15.9947 8.31295 16.3131 7.99456 16.5722 7.63015C16.8646 7.21898 17.0526 6.73016 17.4286 5.75252L19.0001 1.66663Z"
							stroke="#7B61FF"
							stroke-width="2.66667"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>

					<Typography variant="Text/md/Semibold" sx={{ mt: 1.5 }}>
						{/* {isManagePlan ? `Manage StartupOS Subscription` : `Upgrade to our Premium Subscription Plan`} */}
						{isManagePlan ? `Manage StartupOS Subscription` : `Manage StartupOS Subscription`}
					</Typography>
				</Box>
			}
			titleVariant="title_medium"
			maxWidth="sm"
			btnCloseStyle={{ margin: "0 24px 0 0" }}
			dialogAction={
				<Box display={"flex"} alignItems={"center"} gap={2}>
					{" "}
					<Button onClick={onClose} sx={{ color: "rgba(102, 112, 133, 1)" }} variant="noOutlineNew">
						No thanks
					</Button>
					<Button onClick={handlePlanChoose} startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />} variant="DS1">
						Upgrade
					</Button>
				</Box>
			}
			PaperProps={{
				sx: (theme) => ({
					position: "fixed",
					top: 0,
					borderRadius: "30px",
					m: 0,
					p: 2,
					[theme.breakpoints.down("md")]: {
						px: 1,
						py: 1.5,
					},
					[theme.breakpoints.down("sm")]: {
						p: 0,
						m: -1,
					},
				}),
			}}
		>
			<Box>
				<Box pb={3}>
					{isManagePlan ? (
						<Typography variant="Text/sm/Regular">
							There is an issue with your premium subscription. Please review your plan and payment details.
						</Typography>
					) : (
						<>
							<Typography variant="Text/sm/Regular" sx={{ pb: 2, display: "block" }}>
								{PAYMENT_DESC[descIndex].desc}
							</Typography>

							<Typography variant="Text/sm/Regular">
								Enjoy advanced features tailored for startup founders: pitch events, premium resources, expert advisors, mentors, and investor
								connections. Elevate your entrepreneurial journey by becoming a StartupOS Premium Subscriber!
							</Typography>
						</>
					)}
				</Box>
				{!isManagePlan && (
					<Box
						display="flex"
						gap={4}
						sx={(theme) => ({
							flexDirection: "row",
							[theme.breakpoints.down("md")]: {
								flexDirection: "column",
							},
						})}
					>
						{PAYMENT_PLANS.map((payment) => (
							<Plan
								key={payment.id}
								isMonthlyPlan={isMonthlyPlan}
								payment={payment}
								handlePlanChoose={handlePlanChoose}
								handlePlanChange={handlePlanChange}
								loading={loading}
							/>
						))}
					</Box>
				)}
				{isManagePlan && (
					<Box display="flex" justifyContent="flex-end">
						<Box mr={10}>
							<Button
								onClick={handleSubscriptionSettings}
								sx={{
									height: "48px",
									width: "300px",
									fontWeight: 600,
									fontSize: "16px",
									lineHeight: "24px",
									boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
									borderRadius: "100px",
									background: "#7B61FF",
									"&:hover": {
										background: "#7B61FF",
									},
									"&:active": {
										background: "#7B61FF",
									},
								}}
							>
								Update Subscription Settings
							</Button>
						</Box>
					</Box>
				)}
			</Box>
		</CommonSlideDialog>
	);
}

const Plan = ({ payment, isMonthlyPlan, handlePlanChoose, handlePlanChange, loading }) => {
	const { plan, title, amount, amountTitle, items } = payment;

	const currentPlanColor = useMemo(() => {
		if (plan === "Free Plan") {
			return "#12B76A";
		}

		if (isMonthlyPlan) {
			return "#DE0085";
		}

		return (theme) => theme.palette.primary.main;
	}, [plan, isMonthlyPlan]);

	return (
		<Box
			position="relative"
			display="flex"
			flexDirection="column"
			// alignItems="center"
			// flex={1}
			py={5}
			sx={{
				background: (theme) => theme.palette.primary.primary25,
				boxShadow: "0px 10px 85px rgba(52, 64, 84, 0.09)",
				borderRadius: "16px",
			}}
		>
			{/* {plan !== "Free Plan" && !isMonthlyPlan && (
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
					top={0}
					sx={{
						background: "#7B61FF",
						width: "100%",
						height: "40px",
						borderTopLeftRadius: "16px",
						borderTopRightRadius: "16px",
					}}
				>
					<Typography variant="onsurface_subtext" color={"#fff"}>
						Most Popular | 40% Savings
					</Typography>
				</Box>
			)} */}
			<Typography
				variant="title_portfolio"
				color={(theme) => theme.palette.primary.light}
				sx={(theme) => ({
					[theme.breakpoints.down("md")]: {
						mt: 0,
					},
					px: 4,
				})}
			>
				{plan}
			</Typography>
			<Box display="flex" alignItems="center" my={0.5} px={4}>
				<Typography variant="Text/2xl/Semibold" color={currentPlanColor}>
					${isMonthlyPlan && plan !== "Free Plan" ? "49" : amount}
				</Typography>
				{plan !== "Free Plan" && (
					<Box position="relative">
						{/* <PremiumSwitch checked={!isMonthlyPlan} /> */}
						<Box position="absolute" top="0px" sx={{ width: "100px" }} left={isMonthlyPlan ? "30px" : "2px"}>
							<Typography variant="Text/xs/Regular" color={(theme) => theme.palette.secondary.light}>
								{isMonthlyPlan ? "Monthly" : "/per year"}
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
			{/* <Typography variant="subhead3" color="#475467" mb={3}>
				{isMonthlyPlan && plan !== "Free Plan" ? "Per month" : amountTitle}
			</Typography> */}
			{/* <Typography variant="subhead1" color="#475467" sx={{ textAlign: "center", maxWidth: "450px" }}>
				{title}
			</Typography> */}
			{/* <Box my={3}>
				{plan === "Free Plan" ? (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						sx={{
							background: "#ECFDF3",
							boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
							borderRadius: "100px",
							height: "48px",
							width: "266px",
						}}
					>
						<Typography variant="poppinsSemiBold16" color="#12B76A">
							You’re already in!
						</Typography>
					</Box>
				) : (
					<Button
						onClick={handlePlanChoose}
						startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
						sx={{
							height: "48px",
							width: "266px",
							fontWeight: 600,
							fontSize: "16px",
							lineHeight: "24px",
							boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
							borderRadius: "100px",
							background: currentPlanColor,
							"&:hover": {
								background: currentPlanColor,
							},
							"&:active": {
								background: currentPlanColor,
							},
						}}
					>
						Upgrade to Premium1
					</Button>
				)}
			</Box> */}
			{/* <Box display="flex" width="100%" mb={3}>
				<Typography variant="subhead1" color="#475467">
					Everything in our &nbsp;
				</Typography>
				<Typography variant="subhead1" color="#475467" sx={{ fontWeight: 600 }}>
					Free Plan
				</Typography>
				{plan !== "Free Plan" && (
					<Typography variant="subhead1" color="#475467">
						&nbsp; plus...
					</Typography>
				)}
			</Box> */}
			<Box px={1}>
				<ul>
					{items.map((item) => (
						<Box key={item.id} display="flex" alignItems="flex-start">
							{/* <CheckIcon color={currentPlanColor} />
						<Box ml={1.5}>
							<Typography variant="subhead1" color="#475467" sx={{ display: "block" }}>
								{item.title}
							</Typography>
							{item.desc && (
								<Typography variant="subhead1" color="#475467">
									{item.desc}
								</Typography>
							)}
						</Box> */}
							<li>
								{" "}
								<Typography variant="Text/sm/Regular">{item.title}</Typography>
							</li>
						</Box>
					))}
				</ul>
			</Box>
		</Box>
	);
};

const CheckIcon = ({ color = "#12B76A" }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.8122 8.52278L10.7737 20.5603C10.6344 20.6996 10.4691 20.8101 10.2871 20.8855C10.105 20.9609 9.90996 20.9997 9.71295 20.9997C9.51594 20.9997 9.32085 20.9609 9.13884 20.8855C8.95683 20.8101 8.79146 20.6996 8.65217 20.5603L1.93779 13.8103C1.65699 13.529 1.49927 13.1479 1.49927 12.7504C1.49927 12.353 1.65699 11.9718 1.93779 11.6906L4.18779 9.44059C4.46813 9.16018 4.84809 9.00217 5.2446 9.00112C5.64111 9.00006 6.0219 9.15605 6.30373 9.43496L9.73873 12.7415L9.74904 12.7518L18.4425 4.1859C18.7236 3.90595 19.1042 3.74878 19.5009 3.74878C19.8977 3.74878 20.2782 3.90595 20.5594 4.1859L22.8094 6.39465C22.9498 6.53388 23.0614 6.69951 23.1376 6.88203C23.2139 7.06455 23.2533 7.26033 23.2535 7.45813C23.2538 7.65592 23.2149 7.85181 23.1392 8.03453C23.0634 8.21725 22.9523 8.38318 22.8122 8.52278Z"
				fill={color}
			/>
		</svg>
	);
};
