import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import ROUTES_PATH from "../../constants/routes";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../utils/userTypes";
import services from "../../services";
import PaymentPlanDialog from "../../components/PaymentPlanDialog";

function UpgradePlan() {
	const history = useHistory();
	const userType = useSelector((store) => store?.auth?.userType);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);

	const handleShowPlan = async () => {
		// history.push(ROUTES_PATH.PAYWALL);
		try {
			if (isPremiumPlanAccess || isAdmin) {
				const settings = await services.getSubscriptionSettings();
				window.open(settings?.data?.data?.url || process.env.REACT_APP_SUBSCRIPTION_SETTINGS_LINK);
			} else {
				setShowPlanModal(true);
			}
		} catch (err) {
			setShowPlanModal(true);
		}
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				padding: "40px",
				borderRadius: "30px",
				maxWidth: "600p",
			}}
		>
			<Box display="flex" flexDirection="column" gap={1.5} mb={3}>
				<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7.66675 16.3333L8.71269 18.4252C9.06668 19.1332 9.24367 19.4871 9.48012 19.7939C9.68994 20.0661 9.93396 20.3101 10.2062 20.5199C10.5129 20.7564 10.8669 20.9334 11.5749 21.2873L13.6667 22.3333L11.5749 23.3792C10.8669 23.7332 10.5129 23.9102 10.2062 24.1467C9.93396 24.3565 9.68994 24.6005 9.48012 24.8727C9.24367 25.1794 9.06668 25.5334 8.71269 26.2414L7.66675 28.3333L6.6208 26.2414C6.26682 25.5334 6.08983 25.1794 5.85337 24.8727C5.64356 24.6005 5.39954 24.3565 5.12734 24.1467C4.82059 23.9102 4.46661 23.7332 3.75864 23.3792L1.66675 22.3333L3.75864 21.2873C4.46661 20.9334 4.82059 20.7564 5.12734 20.5199C5.39954 20.3101 5.64356 20.0661 5.85337 19.7939C6.08983 19.4871 6.26682 19.1332 6.6208 18.4252L7.66675 16.3333Z"
						stroke="#7B61FF"
						stroke-width="2.66667"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M19.0001 1.66663L20.5716 5.75252C20.9476 6.73016 21.1356 7.21898 21.428 7.63015C21.6871 7.99456 22.0055 8.31295 22.3699 8.57207C22.7811 8.86444 23.2699 9.05245 24.2475 9.42846L28.3334 11L24.2475 12.5715C23.2699 12.9475 22.7811 13.1355 22.3699 13.4278C22.0055 13.687 21.6871 14.0054 21.428 14.3698C21.1356 14.7809 20.9476 15.2698 20.5716 16.2474L19.0001 20.3333L17.4286 16.2474C17.0526 15.2698 16.8646 14.7809 16.5722 14.3698C16.3131 14.0054 15.9947 13.687 15.6303 13.4278C15.2191 13.1355 14.7303 12.9475 13.7526 12.5715L9.66675 11L13.7526 9.42846C14.7303 9.05245 15.2191 8.86444 15.6303 8.57207C15.9947 8.31295 16.3131 7.99456 16.5722 7.63015C16.8646 7.21898 17.0526 6.73016 17.4286 5.75252L19.0001 1.66663Z"
						stroke="#7B61FF"
						stroke-width="2.66667"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<Typography variant="growth_card_subtitle">
					{/* {userType === USER_TYPES.MENTOR_USER
						? "Ignite the Entrepreneurial Spirit and Spread Some Wisdom!"
						: "Upgrade to our Premium Subscription Plan"} */}
					{userType === USER_TYPES.MENTOR_USER
						? "Ignite the Entrepreneurial Spirit and Spread Some Wisdom!"
						: "Ignite the Entrepreneurial Spirit and Spread Some Wisdom!"}
				</Typography>
			</Box>

			<Box pb={2.5}>
				<Typography variant="Text/sm/Regular" sx={{ pb: 2, display: "block" }}>
					{userType === USER_TYPES.MENTOR_USER
						? "We invite you to join forces and make an inspirational or helpful post on the StartupOS social feed. Your words have the power to shape the dreams of aspiring entrepreneurs and propel them towards success."
						: "Unlock a world of advanced features tailored specifically to startup founders like you."}
				</Typography>
				<Typography variant="Text/sm/Regular" sx={{ pb: userType === USER_TYPES.MENTOR_USER && 2, display: "block" }}>
					{userType === USER_TYPES.MENTOR_USER
						? "Whether it's sharing a personal triumph, offering a practical tip, or revealing the secrets of your own journey, your contribution can spark a fire within someone who needs it the most."
						: ""}
				</Typography>

				<Typography variant="Text/sm/Regular" sx={{ pb: 2, display: "block" }}>
					{/* {userType === USER_TYPES.MENTOR_USER
						? "Together, let's build a community that uplifts, supports, and celebrates the entrepreneurial spirit. Inspire, motivate, and empower your fellow startup enthusiasts."
						: "By upgrading to our Premium Subscription, you unlock a world of advanced features tailored specifically to startup founders like you,including access to pitch events, premium resources, advisors, mentors, and investors."} */}
					{userType === USER_TYPES.MENTOR_USER
						? "Together, let's build a community that uplifts, supports, and celebrates the entrepreneurial spirit. Inspire, motivate, and empower your fellow startup enthusiasts."
						: "Together, let's build a community that uplifts, supports, and celebrates the entrepreneurial spirit. Inspire, motivate, and empower your fellow startup enthusiasts."}
				</Typography>
				<Typography variant="Text/sm/Regular" sx={{ pb: 2, display: "block" }}>
					{/* {userType === USER_TYPES.MENTOR_USER
						? "It’s time to unleash the power of your words! Take a moment to craft a post that can change lives, shape destinies, and drive our community to new heights."
						: "We've designed this premium plan with founders like you in mind, aiming to provide the most value and boost your startup journey."} */}
					{userType === USER_TYPES.MENTOR_USER
						? "It’s time to unleash the power of your words! Take a moment to craft a post that can change lives, shape destinies, and drive our community to new heights."
						: "It’s time to unleash the power of your words! Take a moment to craft a post that can change lives, shape destinies, and drive our community to new heights."}
				</Typography>
				<Typography variant="Text/sm/Regular">
					{/* {userType === USER_TYPES.MENTOR_USER
						? "Lets make magic happen, one post at a time!"
						: "Don't miss out on these fantastic features! Upgrade to Premium today."} */}
					{userType === USER_TYPES.MENTOR_USER
						? "Lets make magic happen, one post at a time!"
						: "Lets make magic happen, one post at a time!"}
				</Typography>
			</Box>

			{/* {userType !== USER_TYPES.MENTOR_USER && (
				<Box width="100%" display="flex" justifyContent="flex-end" mt={3}>
					<Box>
						<Button
							onClick={handleShowPlan}
							sx={{
								height: "48px",
								width: "266px",
								fontWeight: 600,
								fontSize: "16px",
								lineHeight: "24px",
								boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
								borderRadius: "100px",
								background: "#7A5AF8",
							}}
						>
							Upgrade to Premium
						</Button>
					</Box>
				</Box>
			)} */}
			{/* {showPlanModal && <PaymentPlanDialog open={showPlanModal} onClose={handleClosePlanModal} isRedirect={false} />} */}
		</Box>
	);
}

export default UpgradePlan;
