let apiVersion = process.env.REACT_APP_API_URL;
const VOUCH_VERSION = process.env.REACT_APP_VOUCH_ENV;
//let apiVersion = "https://49ad-103-171-133-112.ngrok.io";

let openStreetMapVersion = "https://nominatim.openstreetmap.org";

let calApiVersion = "http://54.211.1.73:3002";

export const loginUrl = `${apiVersion}/api/m1/v1/users/login`;

export const updateEmailUrl = `${apiVersion}/api/m1/v1/users/email/verification-link`;

export const resendEmailUrl = `${apiVersion}/api/m1/v1/users/email/resend`;

export const searchLocationByCoordinatesUrl = (lat, lng) => `${openStreetMapVersion}/reverse?lat=${lat}&lon=${lng}&format=json`;

export const searchLocationByZipcodeUrl = (zipcode) => `${openStreetMapVersion}/search?format=json&postalcode=${zipcode}&country=USA&addressdetails=1`;

export const searchLocationByPostalcodeUrl = (zipcode) => `${apiVersion}/api/m3/v1/geo-codes?code=${zipcode}`;

export const autoCompleteDataUrl = (questionKey) => `${apiVersion}/api/m1/v1/companies/auto-complete?key=${questionKey}`;

export const updateUserFlagUrl = () => `${apiVersion}/api/m1/v1/users/tutorials/flags`;

export const updateRunwayDetailsUrl = () => `${apiVersion}/api/m2/v1/companies/runaway`;

export const getDashboardStatsUrl = () => `${apiVersion}/api/m2/v1/companies/stats`;

export const getTodaysTopUrl = () => `${apiVersion}/api/m2/v1/companies/dashboard`;

export const companyProfile = () => `${apiVersion}/api/m1/v1/companies/profile`;

export const createPublicCompanyProfile = () => `${apiVersion}/api/m1/v1/companies/public`;

export const updateCompanyPublicProfileUrl = (requestId, id) => `${apiVersion}/api/m1/v1/companies/public?requestId=${requestId}&id=${id}`;

export const powerUpsApiUrl = () => `${apiVersion}/api/m2/v1/powerups`;

export const todosApiUrl = () => `${apiVersion}/api/m2/v1/cards`;

export const powerThingsApiUrl = () => `${apiVersion}/api/m2/v1/tasks/powerups-plugin`;

export const powerUpPacksApiUrl = () => `${apiVersion}/api/m2/v1/powerups/packs`;

export const powerUpPacksDetailsApiUrl = (id) => `${apiVersion}/api/m2/v1/powerups/packs/${id}`;

export const getCompanyPublicProfileUrl = (companyId) => `${apiVersion}/api/m1/v1/companies/${companyId}/public`;

export const excerciseStepsUrl = () => `${apiVersion}/api/m2/v1/excercises/steps`;

export const modulesStepUrl = (moduleId) => `${apiVersion}/api/m2/v1/modules/steps`;

export const modulesStepResponsesUrl = (moduleId) => `${apiVersion}/api/m2/v1/modules/${moduleId}/responses`;

export const getExcerciseDetailsUrl = () => `${apiVersion}/api/m2/v1/excercises/details`;

export const powerupDataUrl = (id) => `${apiVersion}/api/m2/v1/powerups/details?id=${id}`;

export const lastVisitedPowerupDataUrl = `${apiVersion}/api/m2/v1/powerups/last-visited`;
export const startPowerupDataUrl = `${apiVersion}/api/m2/v1/excercises/start`;
export const startModuleDataUrl = `${apiVersion}/api/m2/v1/modules/start`;
export const uploadFile = `${apiVersion}/api/m1/v1/files/upload`;

export const verifyEmail = (token) => `${apiVersion}/api/m1/v1/users/email/verify?token=${token}`;

export const powerUpPacksDeatils = (id) => `${apiVersion}/api/m2/v1/powerups/packs/details?id=${id}`;

export const moduleDetails = (id) => `${apiVersion}/api/m2/v1/modules/${id}/responses`;

export const interviewCandidates = (id) => `${apiVersion}/api/m1/v1/users/candidates`;

export const scheduleCanidateEvent = (id) => `${apiVersion}/api/m2/v1/invitations`;

export const getAdvisorPluginDetails = (uuid) => `${apiVersion}/api/m2/v1/plugins/${uuid}`;

export const getAdvisorPlugineEventDetails = (uuid) => `${apiVersion}/api/m2/v1/plugins/${uuid}/responses`;

export const getMarketTestDetails = (id) => `${apiVersion}/api/m2/v1/market-tests/${id}`;

export const getCandidateDetails = (id) => `${apiVersion}/api/m1/v1/users/${id}`;

export const getCompletedPowerUpsApiUrl = () => `${apiVersion}/api/m2/v1/tasks`;

export const marketTestApiUrl = () => `${apiVersion}/api/m2/v1/tests`;

export const getPluginDetails = (id) => `${apiVersion}/api/m2/v1/plugins/${id}`;

export const pluginResponses = (id) => `${apiVersion}/api/m2/v1/plugins/${id}/responses`;

export const deleteMarketTestApiUrl = (marketTestUuid, responseId) => `${apiVersion}/api/m2/v1/market-tests/${marketTestUuid}/responses/${responseId}`;

export const sendInterviewInvite = () => `${apiVersion}/api/m1/v1/invitations/candidates/interviews`;

export const createMarketTestApiUrl = (marketTestUuid) => `${apiVersion}/api/m2/v1/market-tests/${marketTestUuid}/responses`;

export const marketTestListApiUrl = () => `${apiVersion}/api/m2/v1/market-tests/responses`;

export const marketTestResponses = (marketTestUuid, responseId) => `${apiVersion}/api/m2/v1/market-tests/${marketTestUuid}/responses/${responseId}`;

export const moduleResponseDetailsByResponseId = (moduleId, responseId) => `${apiVersion}/api/m2/v1/modules/${moduleId}/responses/${responseId}`;

export const marketTestComplete = (marketTestUuid) => `${apiVersion}/api/m2/v1/market-tests/${marketTestUuid}/complete`;

export const checkEmail = (email) => `${apiVersion}/api/m1/v1/users/email/check?email=${email}`;
export const getMentorMatchesApiUrl = () => `${apiVersion}/api/m1/v1/connections/matches`;
export const getAvailableMentorsApiUrl = () => `${apiVersion}/api/m1/v1/connections/users`;
export const userProfile = () => `${apiVersion}/api/m1/v1/users/profile`;
export const startupDetails = (companyId) => `${apiVersion}/api/m1/v1/companies/${companyId}`;
export const startupCardDetails = (companyId) => `${apiVersion}/api/m1/v1/companies/cards/${companyId}`;

export const startupCollection = () => `${apiVersion}/api/m1/v1/collections`;
export const addMentorExperienceApiUrl = () => `${apiVersion}/api/m1/v1/users/experiences`;

export const updateMentorExperienceApiUrl = (experienceId) => `${apiVersion}/api/m1/v1/users/experiences/${experienceId}`;

export const updateAllMentorExperienceApiUrl = () => `${apiVersion}/api/m1/v1/users/experiences`;

export const addMentorEducationApiUrl = () => `${apiVersion}/api/m1/v1/users/educations`;

export const updateMentorEducationApiUrl = (educationId) => `${apiVersion}/api/m1/v1/users/educations/${educationId}`;

export const updateAllMentorEducationApiUrl = () => `${apiVersion}/api/m1/v1/users/educations`;

//advisor or mentor onboarding api
export const userOnboarding = () => `${apiVersion}/api/m1/v1/users/onboarding`;

export const userOnboard = () => `${apiVersion}/api/m1/v2/users/onboarding`;

export const userNewOnboard = () => `${apiVersion}/api/m1/v2/users/onboarding-details`;

export const userSocialOnboard = (paramsData) =>
	`${apiVersion}/api/m1/v2/users/social-sso?utm_source=${paramsData?.utm_source || ""}&utm_medium=${paramsData?.utm_medium || ""}&utm_campaign=${
		paramsData?.utm_campaign || ""
	}&partner=${paramsData?.partner || ""}`;

export const startupConnection = () => `${apiVersion}/api/m1/v1/connections`;

export const getSavedStartUpListApiUrl = () => `${apiVersion}/api/m1/v1/collections`;

export const savedStartupApiUrl = () => `${apiVersion}/api/m1/v1/collections`;

export const calendarInviteEventDetails = (eventId) => `${apiVersion}/api/m1/v1/invitations/events/${eventId}`;

export const platformInviteUrl = () => `${apiVersion}/api/m1/v1/invitations/platform`;

export const deleteEvent = (eventId) => `${apiVersion}/api/m1/v1/invitations/events/${eventId}`;

export const candidateDetails = () => `${apiVersion}/api/m1/v1/users/profile`;

export const candidateInvitationDetails = (id) => `${apiVersion}/api/m1/v1/invitations/candidates?id=${id}`;

export const getCalendarInvitesUrl = () => `${apiVersion}/api/m1/v1/invitations/events`;

export const addReviewUrl = () => `${apiVersion}/api/m2/v1/reviews`;

export const getReviewList = (perpage, page, sort, filter, entityID, entityType, details) =>
	`${apiVersion}/api/m2/v1/reviews?entityId=${entityID}&entityType=${entityType}&details=${details}&perPage=${perpage}&page=${page}&sort=${sort}&filter=${filter}`;

export const getMyWorkPowerups = () => `${apiVersion}/api/m2/v1/modules/responses/my-works`;

export const getMyWorkDetails = () => `${apiVersion}/api/m2/v1/modules/responses/versions`;

export const deleteArchiveModuleResponse = (responseId) => `${apiVersion}/api/m2/v1/modules/responses/${responseId}/archives`;

export const getArchivedList = (page, sort, filter) => `${apiVersion}/api/m2/v1/modules/responses/archives?page=${page}&sort=${sort}&filter=${filter}`;

export const archiveUrl = () => `${apiVersion}/api/m2/v1/modules/responses/archives`;

export const getTeamMembersDetails = () => `${apiVersion}/api/m1/v1/companies/users`;

export const sendTeamMemberInviteUrl = () => `${apiVersion}/api/m1/v1/invitations/teams`;

export const resendTeamMemberInviteUrl = () => `${apiVersion}/api/m1/v1/invitations/resend`;

export const deleteInvitedTeamMemberUrl = (invitationId) => `${apiVersion}/api/m1/v1/invitations/${invitationId}?inviteRole=TEAM_MEMBER`;

export const linkSocialAccount = () => `${apiVersion}/api/m1/v1/users/link`;

export const unLinkSocialAccount = () => `${apiVersion}/api/m1/v1/users/un-link`;

export const signUp = () => `${apiVersion}/api/m1/v1/users/signup`;

export const register = () => `${apiVersion}/api/m1/v2/users/signup`;

export const updateUserProfileDetailsUrl = () => `${apiVersion}/api/m1/v1/users/details`;
export const changeEmailRequest = () => `${apiVersion}/api/m1/v1/requests/email`;

export const changeEmailRequestDetails = () => `${apiVersion}/api/m1/v1/requests/email`;

export const callbackChangeEmailRequest = () => `${apiVersion}/api/m1/v1/requests/email/callback`;

export const resendChangeEmailRequest = () => `${apiVersion}/api/m1/v1/requests/email/resend`;

export const verifyPassword = () => `${apiVersion}/api/m1/v1/users/authentication/verify`;

export const changePassword = () => `${apiVersion}/api/m1/v1/requests/password`;

export const notesList = () => `${apiVersion}/api/m3/v1/notes`;
export const pinnedNotesList = () => `${apiVersion}/api/m3/v1/notes/pinned`;
export const getResearchCuratorDetailsUrl = (researchCuratorId) => `${apiVersion}/api/m3/v1/notes/${researchCuratorId}`;

export const addResearchCuratorUrl = () => `${apiVersion}/api/m3/v1/notes`;

export const addLinkToResearchCuratorUrl = (researchCuratorId) => `${apiVersion}/api/m3/v1/notes/${researchCuratorId}/links`;

export const removeLinkFromResearchCuratorUrl = (researchCuratorId, linkId) => `${apiVersion}/api/m3/v1/notes/${researchCuratorId}/links/${linkId}`;

export const updateResearchCuratorUrl = (researchCuratorId) => `${apiVersion}/api/m3/v1/notes/${researchCuratorId}`;

export const updateReferencesUrl = (researchCuratorId) => `${apiVersion}/api/m3/v1/notes/${researchCuratorId}/references`;

export const getResearchCuratorListUrl = () => `${apiVersion}/api/m3/v1/notes`;

export const getNotesListPanel = () => `${apiVersion}/api/m3/v1/notes/panel`;

export const addNotesAttachment = (noteId) => `${apiVersion}/api/m3/v1/notes/${noteId}/attachments`;

export const deleteNotesAttachemnt = (noteId, attachmentId) => `${apiVersion}/api/m3/v1/notes/${noteId}/attachments/${attachmentId}`;

export const deleteNote = (noteId) => `${apiVersion}/api/m3/v1/notes/${noteId}`;

export const getResearchCuratorReferenceListUrl = () => `${apiVersion}/api/m2/v1/tasks/references`;

export const noteComments = (noteId) => `${apiVersion}/api/m3/v1/notes/${noteId}/comments`;

export const getTagsList = () => `${apiVersion}/api/m3/v1/tags`;

export const addTagToList = (noteId) => `${apiVersion}/api/m3/v1/notes/${noteId}/tags`;

export const notesFilters = () => `${apiVersion}/api/m3/v1/notes/filters`;

export const updateCompanyUserHideStatusUrl = () => `${apiVersion}/api/m1/v1/companies/users/`;

export const getMetadataDetails = () => `${apiVersion}/api/m1/v1/metadata`;

export const getPendingConnections = ({ perPage, page }) => `${apiVersion}/api/m1/v1/connections?perPage=${perPage}&page=${page}&status=PENDING`;

export const getStartupConnections = ({ perPage, page }) => `${apiVersion}/api/m1/v1/connections?perPage=${perPage}&page=${page}&status=ACCEPTED`;

export const calEndPoint = (url) => `${apiVersion}/api/m1/v1/cal/${url}`;

//export const calEndPoint = url => `${apiVersion}/api/m1/v1/cal/${url}`;
export const setUserNotifications = () => `${apiVersion}/api/m1/v1/notifications`;

export const getUserByCalUserId = () => `${apiVersion}/api/m1/v1/users/cal-user-details`;

export const getControlRoleAccessApiUrl = (email) => `${apiVersion}/api/m1/v1/users/email?email=${encodeURIComponent(email)}`;

export const getConversationByUserIDEndpoint = () => `${apiVersion}/api/m1/v1/conversations`;

export const getConversationByConvoIDEndpoint = (conversationId) => `${apiVersion}/api/m1/v1/conversations/${conversationId}`;

export const getContactsWithSearchURL = (searchQuery) => `${apiVersion}/api/m1/v1/conversations/contacts?searchText=${searchQuery}`;

export const getConversationWithSearchURL = (searchQuery) => `${apiVersion}/api/m1/v1/conversations?searchText=${searchQuery}`;

export const updateConversationURL = () => `${apiVersion}/api/m1/v1/conversations`;

export const deleteModuleController = (responseId) => `${apiVersion}/api/m2/v1/modules/responses/${responseId}`;

export const fileController = () => `${apiVersion}/api/m2/v1/files/launch-plan`;

export const getHarmonicData = ({ userId, linkedinUrl }) => `${apiVersion}/api/m1/v1/harmonic?url=${linkedinUrl}`;

export const getSurveyDetailsUrl = (surveyId) => `${apiVersion}/api/m1/v1/surveys/${surveyId}`;

export const getSurveyResponseUrl = (smCollectorId) => `${apiVersion}/api/m1/v1/surveys/responses/${smCollectorId}`;

export const sendSurveyUrl = (surveyConfiguration) => `${apiVersion}/api/m1/v1/surveys/${surveyConfiguration.surveyId}/send`;

export const getAdvertsUrl = () => `${apiVersion}/api/m3/v1/adverts`;

export const getAdvertsByIdUrl = (id) => `${apiVersion}/api/m3/v1/adverts/${id}`;

export const getUserUrl = () => `${apiVersion}/api/m1/v1/users`;

export const getVouchTokenUrl = (email) => `${apiVersion}/api/m2/v1/vouch/auth?email=${email}`;

export const addNewVouchData = (email) => `${apiVersion}/api/m2/v1/vouch/partner/vouch`;

export const getAllVouchData = (email) => `${apiVersion}/api/m2/v1/vouch/partner/vouch`;

export const getVouchDataById = (applicationId) => `${apiVersion}/api/m2/v1/vouch/partner/vouch/${applicationId}`;

export const deleteVouchDataById = (applicationId) => `${apiVersion}/api/m2/v1/vouch/partner/vouch/${applicationId}`;

export const getNotificationsUrl = ({ lastIndex, perPage }) => `${process.env.REACT_APP_AWS_NOTIFICATIONS_REST}`;

export const deleteNotificationsUrl = () => `${process.env.REACT_APP_AWS_NOTIFICATIONS_REST}`;

export const updateNotificationsUrl = () => `${process.env.REACT_APP_AWS_NOTIFICATIONS_REST}`;

export const companyList = () => `${apiVersion}/api/m1/v1/companies`;
export const companyExportList = () => `${apiVersion}/api/m1/v1/companies/export-file`;

export const companyListFilterUrl = () => `${apiVersion}/api/m1/v1/companies/search/filter`;

export const addCompanyToWatchlistOrPorfolioUrl = () => `${apiVersion}/api/m1/v1/collections`;

export const bulkWatchlistOrPorfolioCollectionActionUrl = () => `${apiVersion}/api/m1/v1/collections/bulk`;

export const getVouchApplication = () => `https://${VOUCH_VERSION}.vouch.us/v1/applications/new`;

export const createVouchApplication = () => `https://${VOUCH_VERSION}.vouch.us/v1/applications`;

export const getVouchMoreQuestions = (vouchId) => `https://${VOUCH_VERSION}.vouch.us/v1/applications/${vouchId}/next-question-set`;

export const getVouchApplications = () => `https://${VOUCH_VERSION}.vouch.us/v1/applications`;

export const getVouchApplicationById = (appId) => `https://${VOUCH_VERSION}.vouch.us/v1/applications/${appId}`;

export const setVouchNewAnswers = (appId) => `https://${VOUCH_VERSION}.vouch.us/v1/applications/${appId}/answers`;

export const reviewVouchAnswers = (appId) => `https://${VOUCH_VERSION}.vouch.us/v1/applications/${appId}/review`;

export const attestVouchApplication = (appId) => `https://${VOUCH_VERSION}.vouch.us/v1/applications/${appId}/attest`;

export const getVouchQuotes = (appId) => `https://${VOUCH_VERSION}.vouch.us/v1/applications/${appId}/quotes`;

export const getVouchDecision = (appId) => `https://${VOUCH_VERSION}.vouch.us/v1/applications/${appId}/decision`;

export const getVouchQuestionSetsById = (questionId) => `https://${VOUCH_VERSION}.vouch.us/v1/question-sets/${questionId}`;

export const getVouchQuestionSets = () => `https://${VOUCH_VERSION}.vouch.us/v1/question-sets`;
export const companyUser = (companyId) => `${apiVersion}/api/m1/v1/users/companies/${companyId}`;
export const getIndustryDataUrl = () => `${apiVersion}/api/m1/v1/industries`;
export const companyScore = (companyId) => `${apiVersion}/api/m1/v1/companies/${companyId}/scores`;

export const startupScoreReportUrl = (startupId, months) => `${apiVersion}/api/m1/v1/scores/reports?companyId=${startupId}&months=${months}`;

export const createSavedFilterUrl = () => `${apiVersion}/api/m1/v1/companies/filters/saved`;

export const verifyUser = () => `${apiVersion}/api/m1/v1/users/verify`;

export const deleteSavedFilterUrl = (id) => `${apiVersion}/api/m1/v1/companies/filters/saved/${id}`;

export const getListOfCountryStateUrl = () => `${apiVersion}/api/m3/v1/countrystate`;

export const getTSSPowerUPDataUrl = (companyID) => `${apiVersion}/api/m2/v1/modules/responses?companyId=${companyID}&powerUpId=7`;

export const getPersonaBuilderPowerUPDataUrl = (companyID) => `${apiVersion}/api/m2/v1/modules/responses?companyId=${companyID}&powerUpId=3`;
export const getListOfCategoryApi = () => `${apiVersion}/api/m1/v1/lovs`;
export const getProbSolPowerUPDataUrl = (companyID) => `${apiVersion}/api/m2/v1/modules/responses?companyId=${companyID}&powerUpId=5`;
export const getValuePropPowerUPDataUrl = (companyID) => `${apiVersion}/api/m2/v1/modules/responses?companyId=${companyID}&powerUpId=1`;
export const getBrandstormingPowerUPData = (companyID) => `${apiVersion}/api/m2/v1/modules/responses?companyId=${companyID}&powerUpId=6`;

export const getInvestorImpressionsDataUrl = (companyID) => `${apiVersion}/api/m1/v1/companies/investor-impression/${companyID}`;

export const getGrowthRateUrl = (companyID) => `${apiVersion}/api/m1/v1/scores/social-insights?companyId=${companyID}`;
export const getCurrentStreaksUrl = (companyID) => `${apiVersion}/api/m1/v1/companies/${companyID}/streaks`;
export const updateStreakActionUrl = `${apiVersion}/api/m1/v1/actions`;
export const getWorkHistoryTaskUrl = () => `${apiVersion}/api/m2/v1/tasks/work-history`;

export const getOpenAIChatResponseUrl = `${apiVersion}/api/m3/v1/startupos-ai/chat`;
export const getOpenAICompletionResponseUrl = `${apiVersion}/api/m3/v1/startupos-ai/completion`;

export const getCohortsUrl = `${apiVersion}/api/m1/v1/pitch/cohorts`;

export const applyToPitchUrl = `${apiVersion}/api/m1/v1/pitch/apply`;

export const getSubscriptionDetails = `${apiVersion}/api/m3/v1/payments/subscription-details`;

export const getSubscriptionSettings = `${apiVersion}/api/m3/v1/payments/customer-portal-session`;

export const checkout = `${apiVersion}/api/m3/v1/payments/checkout`;

export const getAllBIChartDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts?companyId=${companyID}`;

export const updateAnnualRevenueGridData = () => `${apiVersion}/api/m3/v1/bi-charts/annual-revenues`;

export const updateNetArrGridData = () => `${apiVersion}/api/m3/v1/bi-charts/net-arrs`;

export const updateMonthlyRevenueGridData = () => `${apiVersion}/api/m3/v1/bi-charts/monthly-revenues`;

export const updateBurnRunwayGridData = () => `${apiVersion}/api/m3/v1/bi-charts/burn-runways`;

export const updatePaybackGridData = () => `${apiVersion}/api/m3/v1/bi-charts/cac-paybacks`;

export const updateCustomerGridData = () => `${apiVersion}/api/m3/v1/bi-charts/customers`;

// Feed section
export const feedApiUrl = () => `${apiVersion}/api/m1/v1/posts`;

export const feedReactionUrl = (postId) => `${apiVersion}/api/m1/v1/posts/${postId}/reactions`;

export const getAndPostFeedCommentUrl = (postId) => `${apiVersion}/api/m1/v1/posts/${postId}/comments`;

export const updatePostFeedCommentUrl = (postId, commentId) => `${apiVersion}/api/m1/v1/posts/${postId}/comments/${commentId}`;

export const capTableApiUrl = (companyID) => `${apiVersion}/api/m3/v1/cap-charts?companyId=${companyID}`;

export const updateFeedPostUrl = (postId) => `${apiVersion}/api/m1/v1/posts/${postId}`;

export const updateCapGridDataUrl = () => `${apiVersion}/api/m3/v1/cap-charts`;

export const getAnnualRevenueGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/annual-revenues?companyId=${companyID}`;

export const getNetArrGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/net-arrs?companyId=${companyID}`;

export const getBurnMultipleDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/burn-multiple?companyId=${companyID}`;

export const getMagicNumberDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/magic-number?companyId=${companyID}`;

export const getMonthlyRevenueGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/monthly-revenues?companyId=${companyID}`;

export const getBurnRunwayGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/burn-runways?companyId=${companyID}`;

export const getPaybackGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/cac-paybacks?companyId=${companyID}`;

export const getCustomerGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/customers?companyId=${companyID}`;

export const getRevenueGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/revenues?companyId=${companyID}`;

export const updateRevenueGridDataUrl = () => `${apiVersion}/api/m3/v1/bi-charts/revenues`;

export const getAcvGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/acvs?companyId=${companyID}`;

export const updateAcvGridDataUrl = () => `${apiVersion}/api/m3/v1/bi-charts/acvs`;

export const getSmExpenseVsNewSalesGridDataUrl = (companyID) => `${apiVersion}/api/m3/v1/bi-charts/sm-expenses?companyId=${companyID}`;

export const updateSmExpenseVsNewSalesGridDataUrl = () => `${apiVersion}/api/m3/v1/bi-charts/sm-expenses`;

export const getFeedPostByIdApiUrl = (postId) => `${apiVersion}/api/m1/v1/posts/${postId}`;

export const getNetworkMatchApiUrl = () => `${apiVersion}/api/m1/v1/network/matches`;

export const updateCollectionsUrl = (isRemove) => `${apiVersion}/api/m1/v2/collections?isRemove=${isRemove}`;

export const getUserPublicData = (uuid) => `${apiVersion}/api/m1/v1/users/public?uuid=${uuid}`;

export const getCompanyPublicData = (uuid) => `${apiVersion}/api/m1/v1/companies/public?uuid=${uuid}`;

export const getPlatformJourneyUrl = () => `${apiVersion}/api/m1/v1/users/journey`;

export const getProxyInvestorsUrl = () => `${apiVersion}/api/m1/v1/users/proxy-investors`;

export const requestProxyInvestorConnectUrl = () => `${apiVersion}/api/m1/v1/network/contact-investor`;

export const waitListUrl = () => `${apiVersion}/api/m1/v1/network/waitlist`;

export const deactivateAccountUrl = () => `${apiVersion}/api/m1/v1/requests/deactivate-account`;

export const collectingDataFromPartnerUrl = (partner) => `${apiVersion}/api/m3/v1/integrations/${partner}`;

export const createGoogleSheetUrl = () => `${apiVersion}/api/m3/v1/integrations/google-sheet/create`;

export const getGoogleSheetUrl = () => `${apiVersion}/api/m3/v1/integrations/google-sheet/metadata`;

export const getGoogleSheetGridDataUrl = () => `${apiVersion}/api/m3/v1/integrations/google-sheet/fetch`;

export const googleSheetTokenUrl = () => `${apiVersion}/api/m3/v1/integrations/google-sheet/token`;

export const getApplyToPitchUrl = (companyId) => `${apiVersion}/api/m1/v1/pitch/details?companyId=${companyId}`;

export const getPlaidLinkTokenUrl = () => `${apiVersion}/api/m3/v1/integrations/plaid/link-token`;

export const fetchPlaidDataUrl = () => `${apiVersion}/api/m3/v1/integrations/plaid/fetch`;

export const getCreateAccountUrl = () => `${apiVersion}/api/m1/v2/users/create-account`;

export const getPlaidAccountsUrl = () => `${apiVersion}/api/m3/v1/integrations/plaid/accounts`;

export const sendPlaidAccountsUrl = () => `${apiVersion}/api/m3/v1/integrations/plaid/burn-runaway`;

export const getEventListApiUrl = () => `${apiVersion}/api/m3/v1/events`;

export const getEventDetailsApiUrl = (eventId) => `${apiVersion}/api/m3/v1/events/${eventId}`;

export const getPromotionalEventListApiUrl = () => `${apiVersion}/api/m3/v1/events/feature-event`;

export const getPartnerThemeUrl = (partnerCode) => `${apiVersion}/api/m1/v1/partners?partnerCode=${partnerCode}`;

export const getPartnerStartupsUrl = (page, perPage, partnerCode, sort, sortCol, filterString) =>
	`${apiVersion}/api/m1/v1/partners/companies?partnerCode=${partnerCode}&page=${page}&perPage=${perPage}&filter=${sortCol}&sort=${sort}&${filterString}`;
export const getPartnerUsersUrl = (page, perPage, partnerCode, sort, sortCol, filterString) =>
	`${apiVersion}/api/m1/v1/partners/users?partnerCode=${partnerCode}&page=${page}&perPage=${perPage}&filter=${sortCol}&sort=${sort}&${filterString}`;

export const getPartnerAdminsUrl = (page, perPage, partnerCode, sort, sortCol, filterString) =>
	`${apiVersion}/api/m1/v1/partners/users?partnerCode=${partnerCode}&userTypes=PARTNER_USER&page=${page}&perPage=${perPage}&filter=${sortCol}&sort=${sort}&${filterString}`;

export const getRatingReviewApiUrl = ({ entityId, entityType, details, filter, sort, perPage }) =>
	`${apiVersion}/api/m2/v1/reviews?entityId=${entityId}&entityType=${entityType}&details=${details}&filter=${filter}&sort=${sort}&perPage=${perPage}`;

export const addRatingReviewApiUrl = () => `${apiVersion}/api/m2/v1/reviews`;

export const updateRatingReviewApiUrl = (reviewId) => `${apiVersion}/api/m2/v1/reviews/${reviewId}`;

export const getAdvertBannerUrl = () => `${apiVersion}/api/m3/v1/adverts/banner`;

export const getPartnerCardsUrl = () => `${apiVersion}/api/m1/v1/partners/cards`;

export const enterpriseReadyScoreUrl = () => `${apiVersion}/api/m1/v1/scores/enterprise-ready-score`;

export const getPitchCompaniesUrl = () => `${apiVersion}/api/m1/v1/pitch/companies`;

export const sendEventInvitationUrl = () => `${apiVersion}/api/m1/v1/pitch/event-invitation`;

export const createEventUrl = () => `${apiVersion}/api/m3/v1/events`;

export const updateEventUrl = (eventId) => `${apiVersion}/api/m3/v1/events/${eventId}`;

// ***------------------------Scheduling Api Urls------------------------***

export const getSchedulesByUserIdUrl = () => `${apiVersion}/api/m1/v1/schedules`;

export const getAvailablitiesByDateUrl = () => `${apiVersion}/api/m1/v1/availabilities`;

export const updateSchedulesByUserIdUrl = (scheduleId) => `${apiVersion}/api/m1/v1/schedules/${scheduleId}`;

export const getEventTypesByUserIdUrl = () => `${apiVersion}/api/m1/v1/event-types`;

export const bookMeetingUrl = () => `${apiVersion}/api/m1/v1/bookings`;

export const deleteBookingMeetingUrl = (bookingId) => `${apiVersion}/api/m1/v1/bookings/${bookingId}/cancel`;

export const rescheduleMeetingUrl = (bookingId) => `${apiVersion}/api/m1/v1/bookings/${bookingId}/reschedule`;

// ***------------------------END-------------------------------------------***

// ***------------------------Partner White label Question and Answers---------***

export const partnerQAUrl = () => `${apiVersion}/api/m1/v1/question-answers`;

export const partnerQuestionsUrl = () => `${apiVersion}/api/m1/v1/questions`;

export const updatePartnerQuestionsUrl = (questionId) => `${apiVersion}/api/m1/v1/questions/${questionId}`;

export const partnerEntitiesUrl = () => `${apiVersion}/api/m1/v1/partner-entities`;

export const partnerQuestionAnswerUrl = () => `${apiVersion}/api/m1/v1/partner-question-answers`;

// ***------------------------END-------------------------------------------***

export const adminPowerPackUrl = () => `${apiVersion}/api/m2/v1/cards/all?group=powerpacks`;
export const createPowerPackUrl = () => `${apiVersion}/api/m2/v1/powerup-packs`;

export const activityCardsUrl = () => `${apiVersion}/api/m2/v1/activity-cards`;

export const meetingHistoryUrl = (companyId) => `${apiVersion}/api/m1/v1/companies/${companyId}/bookings`;

export const powerUpActivityUrl = (companyId) => `${apiVersion}/api/m2/v1/tasks/companies/${companyId}/work-history`;

export const updateAdminPowerPackUrl = (powerUpPackUuid) => `${apiVersion}/api/m2/v1/powerup-packs/${powerUpPackUuid}`;

export const deleteAdminPowerupPackUrl = (powerUpPackUuid) => `${apiVersion}/api/m2/v1/powerup-packs/${powerUpPackUuid}`;

export const getAllGroupCardsUrl = () => `${apiVersion}/api/m2/v1/cards/all`;

export const updatePowerupPackSettingsUrl = () => `${apiVersion}/api/m2/v1/cards/settings`;

export const downloadUserProfileDataUrl = () => `${apiVersion}/api/m1/v1/users/download-data`;

export const deleteUserProfileDataUrl = () => `${apiVersion}/api/m1/v1/users`;

export const getLikesUrl = () => `${apiVersion}/api/m1/v2/collections/count`;

export const getPartnerCodesUrl = () => `${apiVersion}/api/m1/v1/partners/codes`;

export const adminQuestionAnswerUrl = () => `${apiVersion}/api/m1/v1/admin-question-answers`;

export const trackCompanyUrl = (isRemove) => `${apiVersion}/api/m1/v1/company-partner/track?isRemove=${isRemove}`;

export const inviteUserUrl = () => `${apiVersion}/api/m1/v1/user-partner/invite`;

export const verifyInvitationTokenUrl = () => `${apiVersion}/api/m1/v1/user-partner/verify-invitation`;

export const getCommunityPartnersUrl = (pageName) => `${apiVersion}/api/m1/v1/partners/community?pageName=${pageName}`;

export const removeCommunityPartnersUrl = () => `${apiVersion}/api/m1/v1/user-partner`;

export const getPartnerGroupsUrl = (partnerCode) => `${apiVersion}/api/m1/v1/groups?partnerCode=${partnerCode}`;

export const getPartnerGroupByIdUrl = (groupId) => `${apiVersion}/api/m1/v1/groups/${groupId}`;

export const createPartnerGroupUrl = () => `${apiVersion}/api/m1/v1/groups`;

export const getAttributesUrl = () => `${apiVersion}/api/m1/v1/attributes`;

export const getMyAttributesUrl = () => `${apiVersion}/api/m1/v1/attributes/user`;

export const getRolesUrl = () => `${apiVersion}/api/m1/v1/attributes/roles`;

export const getRoleByIdUrl = (role) => `${apiVersion}/api/m1/v1/attributes/roles/${role}`;

export const getPartnerRolesUrl = (partnerCode) => `${apiVersion}/api/m1/v1/attributes/roles?partnerCode=${partnerCode}`;

export const getGroupRolesUrl = (groupId) => `${apiVersion}/api/m1/v1/attributes/group/${groupId}/roles`;

export const getUserRolesUrl = (userId) => `${apiVersion}/api/m1/v1/attributes/user/${userId}/roles`;

export const getUserAttributesUrl = (userId, direct) => `${apiVersion}/api/m1/v1/attributes/user/${userId}${direct ? "?directOnly=true" : ""}`;
export const getCompanyAttributesUrl = (companyId, direct) => `${apiVersion}/api/m1/v1/attributes/company/${companyId}${direct ? "?directOnly=true" : ""}`;

export const getCompanyRolesUrl = (companyId) => `${apiVersion}/api/m1/v1/attributes/company/${companyId}/roles`;

export const assignRolesAndAttributesUrl = () => `${apiVersion}/api/m1/v1/attributes/assign`;

export const getGroupAttributesUrl = (groupId) => `${apiVersion}/api/m1/v1/attributes/group/${groupId}`;

export const getPartnerFormByIdUrl = (formId) => `${apiVersion}/api/m3/v1/forms/${formId}`;
export const getPartnerFormsUrl = (partnerCode) => `${apiVersion}/api/m3/v1/forms?partnerCode=${partnerCode}`;
export const createPartnerFormUrl = () => `${apiVersion}/api/m3/v1/forms`;
export const getAssignedFormByEntityIdUrl = ({ entityType, entityId }) =>
	`${apiVersion}/api/m3/v1/forms/assigned?entityType=${entityType}&entityId=${entityId}`;
export const assignFormUrl = () => `${apiVersion}/api/m3/v1/forms/assign`;
export const formSubmissionsBaseUrl = () => `${apiVersion}/api/m3/v1/form-submissions`;
