import { InfoOutlined, InfoRounded } from "@mui/icons-material";
import { Box, Popover, Typography } from "@mui/material";
import React, { Fragment } from "react";
import JsxParser from "react-jsx-parser";

function InfoHelper({ title, body, iconType, bodyComp }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Fragment>
			{iconType && iconType === "gray" ? (
				<InfoOutlined
					sx={{
						color: "#667085",
						height: "1.25rem",
						width: "1.25rem",
						cursor: "pointer",
						transition: "all .2s",
						"&:hover": {
							scale: "1.1",
						},
					}}
					onClick={handleClick}
				/>
			) : (
				<InfoRounded
					sx={{
						color: (theme) => theme?.palette?.tertiary?.main,
						height: "25px",
						width: "25px",
						cursor: "pointer",
						transition: "all .2s",
						"&:hover": {
							scale: "1.1",
						},
					}}
					onClick={handleClick}
				/>
			)}

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				PaperProps={{
					sx: {
						p: "30px",
						borderRadius: "30px",
						background: "white",
						boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.1)",
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						maxWidth: "27.0625rem",
						gap: "1rem",
					}}
				>
					<Typography variant="Text/sm/Semibold" component={"p"}>
						{title}
					</Typography>
					{bodyComp ? (
						bodyComp
					) : (
						<Typography variant="Text/sm/Regular" component={"p"}>
							<JsxParser autoCloseVoidElements components={{ Typography }} jsx={body} />
						</Typography>
					)}
				</Box>
			</Popover>
		</Fragment>
	);
}

export default InfoHelper;
