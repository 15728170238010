import { USER_TYPES } from "../../utils/userTypes";

const permissionMapping = {
	"manage-social-posts": { action: "manage", subject: "SocialPosts" },
	"view-all-communities": { action: "view", subject: "CommunityOther" },
};

export function defineSocialAbilities(user, can, cannot) {
	// Loop over user permissions to define abilities
	user?.permissions?.forEach((permission) => {
		const mapping = permissionMapping[permission?.name];
		if (mapping) {
			can(mapping?.action, mapping?.subject);
		}
	});
}
