import { USER_TYPES } from "../../utils/userTypes";

const permissionMapping = {
	"view-startup-grid": { action: "view", subject: "StartupGrid" },
};

export function defineStartupGridAbilities(user, can, cannot) {
	// Loop over user permissions to define abilities
	user?.permissions?.forEach((permission) => {
		const mapping = permissionMapping[permission?.name];
		if (mapping) {
			can(mapping?.action, mapping?.subject);

			// If the permission is for managing, also grant viewing rights
			if (mapping.action === "manage") {
				can("view", mapping?.subject);
			}
		}
	});
}
