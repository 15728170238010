import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import FormBuilder from "../FormBuilder";
import FormList from "../Utilities/FormList";
import { useSelector } from "react-redux";
import services from "../../../services";

function FormManager() {
	const [partnerCodeSelect, setPartnerCodeSelect] = useState(null);
	const [loadedPartnerCode, setloadedPartnerCode] = useState("sos");
	const partnerCode = useSelector((state) => state?.auth?.partnerCode);
	const [options, setOptions] = useState(false);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const [formsTableKey, setKeyForFormsTable] = useState(0);

	useEffect(() => {
		getPartnerCodes();

		if (partnerCode) {
			setloadedPartnerCode(partnerCode); //Prevent table flash on field clear.
			setPartnerCodeSelect(partnerCode);
		}
	}, [partnerCode]);

	const getPartnerCodes = async () => {
		try {
			const res = await services.getPartnerCodesApi();
			const partnerCodes = res?.data?.data?.map((item) => item?.partnerCode);
			// partnerCodes?.unshift(partnerCode);
			setOptions(partnerCodes);
		} catch (error) {
			console.log(`Error fetching partner codes`, error);
		}
	};

	const reloadFormsTable = () => {
		setKeyForFormsTable((prevKey) => prevKey + 1);
	};

	return (
		<Box
			sx={{
				px: { lg: 8, md: 4, xs: 2 },
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				margin: "0 auto",
				maxWidth: "1920px", // Figma specifies max content width
			}}
		>
			{isAdmin && (
				<Box
					sx={{
						borderRadius: "12px",
						border: "1px solid var(--gray-200, #EAECF0)",
						background: "var(--base-white, #FFF)",
						boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
						p: 3,
						display: "flex",
						alignItems: "center",
					}}
				>
					<Box sx={{ flex: 1 }}>
						<Typography variant="Text/md/Semibold" component={"p"}>
							StartupOS Admin Control
						</Typography>
						<Typography variant="Text/sm/Regular" component={"p"}>
							Select an accelerator to load forms.
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "35%" }}>
						<FormControl>
							<Typography variant="count_title" sx={{ ml: 1, p: 1 }}>
								{"Accelerator Code"}
							</Typography>
							<Select
								value={partnerCodeSelect}
								onChange={(event) => {
									setPartnerCodeSelect(event?.target?.value);
									reloadFormsTable();
								}}
								sx={{
									borderRadius: "30px",
								}}
							>
								{options &&
									options?.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</Box>
				</Box>
			)}
			<FormList partnerCode={partnerCodeSelect || loadedPartnerCode} tableKey={`formsTable_${formsTableKey}`} />
		</Box>
	);
}

export default FormManager;
