import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";

export default function AttributeSelectionList({ attributes, selectedAttributes, updateSelection, readOnly }) {
	const [checked, setChecked] = React.useState([0]);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value?.id);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value?.id);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
		updateSelection(newChecked);
	};

	const handleCheckAll = () => {
		const allIds = attributes.map((attr) => attr.id);
		setChecked(allIds);
		updateSelection(allIds);
	};

	const handleUncheckAll = () => {
		setChecked([]);
		updateSelection([]);
	};

	React.useEffect(() => {
		setChecked(selectedAttributes);
	}, [selectedAttributes]);

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					mb: 2,
					gap: 2,
					alignItems: "center",
					justifyContent: "flex-end",
				}}
			>
				<Button
					disabled={readOnly}
					variant="DS1"
					small={true}
					onClick={handleCheckAll}
					startIcon={
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none">
							<path
								d="M8 20L10.6667 22.6666L16.6667 16.6666M10.6667 10.6666V6.93329C10.6667 5.43982 10.6667 4.69308 10.9573 4.12265C11.213 3.62089 11.6209 3.21294 12.1227 2.95728C12.6931 2.66663 13.4399 2.66663 14.9333 2.66663H25.0667C26.5601 2.66663 27.3069 2.66663 27.8773 2.95728C28.3791 3.21294 28.787 3.62089 29.0427 4.12265C29.3333 4.69308 29.3333 5.43982 29.3333 6.93329V17.0666C29.3333 18.5601 29.3333 19.3068 29.0427 19.8773C28.787 20.379 28.3791 20.787 27.8773 21.0426C27.3069 21.3333 26.5601 21.3333 25.0667 21.3333H21.3333M6.93334 29.3333H17.0667C18.5601 29.3333 19.3069 29.3333 19.8773 29.0426C20.3791 28.787 20.787 28.379 21.0427 27.8773C21.3333 27.3068 21.3333 26.5601 21.3333 25.0666V14.9333C21.3333 13.4398 21.3333 12.6931 21.0427 12.1227C20.787 11.6209 20.3791 11.2129 19.8773 10.9573C19.3069 10.6666 18.5601 10.6666 17.0667 10.6666H6.93334C5.43986 10.6666 4.69313 10.6666 4.1227 10.9573C3.62093 11.2129 3.21298 11.6209 2.95732 12.1227C2.66667 12.6931 2.66667 13.4398 2.66667 14.9333V25.0666C2.66667 26.5601 2.66667 27.3068 2.95732 27.8773C3.21298 28.379 3.62093 28.787 4.1227 29.0426C4.69313 29.3333 5.43986 29.3333 6.93334 29.3333Z"
								stroke="white"
								stroke-width="2.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					}
				>
					Check All
				</Button>
				<Button
					disabled={readOnly}
					variant="DS1"
					color="gray"
					small={true}
					onClick={handleUncheckAll}
					startIcon={
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
							<path
								d="M15 1.66669V7.00002M15 23V28.3334M7.00002 15H1.66669M28.3334 15H23M24.4379 24.4379L20.6667 20.6667M24.4379 5.66661L20.6667 9.43785M5.56212 24.4379L9.33335 20.6667M5.56212 5.66661L9.33335 9.43785"
								stroke="#344054"
								stroke-width="3.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					}
				>
					Uncheck All
				</Button>
			</Box>
			<List
				sx={{
					width: "100%",
					display: "flex",
					flexWrap: "wrap",
					boxSizing: "border-box",
					justifyContent: "space-between",
				}}
			>
				{attributes?.map((value) => {
					const labelId = `checkbox-list-label-${value?.id}`;

					return (
						<ListItem
							sx={(theme) => ({
								width: "33%",
								padding: "8px",
								[theme.breakpoints.down("lg")]: {
									width: "50%",
								},
								[theme.breakpoints.down("md")]: {
									width: "100%",
								},
							})}
							key={value?.id}
							disablePadding
						>
							<ListItemButton
								disabled={readOnly}
								onClick={handleToggle(value)}
								dense
								sx={{
									padding: "16px",
									backgroundColor: "white",
									borderRadius: "12px",
								}}
							>
								<ListItemText
									sx={{
										margin: "0",
									}}
									id={labelId}
									primary={(() => {
										const regex = /^[a-z]+(-[a-z]+)*$/;
										if (regex.test(value?.name)) {
											return value?.name
												.split("-")
												.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
												.join(" ");
										} else {
											return value?.name;
										}
									})()}
									primaryTypographyProps={{
										variant: "Text/xs/Semibold",
									}}
									secondary={value?.description}
									secondaryTypographyProps={{
										variant: "Text/xs/Regular",
									}}
								/>
								<ListItemIcon
									sx={{
										minWidth: "unset",
										display: "flex",
										justifyContent: "center",
										alignItems: "flex-start",
										alignSelf: "stretch",
									}}
								>
									<Checkbox
										edge="start"
										checked={checked?.indexOf(value?.id) !== -1}
										tabIndex={-1}
										disableRipple
										inputProps={{ "aria-labelledby": labelId }}
										sx={{
											marginLeft: "unset",
										}}
									/>
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
		</Box>
	);
}
