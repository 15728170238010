import { USER_TYPES } from "../../utils/userTypes";

const permissionMapping = {
	"manage-event-applicants": { action: "manage", subject: "EventApplicants" },
	"view-event-applicants": { action: "view", subject: "EventApplicants" },
	"view-events": { action: "view", subject: "EventDashboard" },
	"manage-events": { action: "manage", subject: "EventDashboard" },
};

export function defineEventAbilities(user, can, cannot) {
	// Loop over user permissions to define abilities
	user?.permissions?.forEach((permission) => {
		const mapping = permissionMapping[permission?.name];
		if (mapping) {
			can(mapping?.action, mapping?.subject);

			// If the permission is for managing, also grant viewing rights
			if (mapping.action === "manage") {
				can("view", mapping?.subject);
			}
		}
	});

	// Check if the user's permissions allow any of the relevant subjects for Event Dashboard access
	const hasSomeAccess = user?.permissions?.some((permission) => {
		// Check if the user's permission name matches a key in permissionMapping
		return Object.keys(permissionMapping).some((key) => key === permission?.name);
	});

	// If the user has any Event Dashboard permissions; allow them to view the component.
	if (hasSomeAccess) {
		can("view", "EventDashboard");
	}
}
