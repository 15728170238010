import { USER_TYPES } from "../../utils/userTypes";

export function defineGeneralAbilities(user, can, cannot) {
	// User can view items that do not require approval
	can("view", "MainSideNavigation", { approvalRequired: false });

	can("view", "WorkspaceTopNavMessaging");

	// User can view items that require approval only if the approval status is "APPROVED"
	if (user?.approvalStatus === "APPROVED") {
		can("view", "MainSideNavigation", { approvalRequired: true });
	}

	if (!user?.isInvestorLocked && (user?.userType === USER_TYPES.STARTUP_OWNER || user?.userType === USER_TYPES.STARTUP_USER)) can("view", "WorkspaceBanner");

	if (user?.userType != USER_TYPES.PARTNER_USER) {
		can("view", "WorkspaceTopNavMarketplace");
		if (user?.approvalStatus === "APPROVED") can("view", "WorkspaceTopNavMeetings");
	}

	if (user?.userType === USER_TYPES.INVESTOR_USER) {
		if (user?.approvalStatus != "APPROVED") cannot("view", "WorkspaceTopNavMessaging");
	}

	//if (user?.userType === USER_TYPES.STARTUP_OWNER) {
	//}
	//if (user?.userType === USER_TYPES.STARTUP_USER) {
	//}
	//if (user?.userType === USER_TYPES.ADVISOR_USER) {
	//}
	//if (user?.userType === USER_TYPES.MENTOR_USER) {
	//}
	//if (user?.userType === USER_TYPES.VERIFIED_MENTOR_USER) {
	//}
	//if (user?.userType === USER_TYPES.INVESTOR_USER) {
	//}
	//if (user?.userType === USER_TYPES.CANDIDATE_USER) {
	//}
	//if (user?.userType === USER_TYPES.PARTNER_USER) {
	//}
	//if (user?.userType === USER_TYPES.PROFESSOR_USER) {
	//}
	//if (user?.userType === USER_TYPES.STUDENT_USER) {
	//}
	//if (user?.userType === USER_TYPES.ALUMNI_USER) {
	//}
	//if (user?.userType === USER_TYPES.CORPORATE_USER) {
	//}
}
