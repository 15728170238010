import { Box, Typography, useTheme, Button, Fab, Badge, Autocomplete, Select, OutlinedInput, MenuItem, InputLabel, FormControl } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import TableController from "../../../../components/Tables/TableController";
import { StartupSortOptions, StartupTableColumns } from "./StartupTableColumns";
import { FounderSortOptions, FounderTableColumns } from "./FounderTableColumns";
import EditIcon from "@mui/icons-material/Edit";
import { getPartnerFounders, getPartnerRoles, getPartnerStartups, removePartnerFromFounder } from "./functions";
import { useDispatch, useSelector } from "react-redux";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import ThemeEditFlyout from "./ThemeEditFlyout";
import services from "../../../../services";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import SortComponent from "../../../../components/Tables/SortComponent";
import FilterDrawer from "./FilterDrawer";
import SelectDrop from "./SelectDrop";
import ToggleButtons from "./ToggleButtons";
import FormHelperText from "@mui/material/FormHelperText";
import { SelectIconComponent } from "../../../NewOnboard/Components";
import { setCommonDialogConfirmationData } from "../../../../modules/actions";
import UpdatedDialogConfirmation from "../../../../common/UpdatedDialogConfirmation";
import { ALERT_ICON_V1 } from "../../../../constants";
import Roles from "./Roles";

function RolesTable({ partnerCode, rolesTableKey, setKeyForRolesTable, editRole, updatedSelection, selectedIds }) {
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();
	const [partnerCodeSelect, setPartnerCodeSelect] = useState(null);
	const [loadedPartnerCode, setloadedPartnerCode] = useState(null);
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const userType = useSelector((store) => store?.auth?.userType);

	//const [rolesTableKey, setKeyForRolesTable] = useState(0);

	const [foundersTableKey, setKeyForFoundersTable] = useState(0);
	const [sortCriteriaStartups, setSortCriteriaStartups] = useState({ colName: "", direction: "" });
	const [filterCriteriaStartups, setFilterCriteriaStartups] = useState({});
	const [filterDrawerOpen, setFilterDrawerOpen] = useState({ startups: false, founders: false });
	const [options, setOptions] = useState(false);
	const [buttonLoader, setButtonLoader] = useState(false);

	const [rowSelectionModel, setRowSelectionModel] = useState(selectedIds);

	useEffect(() => {
		if (typeof updatedSelection === "function") updatedSelection(rowSelectionModel);
	}, [rowSelectionModel]);

	const roleColumns = [
		{
			field: "roleName",
			headerName: "Role Name",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "roleDescription",
			headerName: "Description",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 400,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
	];

	const handleSortChange = (newSortCriteria, type) => {
		if (type === "startups") setSortCriteriaStartups(newSortCriteria);
	};

	const reloadStartupsTable = () => {
		setKeyForRolesTable((prevKey) => prevKey + 1);
	};

	const reloadFoundersTable = () => {
		setKeyForFoundersTable((prevKey) => prevKey + 1);
	};

	const reloadTables = (pc) => {
		setloadedPartnerCode(pc); //Prevent table flash on field clear.
		reloadStartupsTable();
		reloadFoundersTable();
	};

	useEffect(() => {
		if (partnerCode) {
			setloadedPartnerCode(partnerCode); //Prevent table flash on field clear.
			setPartnerCodeSelect(partnerCode);
		}
	}, [partnerCode]);

	useEffect(() => {
		sortfilterStartups();
	}, [sortCriteriaStartups]);

	useEffect(() => {
		sortfilterStartups();
	}, [filterCriteriaStartups]);

	const sortfilterStartups = async () => {
		reloadStartupsTable();
	};

	const getRoles = async (currentPage, pageSize) => {
		const result = await getPartnerRoles(
			currentPage,
			pageSize,
			partnerCodeSelect,
			sortCriteriaStartups?.direction,
			sortCriteriaStartups?.colName,
			formatFiltersForQuery(filterCriteriaStartups)
		);
		console.log("WE GOT:", result);
		return result;
	};

	const openStartupProfile = (id) => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "STARTUP_PROFILE",
				profileId: id,
			})
		);
	};

	const formatFiltersForQuery = (filters) => {
		try {
			return Object.entries(filters)
				.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
				.join("&");
		} catch (e) {
			console.log("Unable to process filter for query string, failed with ", e);
			return "";
		}
	};

	const handleStartupFilter = (column, value) => {
		setFilterCriteriaStartups((prevFilters) => {
			const newFilters = { ...prevFilters };
			// If the value is not empty, blank, or null, set the filter
			if (value !== "" && value !== null && value !== undefined) {
				newFilters[column] = value;
			} else {
				// If the value is empty, blank, or null, delete the filter
				delete newFilters[column];
			}
			return newFilters;
		});
	};

	const clearStartupFilters = () => {
		setFilterCriteriaStartups({});
	};

	return (
		<Box
			sx={{
				//px: { lg: 8, md: 4, xs: 2 },
				pb: "30px",
				display: "flex",
				flexDirection: "column",
				gap: "32px",
				margin: "0 auto",
				maxWidth: "1920px", // Figma specifies max content width
				width: "100%",
			}}
		>
			{(partnerCodeSelect || loadedPartnerCode) && (
				<TableController
					checkboxSelection={typeof updatedSelection === "function" ? true : false}
					rowSelectionModel={typeof updatedSelection === "function" ? rowSelectionModel : []}
					setRowSelectionModel={typeof updatedSelection === "function" ? setRowSelectionModel : null}
					key={`roles_${rolesTableKey}`}
					requestData={(currentPage, pageSize) => getRoles(currentPage, pageSize)}
					columnDefs={roleColumns}
					title={typeof updatedSelection === "function" ? "Assign Roles" : "Roles"}
					{...(typeof updatedSelection === "function" && { description: "Select roles to assign to the group." })}
					showCount
					countLabel={{ singular: "Role", plural: "Roles" }}
					icon={
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<path
								d="M8.66667 26.6665H6.66667C5.19391 26.6665 4 25.4726 4 23.9998V5.33317C4 3.86041 5.19391 2.6665 6.66667 2.6665H25.3333C26.8061 2.6665 28 3.86041 28 5.33317V23.9998C28 25.4726 26.8061 26.6665 25.3333 26.6665H23.3333M16 25.3332C18.2091 25.3332 20 23.5423 20 21.3332C20 19.124 18.2091 17.3332 16 17.3332C13.7909 17.3332 12 19.124 12 21.3332C12 23.5423 13.7909 25.3332 16 25.3332ZM16 25.3332L16.0286 25.3329L11.7716 29.5899L8.00033 25.8187L12.0262 21.7928M16 25.3332L20.2571 29.5899L24.0283 25.8187L20.0024 21.7928M12 7.99984H20M9.33333 12.6665H22.6667"
								stroke={theme?.palette?.primary?.main}
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					}
					noResultsMessage={
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "16px",
								alignSelf: "stretch",
								flex: 1,
								justifyContent: "center",
							}}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Typography variant="Text/sm/Semibold">No Roles found</Typography>
						</Box>
					}
					onRowClick={(res) => {
						if (typeof editRole === "function") editRole(res?.id);
					}}
					dataGridProps={{ disableColumnMenu: true }}
				/>
			)}
		</Box>
	);
}

export default RolesTable;
