import React, { useState, useEffect } from "react";
import EventCards from "../../components/Events/EventCards";
import services from "../../services";
import { Helmet } from "react-helmet";
import { CALENDAR_MAIN_BLACK } from "../../constants";
import { Box, Typography, MenuItem, ListItemText } from "@mui/material";
import PageTitle from "../../common/PageTitle";
import { setCommunityPartners, setEventData } from "../../modules/actions";
import { useDispatch, useSelector } from "react-redux";
import UpdatedSelectInput from "../../common/UpdatedSelectInput";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../services/abilities/context";

const EventBanners = () => {
	const [eventList, setEventList] = useState([]);
	const [loading, setLoading] = useState(false);
	const { partnerCode } = useSelector((store) => store?.auth);

	const showCommunityPartners = useSelector((state) => state?.communityPartners?.showCommunityPartners);
	const communityPartnersOptions = useSelector((state) => state?.communityPartners?.communityPartners);
	const selectedOption = useSelector((state) => state?.communityPartners?.selectedOption);
	const communityPartnerItems = useSelector((state) => state?.communityPartners?.communityPartnerItems);
	const [selectedSort, setSelectedSort] = useState(selectedOption);
	const [menuOpen, setMenuOpen] = useState(false);
	const selectedPartnerCode = communityPartnerItems?.find((item) => item?.partnerName == selectedOption);
	const ability = useAbility(AbilityContext);
	const dispatch = useDispatch();

	const fetchOptions = async () => {
		try {
			const response = await services.getCommunityPartnersApi("founders");

			if (!ability.can("view", "CommunityOther")) {
				dispatch(setCommunityPartners({ showCommunityPartners: false, selectedOption: "", communityPartners: [] }));
				return;
			} else if (!response?.data?.data || response?.data?.data?.length === 0) {
				dispatch(setCommunityPartners({ showCommunityPartners: false, selectedOption: "", communityPartners: [] }));
				return;
			} else {
				const resData = response?.data?.data;
				const communityPartnerNames = resData && resData.length > 0 && resData?.map((item) => item?.partnerName);
				const communityPartnerItems = resData && resData.length > 0 && resData?.map((item) => item);

				communityPartnerNames.push("StartupOS");
				communityPartnerItems.push({
					partnerName: "StartupOS",
					partnerCode: "sos",
				});

				dispatch(
					setCommunityPartners({
						showCommunityPartners: true,
						communityPartners: communityPartnerNames,
						communityPartnerItems: communityPartnerItems,
					})
				);
			}
		} catch (error) {
			console.error("Error fetching options:", error);
		}
	};

	useEffect(() => {
		GetEventList();
		fetchOptions();
	}, []);

	useEffect(() => {
		GetEventList();
	}, [selectedSort]);

	const GetEventList = async () => {
		try {
			setLoading(true);
			setEventList([]);

			const response = await services
				.getEventListApi(null, !ability.can("view", "CommunityOther") ? (partnerCode ? partnerCode : "sos") : selectedPartnerCode?.partnerCode)
				.then((response) => {
					if (response) {
						setLoading(false);
						dispatch(
							setEventData({
								eventList: response?.data?.data,
							})
						);
						setEventList(response?.data?.data);
					} else {
						setLoading(false);
						dispatch(
							setEventData({
								eventList: [],
							})
						);
						setEventList([]);
					}
				})
				.catch((e) => {
					console.error("Error fetching event list:", e);
				});
		} catch (error) {
			console.error("Error fetching event list:", error);
		}
	};

	const ITEM_HEIGHT = 30;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
				borderRadius: "0px 0px 40px 40px",
				boxShadow: "0px 5px 24px rgba(0, 0, 0, 0.15)",
			},
		},
	};
	const handleChange = (event) => {
		dispatch(setCommunityPartners({ selectedOption: event?.target?.value }));
		setSelectedSort(event?.target.value);
	};

	const handleMenuOpen = (value) => {
		setMenuOpen(value);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Events</title>
			</Helmet>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px",
					margin: "0 auto",
				}}
			>
				<Box display="flex" alignItems="center">
					<PageTitle icon={CALENDAR_MAIN_BLACK} label={"Events"} />
					{showCommunityPartners && (
						<Box width={"180px"} ml={2}>
							<UpdatedSelectInput
								onInputChange={handleChange}
								MenuProps={MenuProps}
								selectedValue={selectedSort}
								handleMenuOpen={handleMenuOpen}
								handleMenuClose={handleMenuOpen}
								wrapperstyle={{
									"& .MuiInputBase-root": {
										height: "auto",
										boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.05)",
										borderRadius: menuOpen ? "40px 40px 0px 0px" : "40px",
										fontSize: "16px",
										fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
										background: (theme) => theme.palette.secondary.white,
										width: "216px",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"& .MuiOutlinedInput-input": { pl: 3 },
									width: 1,
								}}
								renderValue={(selected) => {
									if (!selected?.length > 0) {
										return (
											<Box sx={{ my: 0, py: 0 }} display={"flex"}>
												<Typography
													variant="poppinsMedium16"
													sx={{
														lineHeight: "19px",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
														overflow: "hidden",
													}}
													color="secondary.black"
												>
													Select Community
												</Typography>
											</Box>
										);
									}

									return (
										<Box sx={{ display: "flex" }} alignItems="center">
											<Typography
												variant="poppinsMedium16"
												sx={{
													lineHeight: "19px",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
												}}
												color="secondary.black"
											>
												{selected}
											</Typography>{" "}
										</Box>
									);
								}}
								inputProps={{ "aria-label": "Without label" }}
							>
								{communityPartnersOptions?.length > 0 &&
									communityPartnersOptions?.map((option) => (
										<MenuItem
											display={"flex"}
											alignItems="center"
											key={option}
											value={option}
											sx={{
												py: 1,
												px: 2,
												"&.Mui-selected": {
													backgroundColor: "rgba(106, 117, 117, 0.08)",
												},
											}}
											onClick={() => {}}
										>
											<ListItemText sx={{ ml: 3 }} primary={<Typography variant="custom070">{option}</Typography>} />{" "}
										</MenuItem>
									))}
							</UpdatedSelectInput>
						</Box>
					)}
				</Box>

				<EventCards events={eventList} loading={loading} />
			</Box>
		</>
	);
};

export default EventBanners;
