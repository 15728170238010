import React, { useState, useEffect, useMemo } from "react";
import { Box, Grid, Typography, Fab, Collapse, CircularProgress, useMediaQuery, MenuItem, ListItemText } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { debounce, get } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { NetworkingSerchField } from "./styles";
import {
	ALERT_ICON_V1,
	BLACK_GRID_ICON_V2,
	BLACK_LIST_ICON_V2,
	GRID_BLACK_ICON_V1,
	GRID_PURPLE_ICON_V1,
	MENU_BLACK_ICON_V1,
	MENU_PURPLE_ICON_V1,
	PAGE_COMMUNITY_ICON,
	UPDATED_SEARCH_ICON,
	WHITE_GRID_ICON_V2,
	WHITE_LIST_ICON_V2,
} from "../../constants";
import NetworkingCard from "../../components/Networking/NetworkingCard";
import services from "../../services";
import FilterDialog from "../../components/Networking/FilterDialog";
import FindMeButton from "../../components/Networking/FindMeButton";
import ScheduleMeetingDialog from "../../components/Networking/ScheduleMeetingDialog";
import { USER_TYPES } from "../../utils/userTypes";
import { useWindowSize } from "../../utils/windowResize";
import { setNetworkingDashboardData } from "../../modules/actions/networkingActions";
import FilteredValueCarosualComponent from "../../components/Networking/FilterDialog/component/FilteredValueCarosual";
import { useHistory } from "react-router-dom";
import NetworkingTab from "./NetworkingTab";
import { getProxyInvstors, requestProxyInvestorConnect, saveOrHideMatchedCollections, verifyInvitationToken } from "./Functions/common";
import ConfirmationDialog from "../../components/Networking/ConfirmationDialog";
import PaymentPlanDialog from "../../components/PaymentPlanDialog";
import ProxyInvestor from "../../components/Networking/NetworkingCard/component/ProxyInvestor";
import RequestSentDialog from "../../components/Networking/RequestSentDialog";
import { setSlidingProfileData } from "../../modules/actions/slidingProfileActions";
import { handleMixPanel } from "../../utils/mixPanelEventHandling";
import { toastContainer } from "../../utils/toast";
import CommunityTable from "../../components/Networking/CommunityTable";
import { setCommonDialogConfirmationData, setCommunityPartners, setEmailToken } from "../../modules/actions";
import UpdatedDialogConfirmation from "../../common/UpdatedDialogConfirmation";
import IndustryDetailsDialog from "../../components/Investor/IndustryDetails";
import NewSingleSelect from "../../common/NewSingleSelect";
import { useLocation } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../constants/Theme/ThemeContext";
import UpdatedSelectInput from "../../common/UpdatedSelectInput";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../services/abilities/context";

const firstPage = 1;

const userTypesName = {
	[USER_TYPES.MENTOR_USER]: "Mentors",
	[USER_TYPES.INVESTOR_USER]: "Investors",
	[USER_TYPES.CORPORATE_USER]: "Corporate",
	[USER_TYPES.STARTUP_OWNER]: "Founders",
	[USER_TYPES.ALUMNI_USER]: "Alumni",
	SAVED: "Saved",
	HIDDEN: "Blocked",
};

function Networking() {
	const [currentPage, setCurrentPage] = useState(firstPage);
	const [loading, setLoading] = useState(false);
	const [isLast, setIsLast] = useState(false);
	const [pageData, setPageData] = useState({ isLast: false });
	const [totalPages, setTotalPages] = useState(0);
	const [openScheduleMeetingDialog, setOpenScheduleMeetingDialog] = useState({ open: false, userInfo: null, type: null });
	const dispatch = useDispatch();

	const { partnerCode } = useSelector((store) => store?.auth);

	const currentTab = useSelector((state) => state.networking?.currentTab);
	const networkMatches = useSelector((state) => state.networking?.networkMatches ?? []);
	const filteredValue = useSelector((state) => state.networking?.filteredValue);
	const appliedFilteredValue = useSelector((state) => state.networking?.appliedFilteredValue);
	const searchedText = useSelector((state) => state.networking?.searchedText);

	const [isFilterChanged, setIsFilterChanged] = useState(false);
	const size = useWindowSize();
	const totalMatches = useSelector((state) => state?.networking?.totalMatches ?? 0);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const [viewType, setViewType] = useState("GRID");
	const emailToken = useSelector((state) => state?.emailToken);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const showCommunityPartners = useSelector((state) => state?.communityPartners?.showCommunityPartners);
	const communityPartnersOptions = useSelector((state) => state?.communityPartners?.communityPartners);
	const selectedOption = useSelector((state) => state?.communityPartners?.selectedOption);
	const communityPartnerItems = useSelector((state) => state?.communityPartners?.communityPartnerItems);
	const [selectedSort, setSelectedSort] = useState(selectedOption);
	const [menuOpen, setMenuOpen] = useState(false);
	const location = useLocation();
	const selectedPartnerCode = communityPartnerItems?.find((item) => item?.partnerName == selectedOption);

	const ability = useAbility(AbilityContext);
	// console.log("communityPartners", communityPartners, selectedPartnerCode, selectedOption);
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const token = params.get("token");
		const verificationToken = token ? token : retrieveFromLocalStorage("emailToken");

		if (verificationToken) {
			const isVerified = verifyInvitationToken(verificationToken);
			dispatch(setEmailToken({ emailToken: isVerified }));
		}
	}, [location]);

	useEffect(() => {
		fetchOptions();
	}, []);

	const getPageName = () => {
		switch (currentTab) {
			case USER_TYPES.STARTUP_OWNER:
				return "founders";
			case USER_TYPES.MENTOR_USER:
				return "mentors";
			case USER_TYPES.INVESTOR_USER:
				return "investors";
		}
	};

	const fetchOptions = async () => {
		try {
			const pageName = getPageName();
			const response = await services.getCommunityPartnersApi(pageName);
			if (!ability.can("view", "CommunityOther")) {
				dispatch(setCommunityPartners({ showCommunityPartners: false, selectedOption: "", communityPartners: [] }));
				return;
			} else if (!response?.data?.data || response?.data?.data?.length === 0) {
				dispatch(setCommunityPartners({ showCommunityPartners: false, selectedOption: "", communityPartners: [] }));
				return;
			} else {
				const resData = response?.data?.data;
				const communityPartnerNames = resData && resData.length > 0 && resData?.map((item) => item?.partnerName);
				const communityPartnerItems = resData && resData.length > 0 && resData?.map((item) => item);

				dispatch(
					setCommunityPartners({
						showCommunityPartners: true,
						communityPartners: communityPartnerNames,
						communityPartnerItems: communityPartnerItems,
					})
				);
			}
		} catch (error) {
			console.error("Error fetching options:", error);
		}
	};

	useEffect(() => {
		let trackingTitle = "";
		if (currentTab === USER_TYPES.STARTUP_OWNER) {
			trackingTitle = "Founders Viewed";
		} else if (currentTab === USER_TYPES.MENTOR_USER) {
			trackingTitle = "Mentors Viewed";
		} else if (currentTab === USER_TYPES.INVESTOR_USER) {
			trackingTitle = "Investors Viewed";
		} else if (currentTab === USER_TYPES.CORPORATE_USER) {
			trackingTitle = "Corporaters Viewed";
		} else if (currentTab === USER_TYPES.ALUMNI_USER) {
			trackingTitle = "Alumni Viewed";
		}

		if (trackingTitle !== "") {
			handleMixPanel(trackingTitle);
		}
		// Reset Community Partner Selection On Tab Change
		setSelectedSort("");
		fetchOptions();
	}, [currentTab]);

	useEffect(() => {
		setViewType("GRID");
	}, [currentTab]);

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const [proxyInvestors, setProxyInvestors] = useState([]);

	const userData = useSelector((store) => ({
		userId: store?.auth?.userId,
		userName: store?.auth?.userName,
		companyId: store?.auth?.companyId,
		companyName: store?.auth?.companyName,
		userType: store?.auth?.userType,
	}));

	useEffect(() => {
		dispatch(
			setNetworkingDashboardData({
				hiddenConfirmOpen: false,
				openMessageDialog: false,
				openFilterDialog: false,
				requestSentOpen: false,
				filteredValue: appliedFilteredValue?.length > 0 ? appliedFilteredValue : [],
			})
		);
	}, []); //onload

	const listHeight = useMemo(() => {
		if (size.width > 1536) {
			return "calc(100vh - 400px)";
		} else if (size.width > 1200) {
			return "calc(100vh - 410px)";
		} else if (size.width > 1045) {
			return "calc(100vh - 460px)";
		} else if (size.width > 900) {
			return "calc(100vh - 520px)";
		} else {
			return 400;
		}
	}, [size]);

	const handleCloseScheduleMeetingDialog = () => {
		setOpenScheduleMeetingDialog({ open: false, userInfo: null, type: null });
	};

	const getFilterPayload = () => {
		let industryObj = appliedFilteredValue?.filter((filterItem) => filterItem?.type === "industry");
		let industryArray = industryObj.map((item) => item["checkboxValue"]);

		let statesObj = appliedFilteredValue?.filter((filterItem) => filterItem?.type === "state");
		let statesArray = statesObj.map((item) => item["code"]);

		let productOfferingObj = appliedFilteredValue?.filter((filterItem) => filterItem?.type === "productOffering");
		let productOfferingArray = productOfferingObj.map((item) => item["checkboxValue"]);

		let expertiseObj = appliedFilteredValue?.filter((filterItem) => filterItem?.type === "expertise");
		let expertiseArray = expertiseObj.map((item) => item["checkboxValue"]);

		let currentStageObj = appliedFilteredValue?.filter((filterItem) => filterItem?.type === "currentStage");
		let currentStageArray = currentStageObj.map((item) => item["checkboxValue"]);

		let countriesObj = appliedFilteredValue?.filter((filterItem) => filterItem?.type === "country");
		let countriesArray = countriesObj.map((item) => item["code"]);
		let investmentRangeObj = filteredValue.filter((filterItem) => filterItem?.type === "investmentRange")[0];

		const industries = industryArray.join();
		const productOffering = productOfferingArray.join();
		const expertise = expertiseArray.join();
		const currentStage = currentStageArray.join();

		const states = statesArray.join();
		const countries = countriesArray.join();
		return currentTab === "INVESTOR_USER"
			? {
					...(industries && { industries }),
					...(productOffering && { productOffering }),
					...(expertise && { expertise }),
					...(countries && { country: countries }),
					...(states && { state: states }),
					...(currentStage && { currentStage }),
					...(investmentRangeObj && {
						investmentRangeHigh: investmentRangeObj?.investmentRangeHigh === 1000000 ? null : investmentRangeObj?.investmentRangeHigh,
					}),
					...(investmentRangeObj && { investmentRangeLow: investmentRangeObj?.investmentRangeLow }),
			  }
			: {
					...(industries && { industries }),
					...(productOffering && { productOffering }),
					...(expertise && { expertise }),
					...(countries && { country: countries }),
					...(states && { state: states }),
			  };
	};

	const getNetworkMatchApiCall = async () => {
		try {
			setLoading(true);
			const result = await services.getNetworkMatchApi({
				page: currentPage,
				perPage: 10,
				...(searchedText && { search: searchedText }),
				...(currentTab === "SAVED" || currentTab === "HIDDEN"
					? { collectionType: currentTab }
					: { userTypes: currentTab === USER_TYPES.ALUMNI_USER ? USER_TYPES.STARTUP_OWNER : currentTab }),
				...(appliedFilteredValue?.length > 0 && { ...getFilterPayload() }),
				badges: currentTab === USER_TYPES.ALUMNI_USER ? "Alumni" : null,
				partnerCode: !ability.can("view", "CommunityOther") ? (partnerCode ? partnerCode : "sos") : selectedPartnerCode?.partnerCode,
			});
			const data = result?.data?.data;

			if (data) {
				setTotalPages(data?.totalPages || 0);
				setPageData({
					totalPages: data?.totalPages || 0,
					totalElements: data.totalElements || 0,
					isFirst: data.isFirst,
					isLast: data.isLast,
				});
				setIsFilterChanged(false); //reset value
				const items = data?.models || [];

				let totalMatches = data.totalElements || 0;

				if (!data.isFirst) {
					dispatch(setNetworkingDashboardData({ networkMatches: [...networkMatches, ...items], totalMatches }));
				} else {
					dispatch(setNetworkingDashboardData({ networkMatches: items, totalMatches }));
				}
				setIsLast(data?.isLast || false);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	useEffect(() => {
		getNetworkMatchApiCall();
	}, [currentTab, searchedText, selectedOption]);

	useEffect(() => {
		if (isFilterChanged) {
			getNetworkMatchApiCall();
		}
	}, [appliedFilteredValue, isFilterChanged]);

	useEffect(() => {
		handleMixPanel("Community Viewed (Default View)");
	}, []);

	const handleFilterChange = (newFilteredValue) => {
		dispatch(
			setNetworkingDashboardData({
				openFilterDialog: false,
				appliedFilteredValue: newFilteredValue,
			})
		);
		setCurrentPage(firstPage);
		setIsFilterChanged(true);
	};

	const handleLoadMore = () => {
		if (!isLast) {
			setCurrentPage((prevPage) => prevPage + 1);
			setLoading(true);
			setIsFilterChanged(true);
		}
	};

	const FilterButton = () => (
		<Fab
			sx={{
				ml: "auto",
				position: "fixed",
				right: 20,
				bgcolor: (theme) => theme.palette.primary.main,
				color: (theme) => theme.palette.secondary.white,
				"&:hover": {
					bgcolor: (theme) => theme.palette.primary.dark,
				},
			}}
			aria-label="Edit"
			color="secondary.white"
			onClick={() => {
				dispatch(
					setNetworkingDashboardData({
						openFilterDialog: true,
					})
				);
			}}
		>
			<MoreHorizIcon sx={{ color: (theme) => theme.palette.secondary.white, fontSize: "40px" }} />
		</Fab>
	);

	const handleSearchTextChange = (e) => {
		if (currentTab === USER_TYPES.INVESTOR_USER) {
			setLoading(true); //setting only for proxy investors
		}
		const value = e?.target?.value ?? "";
		setCurrentPage(firstPage);
		dispatch(
			setNetworkingDashboardData({
				searchedText: value,
			})
		);
	};

	const onMessageClick = async (mentorDetails) => {
		// handleMixPanel(`Community: ${getRoleValue(userData?.userTypes && userData?.userTypes[0])} Messages Clicked`, {
		// 	userName: userData.userName,
		// 	userId: userData.userId,
		// });

		dispatch(
			setNetworkingDashboardData({
				messageWithMatch: mentorDetails,
				openMessageDialog: true,
			})
		);
	};

	const onCalendarClick = (selectedUser) => {
		// handleMixPanel(`Community: ${getRoleValue(userData?.userTypes && userData?.userTypes[0])} Schedule Meeting Clicked`, {
		// 	userName: userData.userName,
		// 	userId: userData.userId,
		// 	scheduledName: selectedUser.name,
		// 	scheduledId: selectedUser.id,
		// });

		setOpenScheduleMeetingDialog({ open: true, userInfo: selectedUser, type: selectedUser?.userTypes?.[0] });
	};

	const handleTabChange = (tabName) => {
		const trackTitle =
			tabName === USER_TYPES.MENTOR_USER
				? "Community: Mentors Clicked"
				: tabName === USER_TYPES.INVESTOR_USER
				? "Community: Investors Clicked"
				: tabName === USER_TYPES.STARTUP_OWNER
				? "Community: Founders Clicked"
				: tabName === USER_TYPES.ALUMNI_USER
				? "Community: Alumni Clicked"
				: tabName === USER_TYPES.CORPORATE_USER
				? "Community: Corporate Clicked"
				: "";
		if (trackTitle !== "") {
			handleMixPanel(trackTitle);
		}

		setCurrentPage(firstPage);
		dispatch(
			setNetworkingDashboardData({
				currentTab: tabName,
			})
		);
	};

	const redirectToMatched = (type, userId, matched) => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: matched?.userTypes?.[0] === USER_TYPES.MENTOR_USER ? "MENTOR_PROFILE" : "ACCOUNT_PROFILE",
				profileId: userId,
			})
		);
	};

	const removeMatches = (id) => {
		const filteredNetworkMatches = networkMatches.filter((match) => match.id !== id);
		dispatch(
			setNetworkingDashboardData({
				networkMatches: filteredNetworkMatches,
				hiddenConfirmOpen: false,
				slidingConfirmationObject: {},
				totalMatches: totalMatches - 1 >= 0 ? totalMatches - 1 : 0,
			})
		);
	};

	const onSaveConfirm = async (mentorDetails, isSaved = false) => {
		try {
			const id = mentorDetails?.id;
			await saveOrHideMatchedCollections({ entityIds: [id], collectionType: "SAVED", entityType: "USER", isRemove: isSaved });
			if (currentTab === "SAVED") {
				removeMatches(id);
				return;
			}
			const tempNetworkMatches = [...networkMatches];

			const matchedIndex = tempNetworkMatches.findIndex((match) => match.id === id);
			if (matchedIndex !== -1) {
				tempNetworkMatches[matchedIndex] = { ...tempNetworkMatches[matchedIndex], isSaved: !isSaved };
			}
			dispatch(
				setNetworkingDashboardData({
					networkMatches: tempNetworkMatches,
				})
			);

			if (isSaved) {
				if (mentorDetails?.userTypes?.[0] === USER_TYPES.MENTOR_USER) {
					handleMixPanel(`Unlike a Mentor`, {
						"Mentor Id": mentorDetails?.id,
						"Mentor Email": mentorDetails?.email,
						"Mentor Name": mentorDetails?.name,
					});
				} else if (mentorDetails?.userTypes?.[0] === USER_TYPES.STARTUP_OWNER) {
					handleMixPanel(`Unlike a Founder`, {
						"Founder Id": mentorDetails?.id,
						"Founder Email": mentorDetails?.email,
						"Founder Name": mentorDetails?.name,
					});
				} else if (mentorDetails?.userTypes?.[0] === USER_TYPES.INVESTOR_USER) {
					handleMixPanel(`Unlike an Investor`, {
						"Investor Id": mentorDetails?.id,
						"Investor Email": mentorDetails?.email,
						"Investor Name": mentorDetails?.name,
					});
				} else if (mentorDetails?.userTypes?.[0] === USER_TYPES.CORPORATE_USER) {
					handleMixPanel(`Unlike an Corporate`, {
						"Corporate Id": mentorDetails?.id,
						"Corporate Email": mentorDetails?.email,
						"Corporate Name": mentorDetails?.name,
					});
				}
				// handleMixPanel(`Community: ${getRoleValue(userData?.userTypes && userData?.userTypes[0])} un-liked`, {
				// 	userName: userData.userName,
				// 	userId: userData.userId,
				// });
			} else {
				if (mentorDetails?.userTypes?.[0] === USER_TYPES.MENTOR_USER) {
					handleMixPanel(`Like a Mentor`, {
						"Mentor Id": mentorDetails?.id,
						"Mentor Email": mentorDetails?.email,
						"Mentor Name": mentorDetails?.name,
					});
				} else if (mentorDetails?.userTypes?.[0] === USER_TYPES.STARTUP_OWNER) {
					handleMixPanel(`Like a Founder`, {
						"Founder Id": mentorDetails?.id,
						"Founder Email": mentorDetails?.email,
						"Founder Name": mentorDetails?.name,
					});
				} else if (mentorDetails?.userTypes?.[0] === USER_TYPES.INVESTOR_USER) {
					handleMixPanel(`Like an Investor`, {
						"Investor Id": mentorDetails?.id,
						"Investor Email": mentorDetails?.email,
						"Investor Name": mentorDetails?.name,
					});
				} else if (mentorDetails?.userTypes?.[0] === USER_TYPES.CORPORATE_USER) {
					handleMixPanel(`Like an Coporate`, {
						"Corporate Id": mentorDetails?.id,
						"Corporate Email": mentorDetails?.email,
						"Corporate Name": mentorDetails?.name,
					});
				}
				// handleMixPanel(`Community: ${getRoleValue(userData?.userTypes && userData?.userTypes[0])} liked`, {
				// 	userName: userData.userName,
				// 	userId: userData.userId,
				// });
			}
		} catch (error) {}
	};

	const onHideConfirm = async (id, isHidden = false) => {
		try {
			await saveOrHideMatchedCollections({ entityIds: [id], collectionType: "HIDDEN", entityType: "USER", isRemove: isHidden });
			removeMatches(id);
		} catch (error) {}
	};

	const onHideIconClick = (matched, isHidden = false) => {
		const slidingConfirmationObject = {
			title: "Are you sure?",
			// text: isHidden ? "Removal Confirmation" : "Confirmation",
			subText: isHidden
				? `Please confirm that you would like to unblock ${matched?.name} in your community.`
				: `Please confirm that you would like to block ${matched?.name} in your community.`,
			primaryButtonText: isHidden ? "Unblock" : "Block",
			secondaryButtonText: "Cancel",
			id: matched?.id,
			isHidden,
		};
		dispatch(
			setNetworkingDashboardData({
				hiddenConfirmOpen: true,
				slidingConfirmationObject,
			})
		);
	};
	const handleCloseConnectionLimit = () => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: false,
			})
		);
	};
	const handleConnectionLimitExceeded = () => {
		dispatch(
			setCommonDialogConfirmationData({
				isOpen: true,
				title: "Investor Connection Limit",
				text: "",
				subTitle: `You’ve ran out of investor connections! Please wait until the beginning of the new month to receive your 3 connection requests.`,
				primaryButtonText: "Done",
				primaryButtonAction: () => handleCloseConnectionLimit(),
				primaryButtonVariant: "PurpleRounded",
				primaryButtonLoading: false,
				titleIcon: ALERT_ICON_V1,
			})
		);
	};

	const onReqProxyInvestorConnectClick = async (investor) => {
		try {
			const tempProxyInvestors = [...proxyInvestors];
			const index = tempProxyInvestors.findIndex((proxy) => proxy.id === investor.id);
			const data = {
				founderName: userData?.userName,
				founderId: userData?.userId,
				startupName: userData?.companyName,
				startupId: userData?.companyId,
				investor: { ...investor, isProxyInvestor: index !== -1 },
				isProxyInvestor: index !== -1,
			};
			const res = await requestProxyInvestorConnect(data);
			if (res?.response?.status === 400) {
				handleConnectionLimitExceeded();
				// toastContainer(res?.response?.data?.message, "error");
			} else {
				dispatch(
					setNetworkingDashboardData({
						requestSentOpen: true,
					})
				);

				if (index !== -1) {
					tempProxyInvestors[index] = { ...tempProxyInvestors[index], isRequestSent: true, connectionStatus: "REQUEST_SENT" };
					setProxyInvestors(tempProxyInvestors);
				} else {
					networkMatches.map((match) => {
						if (match?.id === investor?.id) {
							match["connectionStatus"] = "REQUEST_SENT";
						}
						return match;
					});
				}
				handleMixPanel(`Investors Request Connection`, {
					"Investor Id": investor?.id,
					"Investor Email": investor?.email,
					"Investor Name": investor?.name,
					"Proxy Investor": index !== -1,
				});
			}
		} catch (error) {
			console.log(error, "err");
		}
	};

	const matchedName = useMemo(() => get(userTypesName, `${currentTab}`, ""), [currentTab]);

	const filteredProxyInvestors = useMemo(() => {
		if (proxyInvestors?.length > 0) {
			return proxyInvestors.filter((pInvestor) => pInvestor?.name?.toLowerCase().includes(searchedText?.toLowerCase()));
		} else {
			return [];
		}
	}, [searchedText, proxyInvestors]);

	// const displayingProxyInvestors = appliedFilteredValue?.length > 0 ? [] : searchedText ? filteredProxyInvestors : proxyInvestors;

	let totalMatchesCount = totalMatches;

	// if (currentTab === USER_TYPES.INVESTOR_USER) {
	// 	totalMatchesCount += displayingProxyInvestors.length;
	// }
	const selectOPtions = ["StartupOS", "IBM Watson X", "DIFC", "Cincinnati Children's", "1st Citizens"];
	const SwitchViewButtons = () => {
		return (
			<Box flexGrow={1} gap={1} sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
				<Box
					sx={{
						cursor: "pointer",
						height: "20px",
						width: "20px",
						backgroundColor: viewType === "GRID" && "rgba(123, 97, 255, 1)",
						borderRadius: "50%",
						p: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box component={"img"} src={viewType === "GRID" ? WHITE_GRID_ICON_V2 : BLACK_GRID_ICON_V2} alt="grid" onClick={() => setViewType("GRID")} />
				</Box>
				<Box
					sx={{
						cursor: "pointer",
						height: "20px",
						width: "20px",
						backgroundColor: viewType === "LIST" && "rgba(123, 97, 255, 1)",
						borderRadius: "50%",
						p: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box component={"img"} src={viewType === "LIST" ? WHITE_LIST_ICON_V2 : BLACK_LIST_ICON_V2} onClick={() => setViewType("LIST")} alt="list" />
				</Box>
			</Box>
		);
	};

	const ITEM_HEIGHT = 30;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
				borderRadius: "0px 0px 40px 40px",
				boxShadow: "0px 5px 24px rgba(0, 0, 0, 0.15)",
			},
		},
	};
	const handleChange = (event) => {
		dispatch(setCommunityPartners({ selectedOption: event?.target?.value }));
		setSelectedSort(event?.target.value);
	};

	const handleMenuOpen = (value) => {
		setMenuOpen(value);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>StartupOS - Community</title>
			</Helmet>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					// pb: "20px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px", // Figma specifies max content width
					margin: "0 auto",
				}}
			>
				<Box display="flex" alignItems="center">
					<Box component={"img"} src={PAGE_COMMUNITY_ICON} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />
					<Typography ml={1.5} variant="Text/lg/Semibold">
						{isSmallScreen ? "Community" : "StartupOS Community"}
					</Typography>
					{showCommunityPartners && (
						<Box width={"180px"} ml={2}>
							<UpdatedSelectInput
								onInputChange={handleChange}
								MenuProps={MenuProps}
								selectedValue={selectedSort}
								handleMenuOpen={handleMenuOpen}
								handleMenuClose={handleMenuOpen}
								wrapperstyle={{
									"& .MuiInputBase-root": {
										height: "auto",
										boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.05)",
										borderRadius: menuOpen ? "40px 40px 0px 0px" : "40px",
										fontSize: "16px",
										fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
										background: (theme) => theme.palette.secondary.white,
										width: "216px",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"& .MuiOutlinedInput-input": { pl: 3 },
									width: 1,
								}}
								renderValue={(selected) => {
									console.log(`selected`, selected);
									if (!selected?.length > 0) {
										return (
											<Box sx={{ my: 0, py: 0 }} display={"flex"}>
												<Typography
													variant="poppinsMedium16"
													sx={{
														lineHeight: "19px",
														textOverflow: "ellipsis",
														whiteSpace: "nowrap",
														overflow: "hidden",
													}}
													color="secondary.black"
												>
													Select Community
												</Typography>
											</Box>
										);
									}

									return (
										<Box sx={{ display: "flex" }} alignItems="center">
											<Typography
												variant="poppinsMedium16"
												sx={{
													lineHeight: "19px",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
												}}
												color="secondary.black"
											>
												{selected}
											</Typography>{" "}
										</Box>
									);
								}}
								inputProps={{ "aria-label": "Without label" }}
							>
								<MenuItem
									display={"flex"}
									alignItems="center"
									key={"sos_default"}
									value={""}
									sx={{
										py: 1,
										px: 2,
										"&.Mui-selected": {
											backgroundColor: "rgba(106, 117, 117, 0.08)",
										},
									}}
									onClick={() => {}}
								>
									<ListItemText sx={{ ml: 3 }} primary={<Typography variant="custom070">StartupOS Community</Typography>} />{" "}
								</MenuItem>
								{communityPartnersOptions?.length > 0 &&
									communityPartnersOptions?.map((option) => (
										<MenuItem
											display={"flex"}
											alignItems="center"
											key={option}
											value={option}
											sx={{
												py: 1,
												px: 2,
												"&.Mui-selected": {
													backgroundColor: "rgba(106, 117, 117, 0.08)",
												},
											}}
											onClick={() => {}}
										>
											<ListItemText sx={{ ml: 3 }} primary={<Typography variant="custom070">{option}</Typography>} />{" "}
										</MenuItem>
									))}
							</UpdatedSelectInput>
						</Box>
					)}

					<FilterButton />
				</Box>
				<Box display="flex" alignItems="center" flexWrap="wrap" gap={{ xs: 2, sm: 3 }} justifyContent="space-between">
					<NetworkingTab currentTab={currentTab} handleTabChange={handleTabChange} />

					<Box sx={{ width: { xs: "100%", sm: "480px" } }}>
						<NetworkingSerchField
							defaultValue={searchedText ? searchedText : ""}
							fullWidth
							onChange={debounce(handleSearchTextChange, 700)}
							placeholder="Search By Name"
							InputProps={{
								endAdornment: (
									<Box display="flex" gap={1}>
										<Box component={"img"} src={UPDATED_SEARCH_ICON} alt="search" sx={{ width: "15px", height: "15px" }} />
									</Box>
								),
							}}
						/>
					</Box>
				</Box>
				<Box>
					<Collapse in={appliedFilteredValue?.length > 0}>
						<FilteredValueCarosualComponent
							filteredValue={appliedFilteredValue}
							handleDelete={(checkedValue) => {
								const finalFilteredList = appliedFilteredValue?.filter((item) => item?.checkboxValue !== checkedValue);
								handleFilterChange(finalFilteredList);
								dispatch(
									setNetworkingDashboardData({
										filteredValue: finalFilteredList,
									})
								);
							}}
							filterId={"networkingAppliedFilteredId"}
						/>
					</Collapse>
				</Box>

				<Box>
					<Box mb={3} display="flex" gap={2} alignItems="center" flexWrap="wrap" justifyContent="space-between">
						<Box display="flex" gap={1} alignItems="center">
							<Typography variant="Text/sm/Regular">
								{matchedName === "Saved" || matchedName === "Blocked" ? matchedName : `${matchedName} Community`}
							</Typography>
							{!(loading && currentPage === 1) && <Typography variant="Text/sm/Regular">{`[${totalMatchesCount}]`}</Typography>}
						</Box>
						{currentTab === USER_TYPES.MENTOR_USER && <FindMeButton handleFilterChange={handleFilterChange} />}

						{currentTab === USER_TYPES.INVESTOR_USER && <SwitchViewButtons />}
					</Box>
					{loading && currentPage === 1 ? (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								mt: 10,
								width: "inherit",
							}}
						>
							<CircularProgress
								sx={(theme) => ({
									height: "80px",
									width: "80px",
									color: theme.palette.primary.primaryPurple,
								})}
							/>
						</Box>
					) : networkMatches?.length === 0 && currentTab !== USER_TYPES.INVESTOR_USER ? (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								mt: 10,
								width: "inherit",
							}}
						>
							<Typography variant="Text/sm/Semibold">No records found</Typography>
						</Box>
					) : viewType === "GRID" ? (
						<Box sx={{ height: "calc(100vh - 280px)", overflowX: "hidden" }}>
							<InfiniteScroll
								dataLength={networkMatches?.length}
								next={handleLoadMore}
								hasMore={!isLast && !loading}
								height={"calc(100vh - 280px)"}
								style={{
									overflowX: "hidden",
									display: "flex",
									flexDirection: "column",
									gap: "24px",
								}}
								loader={null}
								endMessage={
									!loading &&
									isLast && (
										<Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
											<Typography variant="Text/sm/Regular">No more records</Typography>
										</Box>
									)
								}
							>
								<Box>
									<Grid container rowSpacing={6} columnSpacing={4} pt={2} pb={3}>
										{networkMatches &&
											networkMatches?.map((matched, index) => (
												<Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={3}>
													<NetworkingCard
														key={matched.id}
														matched={matched}
														onSaveIconClick={onSaveConfirm}
														onMessageClick={onMessageClick}
														onCalendarClick={() => onCalendarClick(matched)}
														redirectToMatched={redirectToMatched}
														currentTab={currentTab}
														onHideIconClick={onHideIconClick}
														onReqProxyInvestorConnectClick={onReqProxyInvestorConnectClick}
														setShowPlanModal={setShowPlanModal}
													/>
												</Grid>
											))}
									</Grid>
								</Box>
							</InfiniteScroll>
							{loading && currentPage !== 1 && viewType === "GRID" && (
								<Box display={"flex"} justifyContent="center" sx={{ my: "4px" }}>
									<CircularProgress sx={{ height: "10px", width: "10px", color: (theme) => theme.palette.primary.primaryPurple }} />
								</Box>
							)}
						</Box>
					) : (
						<Box>
							<CommunityTable
								rows={networkMatches}
								onReqProxyInvestorConnectClick={onReqProxyInvestorConnectClick}
								redirectToMatched={redirectToMatched}
								currentTab={currentTab}
								paginationLoader={loading && currentPage !== 1}
								setShowPlanModal={setShowPlanModal}
								networkMatches={networkMatches}
								handleLoadMore={handleLoadMore}
								isLast={isLast}
								loading={loading}
								currentPage={currentPage}
								viewType={viewType}
							/>
						</Box>
					)}
				</Box>
				<FilterDialog handleFilterChange={handleFilterChange} matchedName={matchedName} totalMatchesCount={totalMatchesCount} />
				{openScheduleMeetingDialog?.open && openScheduleMeetingDialog?.userInfo && (
					<ScheduleMeetingDialog
						open={openScheduleMeetingDialog?.open}
						title="Community"
						handleClose={handleCloseScheduleMeetingDialog}
						userInfo={openScheduleMeetingDialog?.userInfo}
						withUserType={openScheduleMeetingDialog?.type}
					/>
				)}
				<ConfirmationDialog onHideConfirm={onHideConfirm} />
				{showPlanModal && <PaymentPlanDialog open={showPlanModal} isRedirect={false} onClose={handleClosePlanModal} />}
				<RequestSentDialog />
				<UpdatedDialogConfirmation />
				<IndustryDetailsDialog />
			</Box>
		</>
	);
}

export default Networking;
