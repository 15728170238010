import React, { memo, useEffect, useState } from "react";
import { Box, Button, Typography, Tooltip, Chip, Skeleton, Stack, IconButton } from "@mui/material";
import { StartupCardComp } from "./styles";
import {
	UPDATED_PORTFOLIO_ICON,
	UPDATED_WATCHLIST_ICON,
	ROCKET_ICON_V3,
	LOCATION_ICON_V3,
	CORRECT_ICON_V2,
	WRONG_ICON_V2,
	PRODUCT_STATE_ICON_V2,
	BUSINESS_MODEL_ICON_V3,
	BUILDING_ICON_V3,
	DOLLAR_ICON_V2,
	CALCULATION_ICON_V2,
	CURRENECY_ICON_V2,
	REVENUE_MODEL_ICON_V3,
	START_ICON_V3,
	GRID_BLACK_ICON_V1,
	HISTORY_OF_SUCCESS_LIST,
	FOUNDING_TEAM_EXP_LIST,
	FOUNDING_TEAM_SKILLS_LIST,
	CUBE_ICON_V2,
	CALEDNER_ICON_V2,
	MESSAGE_ICON_V2,
	NEW_LINKEDIN_ICON,
	ARROW_UP_RIGHT_ICON_V1,
	GREY_USER_ICON,
} from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setInvestorDashboardData } from "../../../../modules/actions";
import { ArrowForwardOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { UploadLogo } from "../../../../components/Profile/style";
import { generatePath, useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";
import { addOrRemoveCompanyToPortfolioOrWatchlist, addOrRemoveCompanyToTrackSection } from "../Functions/common";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import SemiCircleProgress from "../../../../common/SemiCircleProgress";
import StageStepper from "../../../../common/StageStepper";
import { formatUrl, parseStringArray } from "../../../../utils/commonFunctions";
import UpdatedProfileWatchlistSection from "../../../../components/Investor/UpdatedProfileWatchlistSection";
import ArrowButtonSection from "../../../../components/Investor/ArrowButtonSection";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getConversationWithID } from "../../../../utils/messaging-helper";
import { updateCurrentConversation } from "../../../Messaging/store/action-creators";
import { setNetworkingDashboardData } from "../../../../modules/actions/networkingActions";
import { USER_TYPES } from "../../../../utils/userTypes";
import MessageDialog from "../../../../components/Networking/MessageDialog";
import ScheduleMeetingDialog from "../../../../components/Networking/ScheduleMeetingDialog";
import LanguageIcon from "@mui/icons-material/Language";
import AreYouEnterpriseDialog from "./AreYouEnterpriseDialog";
import services from "../../../../services";
import InfoHelper from "../../../../components/Founder/InfoHelper";
import { Can } from "../../../../services/abilities/context";

function GridView({ startup, companiesPageIndex, setCompaniesPageIndex, colCount, isOpportunityDashboard = true, isPublicProfile = true }) {
	const investorDashboardData = useSelector((state) => state?.investorDashboard);
	const { isAdmin, isInvestorLocked, partnerCode, userType } = useSelector((store) => store?.auth);
	const COMPANY_ID = useSelector((store) => store?.auth?.companyId);

	const { profileObj } = useSelector((state) => state?.slidingProfile);
	const [openIndicatorsDialog, setOpenIndicatorsDialog] = useState(false);
	const dispatch = useDispatch();
	const [col, setCol] = useState(colCount);
	const [historySuccessList, setHistorySuccessList] = useState({});
	const [foundingTeamList, setFoundingTeamList] = useState({});
	const [foundingSkillList, setFoundingSkillList] = useState({});
	const [openScheduleMeetingDialog, setOpenScheduleMeetingDialog] = useState({ open: false, userInfo: null, type: null });
	const [openMessagingDialog, setOpenMessagingDialog] = useState(false);
	const [openAreYouEnterpriseDialog, setOpenAreYouEnterpriseDialog] = useState(false);
	const [enterpriseScore, setEnterpriseScore] = useState(startup?.enterpriseReadyScore);

	const history = useHistory();
	useEffect(() => {
		setCol(colCount);
	}, [colCount]);

	useEffect(() => {
		if (startup) initLeadingIndicators(startup);
	}, []);
	useEffect(() => {
		if (!startup?.enterpriseReadyScore && !isOpportunityDashboard) {
			enterpriseReadyScoreCalculationApi();
		}
	}, [startup]);

	useEffect(() => {
		if (profileObj) initLeadingIndicators(profileObj);
	}, [profileObj]);

	const enterpriseReadyScoreCalculationApi = async () => {
		const companyTagObj = startup?.companyTags.reduce((acc, item) => {
			acc[item.scoreTag.id] = true;
			return acc;
		}, {});
		const startupEnterpriseData = startup?.enterpriseReadyJson && JSON.parse(startup?.enterpriseReadyJson);

		const payloadData = {
			financials: {
				"current-runway": startupEnterpriseData?.["current-runway"],
				"monthly-revenue": startup?.monthlyRevenue,
				"capital-raised": startup?.capitalRaised,
				"team-size": startup?.companySize,
			},
			risk: {
				"strategic-plan": startupEnterpriseData?.["strategic-plan"],
				"strategic-partnership": startupEnterpriseData?.["strategic-partnerships"],
				has_patent: startupEnterpriseData?.["patent"],
				"strategy-for-leverging-product": startupEnterpriseData?.["strategy-for-leveraging"],
				"startup-own-cash": startupEnterpriseData?.["startup-own-cash"],
				"liens-against-startup": startupEnterpriseData?.["any-litigation"],
				"legal-liabilities": startupEnterpriseData?.["legal-liabilities"],
			},
			"product-service": {
				"is-your-startup-scalable": startupEnterpriseData?.["product-offering-scale"],
				"startup-offers-unqiue-value": startupEnterpriseData?.["unique-value-proposition"],
				"competitive-landscape": startupEnterpriseData?.["competitive-landscape"],
				"roadmap-for-development": startupEnterpriseData?.["roadmap"],
				"process-for-customer-feedback": startupEnterpriseData?.["process-for-soliciting"],
				"process-for-customer-support": startupEnterpriseData?.["support-customers"],
				"flagship-customer": startupEnterpriseData?.["flagship-customer"],
				"pricing-strategy": startupEnterpriseData?.["proven-pricing-strategy"],
				"sales-marketing-strategy": startupEnterpriseData?.["proven-sales"],
				"success-metrics-sales-marketing": startupEnterpriseData?.["measure-the-success"],
				"endorsements-testimonials": startupEnterpriseData?.["endorsements"],
			},
			"business-operations": {
				"business-meet-compilance-requirement": startupEnterpriseData?.["business-meet-operational"],
				"policies-for-regulatory-compliance": startupEnterpriseData?.["clear-policies-for-regulatory"],
				"procurement-contracts": startupEnterpriseData?.["ready-for-procurement"],
				"attract-retain-talent": startupEnterpriseData?.["attract-and-retain-talent"],
				"hr-policies": startupEnterpriseData?.["clear-HR-policies"],
				"policies-environmental-sustainability": startupEnterpriseData?.["clear-environmental-sustainability-policies"],
				"social-responsibility-initiatives": startupEnterpriseData?.["social-responsibility-initiatives"],
			},
			"software-platform":
				startupEnterpriseData?.["depend-on-a-Web"] === "Yes"
					? {
							"depend-on-saas-platform": startupEnterpriseData?.["depend-on-a-Web"],
							"soc-compliant": startupEnterpriseData?.["SOC-2-compliant"],
							"meet-compliance-requirements": startupEnterpriseData?.["platform-meet-compliance"],
							"technology-infrastructure": startupEnterpriseData?.["technology-infrastructure-scale"],
							"system-monitoring-alets": startupEnterpriseData?.["system-monitoring"],
							"data-analytics-reporting": startupEnterpriseData?.["robust-data-analytics"],
							"disaster-recovery-plan": startupEnterpriseData?.["disaster-recovery-plan"],
							"technological-upgrade": startupEnterpriseData?.["technological-upgrades"],
					  }
					: {
							"depend-on-saas-platform": startupEnterpriseData?.["depend-on-a-Web"],
					  },
			hardware:
				startupEnterpriseData?.["hardware-product"] === "Yes"
					? {
							"have-hardware-product": startupEnterpriseData?.["hardware-product"],
							"deliver-hardware": startupEnterpriseData?.["deliver-the-hardware"],
							"maintain-hardware": startupEnterpriseData?.["maintain-the-hardware"],
							"hardware-returned": startupEnterpriseData?.["hardware-returned"],
					  }
					: {
							"have-hardware-product": startupEnterpriseData?.["hardware-product"],
					  },
			"startup-credentials": companyTagObj,
		};
		return services
			.enterpriseReadyScoreApi(payloadData)
			.then((response) => {
				if (response.data.message === "Success") {
					setEnterpriseScore(response?.data?.data?.totalScore);
				}
			})
			.catch((e) => {
				// setScoreLoader(false);
				return "error";
			});
	};

	const initLeadingIndicators = (startupObj) => {
		const tempHistorySuccessList = [];
		HISTORY_OF_SUCCESS_LIST?.forEach((successItem) => {
			if (startupObj?.companyTags?.find((item) => String(item?.scoreTag?.id) === String(successItem?.id))) {
				tempHistorySuccessList.push(successItem);
			}
		});
		setHistorySuccessList(tempHistorySuccessList);

		const tempFoundingTeamList = [];
		FOUNDING_TEAM_EXP_LIST?.forEach((foundingItem) => {
			if (startupObj?.companyTags?.find((item) => String(item?.scoreTag?.id) === String(foundingItem?.id))) {
				tempFoundingTeamList.push(foundingItem);
			}
		});
		setFoundingTeamList(tempFoundingTeamList);

		const tempFoundingSkillList = [];
		FOUNDING_TEAM_SKILLS_LIST?.forEach((skillItem) => {
			if (startupObj?.companyTags?.find((item) => String(item?.scoreTag?.id) === String(skillItem?.id))) {
				tempFoundingSkillList.push(skillItem);
			}
		});
		setFoundingSkillList(tempFoundingSkillList);
	};

	const CompanyAskItem = ({ icon, label, value }) => {
		return (
			<Box
				gap={1}
				sx={{
					display: "flex",
					alignItems: "center",
					maxWidth: "330px",
				}}
			>
				<Box component={"img"} src={icon} sx={{ height: "25px", width: "25px" }} alt="location" />
				<Typography variant="Text/sm/Regular" display={"block"} sx={{ color: "rgba(0, 0, 0, 1)" }}>
					{`${label}: ${value ? value : "-"} `}
				</Typography>
			</Box>
		);
	};

	const toggleOpenIndustryDetails = () => {
		dispatch(
			setInvestorDashboardData({
				openStartupObj: startup,
				industryDetailsOpen: true,
			})
		);
	};

	const addCompanyToWatchlistOrPortfolioApiCall = (companyId, type) => {
		addOrRemoveCompanyToPortfolioOrWatchlist({ entityIds: [companyId], collectionType: type, isRemove: false })
			.then((result) => {
				const tempStartupList = investorDashboardData?.startupList;
				tempStartupList.map((startupItem) => {
					if (startupItem.id === startup.id) {
						if (type === "PORTFOLIO") {
							startupItem.inPortfolio = true;
						} else {
							startupItem.inWatchlist = true;
						}
					}
				});
				dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
			})
			.catch((err) => console.log(err));
	};

	const addCompanyTrackApiCall = (companyId) => {
		addOrRemoveCompanyToTrackSection({ trackedCompanyIds: [companyId], isRemove: false })
			.then((result) => {
				const tempStartupList = investorDashboardData?.startupList;
				tempStartupList.map((startupItem) => {
					if (startupItem.id === startup.id) startupItem.inTracked = true;
				});
				dispatch(setInvestorDashboardData({ startupList: [...tempStartupList] }));
			})
			.catch((err) => console.log(err));
	};
	const handlePortfolioAndWatchlistDrawerActions = (type) => {
		if (type === "PORTFOLIO") {
			if (!startup?.inPortfolio) {
				addCompanyToWatchlistOrPortfolioApiCall(startup?.id, "PORTFOLIO");
			} else {
				let portfolioObj = {
					title: "My Portfolio",
					text: startup?.inPortfolio ? "Removal Confirmation" : "Confirmation",
					subText: startup?.inPortfolio
						? "Please confirm that you are removing this startup from your portfolio."
						: "Please confirm that you have invested in this startup, and would like to add it to you portfolio.",
					primaryButtonText: startup?.inPortfolio ? "Remove" : "Add",
					secondaryButtonText: "Cancel",
					type: type,
				};
				dispatch(
					setInvestorDashboardData({
						slidingConfirmationObject: portfolioObj,
						openStartupObj: startup,
						removeMyPortfolioOpen: startup?.inPortfolio,
						addMyPortfolioOpen: !startup?.inPortfolio,
					})
				);
			}
		} else {
			if (!startup?.inWatchlist) {
				addCompanyToWatchlistOrPortfolioApiCall(startup?.id, "WATCHLIST");
			} else {
				let watchlistObj = {
					title: "My Watchlist",
					text: startup?.inWatchlist ? "Removal Confirmation" : "Confirmation",
					subText: startup?.inWatchlist
						? "Please confirm that you are removing this startup from your watchlist."
						: "Please confirm that you have invested in this startup, and would like to add it to you watchlist.",
					primaryButtonText: startup?.inWatchlist ? "Remove" : "Add",
					secondaryButtonText: "Cancel",
					type: type,
				};
				dispatch(
					setInvestorDashboardData({
						slidingConfirmationObject: watchlistObj,
						openStartupObj: startup,
						removeWatchlistOpen: startup?.inWatchlist,
						addWatchlistOpen: !startup?.inWatchlist,
					})
				);
			}
		}
	};

	const handleTrackDrawerActions = (type) => {
		if (!startup?.inTracked) {
			addCompanyTrackApiCall(startup?.id);
		} else {
			let trackObj = {
				title: "Remove from Track",
				text: startup?.inTracked ? "Removal Confirmation" : "Confirmation",
				subText: startup?.inTracked
					? "Please confirm that you are removing this startup from Track Section."
					: "Please confirm that you would like to add it to you Track.",
				primaryButtonText: startup?.inTracked ? "Remove" : "Add",
				secondaryButtonText: "Cancel",
				type: type,
			};
			dispatch(
				setInvestorDashboardData({
					slidingConfirmationObject: trackObj,
					openStartupObj: startup,
					removeMyPortfolioOpen: startup?.inTracked,
					addMyPortfolioOpen: !startup?.inTracked,
				})
			);
		}
	};

	const getSelectedIndustryFormatted = (input) => {
		if (input) {
			try {
				let inputParsed = JSON.parse(input);
				if (Array.isArray(inputParsed)) {
					return inputParsed;
				} else {
					return [inputParsed];
				}
			} catch (error) {
				return [];
			}
		} else {
			return [];
		}
	};

	const useStyles = makeStyles((theme) => ({
		gridContainer: {
			display: "grid",

			...(col === 3 && {
				gridTemplateColumns: "repeat(3, 1fr)",
				gridTemplateRows: "auto",
			}),
			...(col === 2 && {
				gridTemplateColumns: "repeat(2, 1fr)",
				gridTemplateRows: "repeat(2, auto)",
				rowGap: "40px",
			}),
			...(col === 1 && {
				gridTemplateColumns: "1fr",
				gridTemplateRows: "repeat(3, auto)",
				rowGap: "40px",
			}),
		},

		gridItemA: {
			...(col === 3 && {}),
			...(col === 2 && {
				gridArea: "1 / 1 / 2 / 3",
				paddingBottom: "40px",
				borderBottom: "1px solid #CCCCCC",
			}),
			...(col === 1 && {
				gridArea: "1 / 1 / 2 / 3",
				paddingBottom: "40px",
				borderBottom: "1px solid #CCCCCC",
			}),
		},
		gridItemB: {
			...(col === 3 && {
				borderRight: "1px solid #CCCCCC",
				borderLeft: "1px solid #CCCCCC",
				padding: "0px 20px",
				margin: "0px 20px",
			}),
			...(col === 2 && {
				gridArea: " 2 / 1 / 3 / 2",
				borderRight: "1px solid #CCCCCC",
				margin: "0px 40px 0 0",
			}),
			...(col === 1 && {
				gridArea: " 2 / 1 / 3 / 2",
				paddingBottom: "40px",
				borderBottom: "1px solid #CCCCCC",
			}),
		},
		gridItemC: {
			display: "flex",
			...(col === 3 && {
				justifyContent: "center",
				// justifyContent: "flex-end",
			}),
			...(col === 2 && {
				gridArea: "2 / 2 / 3 / 3",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}),
			...(col === 1 && {
				gridArea: "3 / 1 / 4 / 2",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}),
		},
	}));

	const classes = useStyles();
	const redirectToStartup = () => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "STARTUP_PROFILE",
				profileId: startup?.id,
				profileObj: startup,
			})
		);
	};

	const redirectToAccountProfile = () => {
		if (!isOpportunityDashboard && isPublicProfile) {
			dispatch(
				setSlidingProfileData({
					open: false,
					profileObj: null,
				})
			);
			history.push({
				pathname: generatePath(ROUTES_PATH.ACCOUNT_PROFILE, {
					id: startup?.founderDetails?.id,
				}),
			});
		} else {
			dispatch(
				setSlidingProfileData({
					open: true,
					profileType: "ACCOUNT_PROFILE",
					profileId: startup?.founderDetails?.id,
					profileObj: null,
				})
			);
		}
	};
	const handleOpenIndicatorsDialog = () => {
		setOpenIndicatorsDialog(true);
	};
	const handleCloseIndicatorsDialog = () => {
		setOpenIndicatorsDialog(false);
	};

	const isHotStartup = () => {
		let hotStartupCount = 0;
		if (startup?.incubator !== null && startup?.incubator !== "none") {
			hotStartupCount++;
		}
		if (startup?.companyTags?.find((item) => item?.scoreTag?.id == 6)) {
			hotStartupCount++;
		}
		if (startup?.companyTags?.find((item) => item?.scoreTag?.id == 11)) {
			hotStartupCount++;
		}
		if (startup?.companyTags?.find((item) => item?.scoreTag?.id == 5)) {
			hotStartupCount++;
		}

		return hotStartupCount >= 2;
	};
	const CompanyBadging = () => {
		return (
			<Box>
				{isOpportunityDashboard
					? isAdmin
						? startup?.isPremiumPlanAccess && (
								<Chip
									label={
										<Typography variant="Text/xs/Regular" sx={{ color: "rgba(0, 206, 126, 1)" }}>
											Premium
										</Typography>
									}
									variant="outlined"
									sx={{ border: "1px solid rgba(0, 206, 126, 1)" }}
								/>
						  )
						: isHotStartup() && (
								<Chip
									label={
										<Typography variant="Text/xs/Regular" sx={{ color: "rgba(240, 68, 56, 1)" }}>
											Hot Startup
										</Typography>
									}
									variant="outlined"
									sx={{ border: "1px solid rgba(240, 68, 56, 1)" }}
								/>
						  )
					: isHotStartup() && (
							<Chip
								label={
									<Typography variant="Text/xs/Regular" sx={{ color: "rgba(240, 68, 56, 1)" }}>
										Hot Startup
									</Typography>
								}
								variant="outlined"
								sx={{ border: "1px solid rgba(240, 68, 56, 1)" }}
							/>
					  )}
				{isAdmin && startup?.companySource && (
					<Tooltip title={startup?.companySource === "PUBLIC_FUNDABLE" ? "Public Get Funded" : "Harmonic"} arrow>
						<Chip
							label={
								<Typography variant="Text/xs/Regular" sx={(theme) => ({ color: theme.palette.secondary.blue500 })}>
									Farm
								</Typography>
							}
							variant="outlined"
							sx={(theme) => ({ border: `1px solid ${theme.palette.secondary.blue500}`, cursor: "pointer" })}
						/>
					</Tooltip>
				)}

				{/* <Box display={"flex"} gap={1}>
					{isAdmin && startup?.isPremiumPlanAccess && (
						<Chip
							label={
								<Typography variant="Text/xs/Regular" sx={{ color: "rgba(0, 206, 126, 1)" }}>
									Premium
								</Typography>
							}
							variant="outlined"
							sx={{ border: "1px solid rgba(0, 206, 126, 1)" }}
						/>
					)}
					{isHotStartup() && (
						<Chip
							label={
								<Typography variant="Text/xs/Regular" sx={{ color: "rgba(240, 68, 56, 1)" }}>
									Hot Startup
								</Typography>
							}
							variant="outlined"
							sx={{ border: "1px solid rgba(240, 68, 56, 1)" }}
						/>
					)}
				</Box> */}
			</Box>
		);
	};
	const onCalendarClick = () => {
		setOpenScheduleMeetingDialog({
			open: true,
			userInfo: startup?.founderDetails,
			type: USER_TYPES.STARTUP_OWNER,
		});
	};

	const onMessageClick = async () => {
		try {
			let conversationID = await getConversationWithID({
				idArray: [startup?.founderDetails?.id],
			});
			setOpenMessagingDialog(true);

			dispatch(updateCurrentConversation(conversationID));
			dispatch(
				setNetworkingDashboardData({
					openMessageDialog: true,
				})
			);
		} catch (e) {
			console.log("Could not retrieve conversation.", e);
		}
	};

	const handleViewStartupGrid = () => {
		if (startup?.id == COMPANY_ID) {
			history.push(ROUTES_PATH.STARTUPGRID);
		} else {
			history.push({
				pathname: generatePath(ROUTES_PATH.STARTUPGRID_BY_ID, {
					id: startup?.id,
				}),
				state: {
					startupDetails: startup,
				},
			});
		}
	};

	const handleDownloadPitchDeck = (fileUrl) => {
		fetch(fileUrl)
			.then((response) => response.blob())
			.then((blob) => {
				const fileType = fileUrl?.split(".")?.pop();
				const url = window.URL.createObjectURL(new Blob([blob]));
				const a = document.createElement("a");
				a.href = url;
				a.download = `pitch_deck.${fileType}`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			})
			.catch((error) => console.error("Error downloading file:", error));
	};

	const StartupCard = () => {
		return (
			<StartupCardComp boltRecommended={startup?.boltRecommended || false}>
				{startup?.boltRecommended && (
					<Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 3 }}>
						<Box
							sx={{
								borderRadius: "100px",
								background: "#F2F4F7",
								boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
								display: "flex",
								gap: 1,
								alignItems: "center",
								padding: "8px 14px",
							}}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" fill="none">
								<path
									d="M5.99984 29.3334V22.6667M5.99984 9.33341V2.66675M2.6665 6.00008H9.33317M2.6665 26.0001H9.33317M17.3332 4.00008L15.0209 10.0119C14.6449 10.9895 14.4569 11.4784 14.1645 11.8895C13.9054 12.2539 13.587 12.5723 13.2226 12.8315C12.8114 13.1238 12.3226 13.3118 11.345 13.6878L5.33317 16.0001L11.345 18.3123C12.3226 18.6883 12.8114 18.8763 13.2226 19.1687C13.587 19.4278 13.9054 19.7462 14.1645 20.1106C14.4569 20.5218 14.6449 21.0106 15.0209 21.9883L17.3332 28.0001L19.6454 21.9883C20.0214 21.0106 20.2094 20.5218 20.5018 20.1106C20.7609 19.7462 21.0793 19.4278 21.4437 19.1687C21.8549 18.8763 22.3437 18.6883 23.3214 18.3123L29.3332 16.0001L23.3214 13.6878C22.3437 13.3118 21.8549 13.1238 21.4437 12.8315C21.0793 12.5723 20.7609 12.2539 20.5018 11.8895C20.2094 11.4784 20.0214 10.9895 19.6454 10.0119L17.3332 4.00008Z"
									stroke="#7B61FF"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Typography variant="Text/sm/Semibold">Bolt AI Recommendation</Typography>
						</Box>
						<InfoHelper
							title={"Bolt AI Recommended Startup"}
							body={"Bolt AI is recommending this startup based on others included in your watchlist."}
						/>
					</Box>
				)}
				<Box className={classes.gridContainer}>
					<Box className={classes.gridItemA}>
						<Box>
							<Box display={"flex"} alignItems="center" gap={1}>
								<Box sx={{ cursor: "pointer" }} onClick={isOpportunityDashboard && redirectToStartup}>
									{startup?.logoUrl ? (
										<Box
											component={"img"}
											sx={{ height: "64px", width: "64px", borderRadius: "50%" }}
											src={startup?.logoUrl}
											alt="startup"
										/>
									) : (
										<UploadLogo borderWidth="4px" variant="poppinsSemiBold25" id={startup?.companyName} width={64} height={64}>
											{startup?.companyName?.charAt(0)?.toUpperCase()}
										</UploadLogo>
									)}
								</Box>
								<Box sx={{ cursor: "pointer" }} onClick={isOpportunityDashboard && redirectToStartup}>
									<Typography display={"block"} variant="Text/md/Semibold" sx={{ maxWidth: "200px" }} color="secondary.black">
										{startup?.companyName}
									</Typography>
								</Box>
								<Box>
									<CompanyBadging />
								</Box>
								{/* Like, Meetings, Messaging */}
								<Stack direction="row" spacing={1} sx={{ ml: "auto" }}>
									{!isOpportunityDashboard && isPublicProfile && (
										<>
											<IconButton
												sx={{
													backgroundColor: "rgba(242, 244, 247, 1)",
													height: "35px",
													width: "35px",
													padding: 1,
													borderRadius: "50%",
												}}
												onClick={onCalendarClick}
											>
												{" "}
												<Box
													component={"img"}
													src={CALEDNER_ICON_V2}
													alt="message"
													sx={{
														height: "14px",
														width: "14px",
														cursor: "pointer",
													}}
												/>
											</IconButton>
											<IconButton
												sx={{
													backgroundColor: "rgba(242, 244, 247, 1)",
													padding: 1,
													borderRadius: "50%",
													height: "35px",
													width: "35px",
												}}
												onClick={onMessageClick}
											>
												{" "}
												<Box
													component={"img"}
													src={MESSAGE_ICON_V2}
													alt="message"
													sx={{
														height: "14px",
														width: "14px",
														cursor: "pointer",
													}}
												/>
											</IconButton>
										</>
									)}
									{!isOpportunityDashboard && (
										<>
											{startup?.websiteLink && (
												<IconButton
													sx={{
														backgroundColor: "rgba(242, 244, 247, 1)",
														padding: 1,
														borderRadius: "50%",
														height: "35px",
														width: "35px",
													}}
												>
													<LanguageIcon
														sx={{ fontSize: "16px" }}
														onClick={() => window.open(formatUrl(startup?.websiteLink), "_blank")}
													/>
												</IconButton>
											)}

											{startup?.linkedInUrl && (
												<IconButton
													sx={{
														backgroundColor: "rgba(242, 244, 247, 1)",
														padding: 1,
														borderRadius: "50%",
														height: "35px",
														width: "35px",
													}}
												>
													<Box
														component={"img"}
														src={NEW_LINKEDIN_ICON}
														onClick={() => window.open(formatUrl(startup?.linkedInUrl), "_blank")}
														alt="message"
														sx={{
															height: "15px",
															width: "15px",
															cursor: "pointer",
														}}
													/>
												</IconButton>
											)}
										</>
									)}
								</Stack>
							</Box>
							{startup?.founderDetails?.id && (
								<Tooltip
									title={
										<Typography
											onClick={redirectToAccountProfile}
											variant="Text/xs/Regular"
											sx={(theme) => ({ cursor: "pointer", color: theme.palette.secondary.white })}
										>
											View founder profile
										</Typography>
									}
								>
									<Box sx={{ my: 2, cursor: "pointer" }} display={"flex"} alignItems={"center"} gap={1} onClick={redirectToAccountProfile}>
										<Box component={"img"} sx={{ height: "20px", width: "20px" }} src={GREY_USER_ICON} />
										<Typography variant="Text/sm/Regular">{startup?.founderDetails?.name}</Typography>
										<Box component={"img"} sx={{ height: "25px", width: "25px" }} src={ARROW_UP_RIGHT_ICON_V1} />
									</Box>
								</Tooltip>
							)}

							<Typography
								sx={{
									mt: 1,
									wordBreak: "break-word",
									color: "rgba(0, 0, 0, 1)",
									maxWidth: 1,
								}}
								display="grid"
								variant="Text/sm/Regular"
							>
								{startup?.tagLine}
							</Typography>
							<Box display={"flex"} gap={3} rowGap={2} sx={{ mt: 1 }} flexWrap="wrap">
								<Box sx={{ width: "140px" }} display={"flex"} alignItems="center" gap={1.125}>
									<Box component={"img"} src={LOCATION_ICON_V3} sx={{ height: "25px", width: "25px" }} alt="location" />
									<Typography variant="Text/sm/Regular" display={"block"} sx={{ maxWidth: "125px", color: "rgba(0, 0, 0, 1)" }} noWrap>
										{!startup?.city && !startup?.state
											? "-"
											: `${startup?.city ? startup?.city : ""} ${startup?.state ? startup?.state : ""}`}
									</Typography>
								</Box>
								<Box sx={{ width: "140px" }} display={"flex"} alignItems="center" gap={1.125}>
									<Box component={"img"} src={ROCKET_ICON_V3} sx={{ height: "25px", width: "25px" }} alt="location" />
									<Typography variant="Text/sm/Regular" sx={{ color: "rgba(0, 0, 0, 1)" }}>
										{startup?.foundedYear ? startup?.foundedYear : "-"}
									</Typography>
								</Box>
								<Box sx={{ width: "140px" }} display={"flex"} alignItems="center" gap={1.125}>
									<Box
										component={"img"}
										src={startup?.incorporatedStatus === "Yes" ? CORRECT_ICON_V2 : WRONG_ICON_V2}
										sx={{ height: "25px", width: "25px" }}
										alt="location"
									/>
									<Typography variant="Text/sm/Regular" sx={{ color: "rgba(0, 0, 0, 1)" }}>
										Incorporated
									</Typography>
								</Box>
								<Box sx={{ width: "140px" }} display={"flex"} alignItems="center" gap={1.125}>
									<Box component={"img"} src={CUBE_ICON_V2} sx={{ height: "25px", width: "25px" }} alt="location" />
									<Typography variant="Text/sm/Regular" sx={{ color: "rgba(0, 0, 0, 1)" }}>
										{startup?.productOffering ? startup?.productOffering : "-"}
									</Typography>
								</Box>

								{getSelectedIndustryFormatted(startup?.industry).length > 0 && (
									<Tooltip
										title={getSelectedIndustryFormatted(startup?.industry).map((item, index) => {
											if (index < getSelectedIndustryFormatted(startup?.industry).length - 1) {
												return `${item}, `;
											} else {
												return `${item}`;
											}
										})}
										arrow
									>
										<Box
											display={"flex"}
											alignItems="center"
											gap={1.125}
											sx={{ cursor: "pointer", width: "140px" }}
											onClick={toggleOpenIndustryDetails}
										>
											<Button
												variant="link"
												sx={{
													color: (theme) => theme.palette.secondary.black,
													fontSize: "16px",
													lineHeight: "24px",
													fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
													transition: "transform .2s",
													letterSpacing: "0px",
													"&:hover": {
														transform: "translateX(1px)",
													},
												}}
												endIcon={<ArrowForwardOutlined fontSize="large" />}
											>
												<Box display={"flex"} alignItems="center" sx={{ width: "140px" }}>
													<Box component={"img"} src={BUILDING_ICON_V3} sx={{ height: "25px", width: "25px" }} alt="location" />
													<Typography ml={0.7} variant="Text/sm/Regular" sx={{ color: "rgba(0, 0, 0, 1)" }}>
														Industry
													</Typography>
													<Typography variant="Text/sm/Regular" sx={{ color: "rgba(0, 0, 0, 1)" }}>
														[{getSelectedIndustryFormatted(startup?.industry).length}]
													</Typography>
												</Box>
											</Button>
										</Box>
									</Tooltip>
								)}
								{!(getSelectedIndustryFormatted(startup?.industry).length > 0) && (
									<Box display={"flex"} alignItems="center" gap={0.5} sx={{ width: "140px" }}>
										<Box component={"img"} src={BUILDING_ICON_V3} sx={{ height: "25px", width: "25px" }} alt="location" />
										{" - "}
									</Box>
								)}
								<Tooltip
									title={
										startup?.pitchDeckLink && (
											<Typography
												variant="Text/xs/Regular"
												sx={(theme) => ({ cursor: "pointer", color: theme.palette.secondary.white })}
												onClick={() => {
													handleDownloadPitchDeck(startup?.pitchDeckLink);
												}}
											>
												Download pitch deck
											</Typography>
										)
									}
								>
									<Box
										onClick={() => {
											handleDownloadPitchDeck(startup?.pitchDeckLink);
										}}
										sx={{ width: "140px", cursor: "pointer" }}
										display={"flex"}
										alignItems="center"
										gap={1.125}
									>
										<Box
											component={"img"}
											src={startup?.investorView && startup?.pitchDeckLink ? CORRECT_ICON_V2 : WRONG_ICON_V2}
											sx={{ height: "25px", width: "25px" }}
											alt="location"
										/>
										<Typography variant="Text/sm/Regular" sx={{ color: "rgba(0, 0, 0, 1)" }}>
											Pitch Deck
										</Typography>
									</Box>
								</Tooltip>

								<Box sx={{ width: "140px" }} display={"flex"} alignItems="center" gap={1.125}>
									<Box component={"img"} src={PRODUCT_STATE_ICON_V2} sx={{ height: "25px", width: "25px" }} alt="location" />
									<Typography variant="Text/sm/Regular" sx={{ color: "rgba(0, 0, 0, 1)", maxWidth: "125px" }}>
										{startup?.stateOfProduct ? startup?.stateOfProduct : "-"}
									</Typography>
								</Box>
								<Box sx={{ width: "140px" }} display={"flex"} alignItems="center" gap={1.125}>
									<Box component={"img"} src={BUSINESS_MODEL_ICON_V3} sx={{ height: "25px", width: "25px" }} alt="location" />
									<Typography variant="Text/sm/Regular" sx={{ color: "rgba(0, 0, 0, 1)" }}>
										{startup?.businessModel ? startup?.businessModel : "-"}
									</Typography>
								</Box>
							</Box>
							{isOpportunityDashboard && (
								<Box
									sx={(theme) => ({
										mt: 2.5,
										display: "flex",
										flexWrap: "wrap",

										[theme.breakpoints.down("1280")]: {
											display: "block",
										},
									})}
									columnGap={3.75}
									rowGap={1.25}
								>
									<Box
										display={"flex"}
										columnGap={1.25}
										rowGap={1}
										alignItems="center"
										sx={(theme) => ({
											flexWrap: "wrap",
										})}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												gap: "5px",
												opacity: isInvestorLocked && !isAdmin ? "0.6" : "1",
												// flexWrap:  "wrap",
											}}
										>
											<UpdatedProfileWatchlistSection
												status={startup?.inPortfolio}
												iconSrc={UPDATED_PORTFOLIO_ICON}
												name="Portfolio"
												handleOnClickSection={() => {
													(!isInvestorLocked || isAdmin) && handlePortfolioAndWatchlistDrawerActions("PORTFOLIO");
												}}
											/>

											<UpdatedProfileWatchlistSection
												status={startup?.inWatchlist}
												iconSrc={UPDATED_WATCHLIST_ICON}
												name="Watchlist"
												handleOnClickSection={() => {
													(!isInvestorLocked || isAdmin) && handlePortfolioAndWatchlistDrawerActions("WATCHLIST");
												}}
											/>
											<Can I="manage" a="AcceleratorTrackedStartups">
												{(isAdmin || userType === USER_TYPES.PARTNER_USER) && startup?.partnerCode !== partnerCode && (
													<UpdatedProfileWatchlistSection
														status={startup?.inTracked}
														iconSrc={UPDATED_WATCHLIST_ICON}
														name="Track"
														handleOnClickSection={() => {
															(!isInvestorLocked || isAdmin) && handleTrackDrawerActions("TRACK");
														}}
													/>
												)}
											</Can>
										</Box>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
					<Box className={classes.gridItemB}>
						<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", height: 1 }}>
							<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
								<Typography display={"block"} variant="Text/md/Semibold" noWrap color="secondary.black">
									Financials
								</Typography>
								<CompanyAskItem
									icon={DOLLAR_ICON_V2}
									label={"Funding Type"}
									value={startup?.fundingType && parseStringArray(startup?.fundingType)}
								/>
								<CompanyAskItem icon={CALCULATION_ICON_V2} label={"Prior Financing"} value={startup?.financing} />
								<CompanyAskItem
									icon={CURRENECY_ICON_V2}
									label={"Ask"}
									value={
										startup?.ask &&
										"$" +
											Intl.NumberFormat("en-US", {
												notation: "compact",
												maximumFractionDigits: 1,
											}).format(Number(startup?.ask))
									}
								/>
								<CompanyAskItem icon={START_ICON_V3} label={"Seeking"} value={startup?.seeking} />
								<CompanyAskItem icon={REVENUE_MODEL_ICON_V3} label={"Revenue Model Type"} value={startup?.revenue} />
							</Box>
							<Box display={"flex"} sx={{ width: 1, mt: 1 }}>
								<Button
									fullWidth
									variant={"DS1"}
									color={"gray"}
									onClick={handleViewStartupGrid}
									endIcon={<Box component={"img"} sx={{ height: "20px", width: "20px" }} src={GRID_BLACK_ICON_V1} alt="menu" />}
								>
									View Startup Grid
								</Button>
							</Box>
						</Box>
					</Box>
					<Box className={classes.gridItemC}>
						<Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} sx={{ height: 1 }} justifyContent={"space-between"}>
							<Box display={"flex"} flexDirection={"column"} gap={2}>
								<Typography display={"block"} variant="Text/md/Semibold" noWrap color="secondary.black">
									StartupOS Score
								</Typography>
								<Box display={"flex"} sx={{ width: 1 }} gap={4} flexWrap="wrap">
									<SemiCircleProgress maxValue={1000} progressValue={startup?.currentScore ? Number(startup?.currentScore) : 0} />
									<StageStepper activeStep={startup?.currentStage ? startup?.currentStage : ""} />
								</Box>
								<Box display={"flex"} gap={1} sx={{ width: 1 }} flexWrap="wrap">
									<ArrowButtonSection
										label={"Raised"}
										value={
											startup?.capitalRaised
												? "$" +
												  Intl.NumberFormat("en-US", {
														notation: "compact",
														maximumFractionDigits: 1,
												  }).format(Number(startup?.capitalRaised))
												: "-"
										}
									/>
									<ArrowButtonSection
										label={"Runway"}
										value={
											startup?.runway
												? Intl.NumberFormat("en-US", {
														notation: "compact",
														maximumFractionDigits: 1,
												  }).format(Number(startup?.runway)) + " mo."
												: "-"
										}
									/>
									<ArrowButtonSection
										label={"MRR"}
										value={
											startup?.monthlyRevenue
												? "$" +
												  Intl.NumberFormat("en-US", {
														notation: "compact",
														maximumFractionDigits: 1,
												  }).format(Number(startup?.monthlyRevenue))
												: "-"
										}
									/>
									<ArrowButtonSection label={"Headcount"} value={startup?.companySize ? startup?.companySize : 0} />
								</Box>
							</Box>

							<Box sx={{ width: 1, mt: 1 }} display={"flex"} flexDirection={"column"} gap={2}>
								<Button
									fullWidth
									variant={"DS1"}
									color={"gray"}
									onClick={() => handleOpenIndicatorsDialog()}
									endIcon={<ArrowForwardOutlined sx={{ color: "black" }} />}
								>
									Leading Indicators
								</Button>
								{startup?.enterpriseReadyJson && (
									<Button
										fullWidth
										variant={"DS1"}
										color={"gray"}
										onClick={() => setOpenAreYouEnterpriseDialog(true)}
										endIcon={<ArrowForwardOutlined sx={{ color: "black" }} />}
									>
										Enterprise Ready
									</Button>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</StartupCardComp>
		);
	};
	const handleCloseScheduleMeetingDialog = () => {
		setOpenScheduleMeetingDialog({ open: false, userInfo: null, type: null });
	};
	const LeadingIndicatorDialog = () => {
		const LeadingIndicatorCard = ({ title, indicatorList }) => {
			return (
				<Box sx={{ backgroundColor: "rgba(242, 244, 247, 1)", borderRadius: "20px", p: 2 }}>
					<Typography component={"p"} variant="Text/sm/Semibold" sx={{ mb: 2 }}>
						{title}
					</Typography>

					{title === "Participated within Accelerator" ? (
						startup?.incubator ? (
							<Box display={"flex"} alignItems={"center"} gap={1}>
								<InfoOutlinedIcon sx={{ color: "rgba(102, 112, 133, 1)" }} />

								<Typography variant="Text/sm/Regular">{startup?.incubator ? startup?.incubator : "No Data"}</Typography>
							</Box>
						) : (
							<Typography variant="Text/sm/Regular">No Data</Typography>
						)
					) : indicatorList?.length > 0 ? (
						indicatorList?.map((item) => {
							return (
								<Box display={"flex"} alignItems={"center"} gap={1} sx={{ mb: 1 }}>
									<InfoOutlinedIcon sx={{ color: "rgba(102, 112, 133, 1)" }} />

									<Typography variant="Text/sm/Regular">{item?.label}</Typography>
								</Box>
							);
						})
					) : (
						<Box>
							<Typography variant="Text/sm/Regular"> No Data</Typography>
						</Box>
					)}
				</Box>
			);
		};

		return (
			<CommonSlideDialog
				disableBackDropClick={false}
				title={"Leading Indicators"}
				titleVariant={"Text/md/Semibold"}
				isOpen={openIndicatorsDialog}
				maxWidth={"xl"}
				onClose={handleCloseIndicatorsDialog}
				transitionDuration={600}
				PaperProps={{
					sx: () => ({
						p: 3,
						ml: "auto",
						alignSelf: "end",
						borderRadius: "30px",
					}),
				}}
				dialogAction={
					<Box display="flex" mt="12px" pr={2}>
						<Button variant="containedNewStyle" onClick={handleCloseIndicatorsDialog}>
							Close
						</Button>
					</Box>
				}
			>
				<Box display={"flex"} flexDirection={"column"} gap={2}>
					<LeadingIndicatorCard title={"History of Success"} indicatorList={historySuccessList} />
					<LeadingIndicatorCard title={"Founding Team Experience"} indicatorList={foundingTeamList} />{" "}
					<LeadingIndicatorCard title={"Founding Team Skills"} indicatorList={foundingSkillList} />{" "}
					<LeadingIndicatorCard title={"Participated within Accelerator"} indicatorList={historySuccessList} />
				</Box>
			</CommonSlideDialog>
		);
	};
	return (
		<Box>
			{startup?.id ? <StartupCard /> : <Skeleton animation="wave" variant="rectangular" sx={{ height: "382px", width: 1, borderRadius: "32px" }} />}

			<LeadingIndicatorDialog />
			{openMessagingDialog && <MessageDialog />}
			<AreYouEnterpriseDialog
				startup={startup}
				enterpriseReadyScore={startup?.enterpriseReadyScore || enterpriseScore}
				openAreYouEnterpriseDialog={openAreYouEnterpriseDialog}
				setOpenAreYouEnterpriseDialog={setOpenAreYouEnterpriseDialog}
			/>
			<ScheduleMeetingDialog
				open={openScheduleMeetingDialog?.open}
				handleClose={handleCloseScheduleMeetingDialog}
				userInfo={openScheduleMeetingDialog?.userInfo}
				withUserType={openScheduleMeetingDialog?.type}
			/>
		</Box>
	);
}

export default memo(GridView);
