import React, { Component, forwardRef, createRef } from "react";

import { FormeoEditor } from "formeo";
import "formeo/dist/formeo.min.css";

const controlOptions = {
	sortable: false,
	groupOrder: ["common", "html"],
	disable: {
		elements: ["number", "date", "hidden", "upload", "button"],
		groups: ["layout"],
		formActions: true,
	},
	elements: [
		{
			tag: "input",
			config: {
				label: "Text Input",
				lockedAttrs: ["required"],
			},
			meta: {
				group: "common",
				id: "text-input",
				icon: "text-input",
			},
			attrs: {
				type: "input",
				required: false,
				description: "",
			},
		},
		{
			tag: "textarea",
			config: {
				label: "Text Area",
				lockedAttrs: ["required"],
			},
			meta: {
				group: "common",
				id: "textarea",
				icon: "textarea",
			},
			attrs: {
				type: "input",
				required: false,
				description: "",
			},
		},
		{
			tag: "select",
			config: {
				label: "Select",
				lockedAttrs: ["required"],
			},
			meta: {
				group: "common",
				id: "select",
				icon: "select",
			},
			attrs: {
				required: false,
				description: "",
			},
			options: (() => {
				let options = [1, 2, 3].map((i) => {
					return {
						label: "Label " + i,
						value: "value-" + i,
						selected: false,
					};
				});
				return options;
			})(),
		},
		{
			tag: "input",
			config: {
				label: "Checkbox Group",
				//lockedAttrs: ["required"],
			},
			meta: {
				group: "common",
				id: "checkbox",
				icon: "checkbox",
			},
			attrs: {
				type: "checkbox",
				//required: false,
			},
			options: (() => {
				let options = [1, 2, 3].map((i) => {
					return {
						label: "Label " + i,
						value: "value-" + i,
						checked: false,
					};
				});
				return options;
			})(),
		},
		{
			tag: "radio",
			config: {
				label: "Radio Group",
				lockedAttrs: ["required"],
			},
			meta: {
				group: "common",
				id: "radio",
				icon: "radio-group",
			},
			attrs: {
				type: "radio",
				required: false,
				description: "",
			},
			options: (() => {
				let options = [1, 2, 3].map((i) => {
					return {
						label: "Label " + i,
						value: "value-" + i,
						selected: false,
					};
				});
				return options;
			})(),
		},
		{
			tag: "span",
			config: {
				label: "Divider",
				hideLabel: false,
				editableContent: true,
			},
			meta: {
				group: "html",
				icon: "divider",
				id: "divider",
			},
			content: "Divider Label Content",
		},
		{
			tag: "h1",
			config: {
				label: "Header",
				hideLabel: false,
				editableContent: true,
			},
			meta: {
				group: "html",
				icon: "header",
				id: "html.header",
			},
			content: "Header",
		},
		{
			tag: "p",
			config: {
				label: "Paragraph",
				hideLabel: false,
				editableContent: true,
			},
			meta: {
				group: "html",
				icon: "paragraph",
				id: "paragraph",
			},
			content:
				"Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.",
		},
	],
	elementOrder: {
		common: ["button", "checkbox", "date-input", "hidden", "upload", "number", "radio", "select", "text-input", "textarea"],
	},
};

class FormEditor extends Component {
	editorRef = createRef();

	componentDidMount() {
		this.initializeFormEditor();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.formData !== this.props.formData) {
			this.initializeFormEditor();
		}
	}

	initializeFormEditor = () => {
		const { formData } = this.props;
		this.editor = new FormeoEditor(
			{
				editorContainer: this.editorRef.current,
				events: {
					onUpdate: (data) => {
						this.props?.saveForm();
					},
				},
				controls: controlOptions,
			},
			formData
		);

		if (this.props.forwardedRef) {
			this.props.forwardedRef.current = this.editor; // Expose the editor instance to parent
		}
	};

	render() {
		return <div ref={this.editorRef} />;
	}
}

// Use forwardRef to pass the ref to the child component
export default forwardRef((props, ref) => <FormEditor {...props} forwardedRef={ref} />);
