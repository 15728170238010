import { AbilityBuilder, Ability } from "@casl/ability";
import { defineGeneralAbilities } from "./general";
import { defineAcceleratorAdminAbilities } from "./acceleratorAdmin";

import { definePowerupAbilities } from "./powerups";
import { defineOpportunityDashboardAbilities } from "./opportunityDashboard";
import { defineEventAbilities } from "./events";
import { defineStartupGridAbilities } from "./startupgrid";
import { defineSocialAbilities } from "./social";
import { defineResourceCenterAbilities } from "./resourceCenter";

export default function defineRulesFor(user) {
	const { can, cannot, rules } = new AbilityBuilder(Ability);

	// isAdmin provides carte blanche access
	if (user?.isAdmin) can("manage", "all");
	// Full accelerator admin attribute provides carte blanche access
	if (user?.permissions?.some((e) => e.name === "full-accelerator-admin-access")) can("manage", "all");

	defineGeneralAbilities(user, can, cannot);
	defineAcceleratorAdminAbilities(user, can, cannot);
	definePowerupAbilities(user, can, cannot);
	defineOpportunityDashboardAbilities(user, can, cannot);
	defineEventAbilities(user, can, cannot);
	defineStartupGridAbilities(user, can, cannot);
	defineSocialAbilities(user, can, cannot);
	defineResourceCenterAbilities(user, can, cannot);

	return rules;
}

export function buildAbilityFor(user) {
	return new Ability(defineRulesFor(user));
}
