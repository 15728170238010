import React, { useState, useEffect } from "react";
import {
	INVESTOR_LEFT_SIDEBAR_ITEMS,
	PARTNER_LEFT_SIDEBAR_ITEMS,
	ADMIN_SIDEBAR_ITEMS,
	LOCKED_INVESTOR_LEFT_SIDEBAR_ITEMS,
} from "../../../../constants/workspace-sidebar";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";

import { USER_TYPES } from "../../../../utils/userTypes";
import NavItemsList from "../../../../common/NavItemsList/NavItemsList";
import { useSelector, useDispatch } from "react-redux";
import {
	ACTIVE_ACCOUNT_PROFILE_ICON,
	ACTIVE_COMMUNITY_ICON,
	ACTIVE_DASHBOARD_ICON,
	ACTIVE_DISCOVERY_ICON,
	ACTIVE_FEED_ICON,
	ACTIVE_HOME_ICON,
	ACTIVE_RESOURCE_CENTER_ICON,
	ACTIVE_STARTUP_GRID_ICON,
	ACTIVE_STARTUP_PROFILE_ICON,
	LOGOICON,
	UPDATED_FOUNDER_ICON,
	UPDATED_INVESTOR_ICON,
	UPDATED_LOGO_ICON_NEW,
	UPDATED_MENTOR_ICON,
} from "../../../../constants/image";
import { Typography, Box, Divider, Grid, Button } from "@mui/material";
import { LeftSideBarWrapper, AppBar, Drawer, DrawerHeader, ListContainer } from "./styles";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
//import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MuiAppBar from "@mui/material/AppBar";
import theme from "../../../../constants/Theme";
import UpdatedLayoutWrapper from "../../../../layout/CommonLayout/UpdatedLayoutWrapper";
import UpdatedHeader from "../../UpdatedHeader";
import { MainArea } from "../../../../layout/Workspace/styles";
import ROUTES_PATH from "../../../../constants/routes";
import { setSidebarOpenPref, setSidebarWidth, setWorkProfile } from "../../../../modules/actions";
import services from "../../../../services";
import useDimensions from "react-cool-dimensions";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import ListItem from "./ListItem";
import { getRoleValue } from "../../../../utils/commonFunctions";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { useThemeContext } from "../../../../constants/Theme/ThemeContext";
import BoltChat from "../../../BoltChat";
import { AbilityContext, Can } from "../../../../services/abilities/context";
import { subject } from "@casl/ability";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useAbility } from "@casl/react";

export default function LeftSidebar({
	userData,
	companyName,
	userName,
	companyLogoUrl,
	header,
	onLogout,
	hasUpdates,
	setMargin,
	mainArea,
	//open,
	//setOpen,
}) {
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const sidebarOpenPref = useSelector((store) => store?.applicationProps?.sidebarOpenPref);
	const history = useHistory();
	const dispatch = useDispatch();
	const ability = useAbility(AbilityContext);
	const { isPremiumPlanAccess, userType } = useSelector((store) => store?.auth);
	const { appNameOverride, logoOverride } = useThemeContext();

	const [active, setActive] = useState();
	const [open, setOpen] = useState(true); // Open By Default
	const location = useLocation();
	const params = useParams();
	const [leftSidebarItems, setLeftSidebarItems] = useState(null);
	const { isInvestorLocked } = useSelector((state) => state?.auth);

	const slackWorkspaceData = useSelector((state) => state?.slackWorkspace);

	const { observe, unobserve, width, height, entry } = useDimensions({
		useBorderBoxSize: true,
		onResize: ({ observe, unobserve, width, height, entry }) => {
			// Triggered whenever the size of the target is changed...
			//  unobserve(); // To stop observing the current target element
			//  observe(); // To re-start observing the current target element
		},
	});

	const MENTOR_LEFT_SIDEBAR_ITEMS = [
		// {
		// 	icon: <DashboardIcon />,
		// 	label: "Dashboard",
		// 	link: ROUTES_PATH.MENTOR_DASHBOARD,
		// 	approvalRequired: false,
		// },
		{
			icon: <Box component={"img"} src={ACTIVE_ACCOUNT_PROFILE_ICON} sx={{ width: "40px" }} />,
			label: "My Profile",
			link: ROUTES_PATH.MY_MENTOR_PROFILE,
			approvalRequired: false,
		},

		{
			icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
			label: "Community",
			link: ROUTES_PATH.MENTOR_NETWORK,
			approvalRequired: false,
		},
		{
			icon: <Box component={"img"} src={ACTIVE_RESOURCE_CENTER_ICON} sx={{ width: "40px" }} />,
			label: "Resource Center",
			link: ROUTES_PATH.RESOURCE_CENTER,
			approvalRequired: false,
		},
		{
			icon: <Box component={"img"} src={ACTIVE_FEED_ICON} sx={{ width: "40px" }} />,
			label: slackWorkspaceData?.slackUrl && slackWorkspaceData?.slackWorkspaceName !== "" ? slackWorkspaceData?.slackWorkspaceName : "Social Feed",
			link: ROUTES_PATH.FEED_PATH,
			approvalRequired: false,
			isslackUrlPresent: Boolean(slackWorkspaceData?.slackUrl),
		},
		{
			icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
			label: "Discovery",
			link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
			approvalRequired: false,
		},
	];

	const STARTUP_LEFT_SIDEBAR_ITEMS = [
		{
			icon: <Box component={"img"} src={ACTIVE_HOME_ICON} sx={{ width: "40px" }} />,
			label: "Home",
			link: ROUTES_PATH.STARTUPS_HOME,
			approvalRequired: false,
		},
		// {
		// 	icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		// 	label: "Dashboard",
		// 	link: ROUTES_PATH.FOUNDER_DASHBOARD,
		// 	approvalRequired: false,
		// },
		{
			icon: <Box component={"img"} src={ACTIVE_STARTUP_PROFILE_ICON} sx={{ width: "40px" }} />,
			label: "My Startup",
			link: ROUTES_PATH.MY_STARTUP_PROFILE,
			approvalRequired: false,
		},

		{
			icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
			label: "Community",
			link: ROUTES_PATH.NETWORK,
			approvalRequired: true,
		},
		{
			icon: <Box component={"img"} src={ACTIVE_FEED_ICON} sx={{ width: "40px" }} />,
			label: slackWorkspaceData?.slackUrl
				? slackWorkspaceData?.slackWorkspaceName === ""
					? "Social Feed"
					: slackWorkspaceData?.slackWorkspaceName
				: "Social Feed",
			link: ROUTES_PATH.FEED_PATH,
			approvalRequired: false,
			isslackUrlPresent: Boolean(slackWorkspaceData?.slackUrl),
		},
		{
			icon: <Box component={"img"} src={ACTIVE_STARTUP_GRID_ICON} sx={{ width: "40px" }} />,
			label: "Startup Grid",
			link: ROUTES_PATH.STARTUPGRID,
			approvalRequired: false,
		},
		{
			icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
			label: "Discovery",
			link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
			approvalRequired: false,
		},
		{
			icon: <Box component={"img"} src={ACTIVE_RESOURCE_CENTER_ICON} sx={{ width: "40px" }} />,
			label: "Resource Center",
			link: ROUTES_PATH.RESOURCE_CENTER,
			approvalRequired: false,
		},
	];

	useEffect(() => {
		observe();
		if (typeof sidebarOpenPref == "boolean") {
			setOpen(sidebarOpenPref); // Pull the past state from the redux object.
		}

		let sidebarItems = [];

		switch (userType) {
			case USER_TYPES.INVESTOR_USER:
				sidebarItems = isInvestorLocked && !isAdmin ? LOCKED_INVESTOR_LEFT_SIDEBAR_ITEMS : INVESTOR_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.CORPORATE_USER:
				sidebarItems = isInvestorLocked && !isAdmin ? LOCKED_INVESTOR_LEFT_SIDEBAR_ITEMS : INVESTOR_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.STARTUP_OWNER:
				sidebarItems = STARTUP_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.STARTUP_USER:
				sidebarItems = STARTUP_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.MENTOR_USER:
				sidebarItems = MENTOR_LEFT_SIDEBAR_ITEMS;
				break;
			case USER_TYPES.PARTNER_USER: {
				if (ability?.can("view", "EventDashboard"))
					sidebarItems.push({
						icon: <DashboardIcon />,
						label: "Event Dashboard",
						link: ROUTES_PATH.EVENT_DASHBOARD,
						approvalRequired: false,
					});
				sidebarItems.push({
					icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
					label: "Community",
					link: ROUTES_PATH.MENTOR_NETWORK,
					approvalRequired: false,
				});
				if (ability?.can("view", "OpportunityDashboard"))
					sidebarItems.push({
						icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
						label: "Opportunity Dashboard",
						link: ROUTES_PATH.INVESTOR_DASHBOARD,
						approvalRequired: false,
					});
				if (ability?.can("view", "StartupGrid"))
					sidebarItems.push({
						icon: <Box component={"img"} src={ACTIVE_STARTUP_GRID_ICON} sx={{ width: "40px" }} />,
						label: "Startup Grid",
						link: ROUTES_PATH.STARTUPGRID,
						approvalRequired: false,
					});
				if (ability?.can("view", "PowerPacks"))
					sidebarItems.push({
						icon: <DashboardIcon />,
						label: "PowerupPack Dashboard",
						link: ROUTES_PATH.POWERPACK_DASHBOARD,
						approvalRequired: false,
					});
				if (ability?.can("manage", "AcceleratorConfiguration"))
					sidebarItems.push({
						icon: <DashboardIcon />,
						label: "Partner Admin QA",
						link: ROUTES_PATH.PARTNER_ADMIN_QA,
						approvalRequired: false,
					});
				if (ability?.can("manage", "AcceleratorForms"))
					sidebarItems.push({
						icon: <DashboardIcon />,
						label: "Admin Form Manager",
						link: ROUTES_PATH.ADMIN_FORM_MANAGER,
						approvalRequired: false,
					});
				break;
			}
			default:
				console.log(userType);
				sidebarItems = [];
				break;
		}

		if (isAdmin) {
			sidebarItems = [...sidebarItems, ...ADMIN_SIDEBAR_ITEMS];
		}

		setLeftSidebarItems(sidebarItems);
	}, [slackWorkspaceData]);

	useEffect(() => {
		dispatch(setSidebarWidth(width));
	}, [width]);

	useEffect(() => {
		console.log(leftSidebarItems);
	}, [leftSidebarItems]);

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	//const handleDrawerClose = (open) => {
	//	setOpen(!open);
	//};
	const currentSelectedNavItem = useSelector((store) => store?.auth?.currentSelectedNavItem);

	const [approvalStatus, setApprovalStatus] = useState();

	useEffect(() => {
		if (!approvalStatus) {
			services.getApprovalStatus().then((status) => {
				setApprovalStatus(status);
			});
		}
	}, [approvalStatus]);

	useEffect(() => {
		setActive(currentSelectedNavItem);
	}, [currentSelectedNavItem]);

	useEffect(() => {
		if (["discovery-board-expand", "discovery-board", "create-discovery-item", "create-quick-note"].includes(location.pathname.split("/")[1])) {
			setActive("discovery-board");
		} else if (["powerpack"].includes(location.pathname.split("/")[1]) && params.id) {
			setActive("workspace");
		} else if (
			(["profile"].includes(location.pathname.split("/")[1]) && params.id) ||
			(["myprofile"].includes(location.pathname.split("/")[2]) && params.id) ||
			["network"].includes(location.pathname.split("/")[2])
		) {
			setActive("network");
		} else {
			if (["dashboard"].includes(location.pathname.split("/")[2]) && ["mentor"].includes(location.pathname.split("/")[1])) {
				setActive("mentor-dashboard");
			} else if (["dashboard"].includes(location.pathname.split("/")[2]) && ["investor"].includes(location.pathname.split("/")[1])) {
				setActive("investor-dashboard");
			} else if (["dashboard"].includes(location.pathname.split("/")[2])) {
				setActive("dashboard");
			} else {
				setActive(location.pathname.split("/")[1]);
			}
		}
	}, [location]);

	const handleListItemClick = (menuItem, role) => {
		console.log(`menuItem`, menuItem);
		handleMixPanel("Menu Item Clicked", {
			"Item Name": menuItem.label,
			"Menu Name": "Left nav",
			subscription_status: isPremiumPlanAccess ? "Subscribed" : "Not Subscribed",
			user_type: userType,
		});

		dispatch(setWorkProfile({ transitionStatus: true }));

		// Added Condition to enable only investor-dashboard
		if (menuItem?.key === "investor-dashboard") {
			if (menuItem.label === "Startup") {
				history.push(`${ROUTES_PATH.MY_STARTUP_PROFILE}`);
				// history.push(`${ROUTES_PATH.STARTUP_PROFILE.replace("/:id?", "")}`);
			} else if (menuItem?.isslackUrlPresent) {
				const slackUrl = `${slackWorkspaceData.slackUrl}`;
				window.open(slackUrl, "_blank");
			} else {
				history.push(`${menuItem.link}`);
			}
		} else {
			if (menuItem.label === "Startup") {
				history.push(`${ROUTES_PATH.MY_STARTUP_PROFILE}`);
				// history.push(`${ROUTES_PATH.STARTUP_PROFILE.replace("/:id?", "")}`);
			} else if (menuItem?.isslackUrlPresent) {
				const slackUrl = `${slackWorkspaceData.slackUrl}`;
				window.open(slackUrl, "_blank");
			} else {
				history.push(`${menuItem.link}`);
			}
		}
	};

	useEffect(() => {
		if (open == false) {
			dispatch(setSidebarOpenPref(false));
		} else {
			dispatch(setSidebarOpenPref(true));
		}
	}, [open]);

	return (
		<Box
			sx={{
				width: open ? "250px" : "100px",
				boxSizing: "content-box", // Ensure the left padding does not take away from the fixed width values. It should always be 250/100 wide + the additional padding.
				transition: "all 0.5s ease-in-out",
				overflow: "hidden",
				height: "100vh",
			}}
			ref={observe}
		>
			<Box
				sx={{
					background: (theme) => theme?.palette?.secondary?.main,
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					flex: 1,
					transition: "all 0.5s ease-in-out",
					gap: "20px",
					height: "100%",
					overflowY: "scroll",
					overflowX: "hidden",
					py: "40px",
					boxSizing: "border-box",
				}}
			>
				<CSSTransition in={open} timeout={500} classNames="open">
					<IconButton
						onClick={handleDrawerOpen}
						sx={{
							color: "white",
							px: "20px",
							...(!open && {
								"&:not(.open-exit-active)": {
									alignSelf: "center",
								},
							}),
							"&.open-exit-done": {
								alignSelf: "center",
							},
							"&.open-exit-active": { alignSelf: "flex-end" },
							"&.open-enter-done": {
								alignSelf: "flex-end",
							},
							"&.open-enter-active": { alignSelf: "flex-end" },
						}}
					>
						<MenuIcon />
					</IconButton>
				</CSSTransition>

				<CSSTransition in={open} timeout={500} classNames="open">
					<Box
						sx={{
							display: "flex",
							px: "20px",
							mb: "20px",
							alignSelf: "stretch",
							alignItems: "center",
							...(open && {
								gap: 2,
							}),
							...(!open && {
								"&:not(.open-exit-active)": {
									justifyContent: "center",
								},
							}),
							"&.open-exit-done": {
								justifyContent: "center",
							},
							"&.open-exit-active": { justifyContent: "flex-start", gap: 2 },
							"&.open-enter-done": {
								justifyContent: "flex-start",
								gap: 2,
							},
							"&.open-enter-active": { justifyContent: "flex-start", gap: 2 },
						}}
					>
						{logoOverride ? (
							<Box
								component={"img"}
								src={logoOverride}
								alt="logo"
								sx={{
									height: "30px",
								}}
							/>
						) : (
							<Box
								component={"img"}
								src={UPDATED_LOGO_ICON_NEW}
								alt="logo"
								sx={{
									height: "30px",
									width: "30px",
								}}
							/>
						)}
						<CSSTransition in={open} appear={open} timeout={500} classNames="open">
							<Box
								sx={{
									transition: "all 0.5s ease-in-out",
									opacity: open ? 1 : 0,
									width: "0px",

									"&.open-exit-done": {
										opacity: 0,
										width: "0px",
									},
									"&.open-exit-active": {
										opacity: 0,
										width: "0px",
									},
									"&.open-enter-done": {
										opacity: 1,
										width: "100%",
									},
									"&.open-enter-active": {
										opacity: 1,
										width: "100%",
									},
								}}
							>
								<Typography
									variant="Text/md/Semibold"
									sx={{
										color: "white",
									}}
								>
									{appNameOverride ? appNameOverride : "StartupOS"}
								</Typography>
							</Box>
						</CSSTransition>
					</Box>
				</CSSTransition>

				<CSSTransition in={open} timeout={500} classNames="open">
					<Box
						sx={{
							display: "flex",
							px: "20px",
							mb: "20px",
							alignSelf: "stretch",
							alignItems: "center",
							...(open && {
								gap: 2,
							}),
							...(!open && {
								"&:not(.open-exit-active)": {
									justifyContent: "center",
								},
							}),
							"&.open-exit-done": {
								justifyContent: "center",
							},
							"&.open-exit-active": { justifyContent: "flex-start", gap: 2 },
							"&.open-enter-done": {
								justifyContent: "flex-start",
								gap: 2,
							},
							"&.open-enter-active": { justifyContent: "flex-start", gap: 2 },
						}}
					>
						<BoltChat isDesktop={open} />
					</Box>
				</CSSTransition>

				<CSSTransition in={open} timeout={500} classNames="open">
					<Box
						sx={{
							transition: "all 0.5s ease-in-out",
							px: "20px",
							display: "flex",
							alignSelf: "stretch",
							...(open && {
								gap: 2,
							}),
							...(!open && {
								"&:not(.open-exit-active)": {
									justifyContent: "center",
								},
							}),
							"&.open-exit-done": {
								justifyContent: "center",
							},
							"&.open-exit-active": {
								justifyContent: "flex-start",
								gap: 2,
							},
							"&.open-enter-done": {
								justifyContent: "flex-start",
								gap: 2,
							},
							"&.open-enter-active": {
								justifyContent: "flex-start",
								gap: 2,
							},
						}}
					>
						{(() => {
							switch (getRoleValue(userType)) {
								case "Founder":
									return <Box component={"img"} src={UPDATED_FOUNDER_ICON} alt="logo" />;
								case "Mentor":
									return <Box component={"img"} src={UPDATED_MENTOR_ICON} alt="logo" />;
								case "Investor":
									return <Box component={"img"} src={UPDATED_INVESTOR_ICON} alt="logo" />;
								default:
									return <Box component={"img"} src={UPDATED_FOUNDER_ICON} alt="logo" />;
							}
						})()}

						<CSSTransition in={open} timeout={300} classNames="open">
							<Box
								sx={{
									transition: "opacity 0.5s ease-in-out",
									opacity: open ? 1 : 0,
									"&:not(.open-enter-active), &:not(.open-enter-done), &:not(.open-exit-done), &:not(.open-exit-active)": {
										width: "0px",
									},
									"&.open-enter-done": {
										opacity: 1,
									},

									"&.open-exit-done": {
										opacity: 0,
									},
									"&.open-enter-active": {
										opacity: 1,
									},
								}}
							>
								<Typography
									variant="customValue"
									sx={{
										fontFamily: "PoppinsSemiBold",
										fontSize: "14px",
										lineHeight: "21px",
										color: "white",
										textTransform: "uppercase",
									}}
								>
									{getRoleValue(userType)}
								</Typography>
							</Box>
						</CSSTransition>
					</Box>
				</CSSTransition>

				{leftSidebarItems?.map((menuItem, index) => {
					return (
						<Can I="view" this={subject("MainSideNavigation", menuItem)}>
							<ListItem
								key={menuItem.label}
								menuItem={menuItem}
								index={index}
								role={userType}
								active={!menuItem?.isslackUrlPresent && !!matchPath(location?.pathname, { path: menuItem?.link, exact: true })}
								open={open}
								handleClick={() => handleListItemClick(menuItem, userType)}
							/>
						</Can>
					);
				})}

				<Can I="view" a="AcceleratorDashboard">
					<ListItem
						key={"Accelerator Dashboard"}
						menuItem={{
							icon: <DashboardIcon />,
							label: "Accelerator Dashboard",
							link: ROUTES_PATH.PARTNER_ADMIN_DASHBOARD,
							approvalRequired: false,
						}}
						index={leftSidebarItems?.length + 1}
						role={userType}
						active={!!matchPath(location?.pathname, { path: ROUTES_PATH.PARTNER_ADMIN_DASHBOARD, exact: true })}
						open={open}
						handleClick={() =>
							handleListItemClick(
								{
									icon: <DashboardIcon />,
									label: "Accelerator Dashboard",
									link: ROUTES_PATH.PARTNER_ADMIN_DASHBOARD,
									approvalRequired: false,
								},
								userType
							)
						}
					/>
				</Can>
			</Box>
		</Box>
	);
}
